const inputPeriodValidation = (params) => {
  const { fromYear, toYear, fromMonth, toMonth } = params;
  const period =
    (parseInt(toYear) - parseInt(fromYear)) * 12 -
    parseInt(toMonth) +
    parseInt(fromMonth);
  if (period > 36) {
    return false;
  } else {
    return true;
  }
};

const inputValidation = (options) => {
  const requiredItem = requiredItems.filter((s) => s.name === options.name);

  return requiredItem[0].required.every((s) => {
    if (options[s] === "") {
      return false;
    }
    return true;
  });
};

const requiredItems = [
  {
    name: "trendNormal",
    required: ["fromMonth", "fromYear", "toYear", "toMonth", "disease"],
  },
  {
    name: "trendYkiho",
    required: ["fromMonth", "fromYear", "toYear", "toMonth", "region"],
  },
  {
    name: "rankNormal",
    required: [
      "fromMonth",
      "fromYear",
      "toYear",
      "toMonth",
      "periodCode",
      "rankLimit",
      "valueNameCode",
    ],
  },
  {
    name: "rankYkiho",
    required: [
      "fromMonth",
      "fromYear",
      "toYear",
      "toMonth",
      "periodCode",
      "rankLimit",
      "valueNameCode",
      "region",
    ],
  },
  {
    name: "rankNormal",
    required: [
      "fromMonth",
      "fromYear",
      "toYear",
      "toMonth",
      "rankLimit",
      "periodCode",
      "valueNameCode",
    ],
  },
  {
    name: "rankYkiho",
    required: [
      "fromMonth",
      "fromYear",
      "toYear",
      "toMonth",
      "rankLimit",
      "periodCode",
      "valueNameCode",
    ],
  },
  {
    name: "itemClsfc",
    required: ["bgn_ym", "end_ym", "prdctClsfcNo"],
  },
];

export { inputValidation, inputPeriodValidation };
