import React, { useState, useContext } from "react";
import ClsfcTable from "./clsfcTable";
import ItemForm from "./itemForm";
import ClsfcDetailTable from "./clsfcDetailTable";
import BuyerTable from "./buyerTable";
import {
  clsfcDetailJsonMerge,
  transToBarChartJson,
} from "../../service/item_data_manipulation";
import ItemBarChart from "./itemBarChart";
import ItemValueRadio from "./itemValueRadio";
import { useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styles from "./itemStat.module.css";
import onHelpPopup from "../NewPatientsStat/openPopup";
import { UserContext } from "../../context/UserContext";
import BaseLayout from "../layout/BaseLayout";
import DownloadForOfflineIcon from "@mui/icons-material/DownloadForOffline";

const ItemStat = ({ openlabApi }) => {
  const [selectParams, setSelectParams] = useState({});
  const [clsfcTable, setClsfcTable] = useState([]);
  const [detailTable, setDetailTable] = useState([]);
  const [barChartData, setBarChartData] = useState([]);
  const [buyerTable, setBuyerTable] = useState([]);
  const [barPlot, setBarPlot] = useState([]);
  const [selectedRadio, setSelectedRadio] = useState("계약금액");
  const [tbDownload1, setTableDownload1] = useState(false);
  const [tbDownload2, setTableDownload2] = useState(false);
  const [chartDownload, setChartDownload] = useState(false);
  const [token] = useContext(UserContext);

  useEffect(() => {
    onChart(barChartData);
  }, [selectedRadio, barChartData]);

  useEffect(() => {
    genDetailTable(selectParams);
    genBarChart(selectParams);
    genBuyerTable(selectParams);
    // onChart(barChartData);
  }, [selectParams]);

  const genClsfcTable = async (inputJson) => {
    const response = await openlabApi.itemClsfcStat(
      JSON.stringify(inputJson),
      token
    );

    if (response.data.length === 0) {
      window.alert("데이터가 없습니다.");
    }

    const rowData = response.data.map((item) => {
      return {
        ...item,
        물품분류명: item.prdctClsfcNoNm,
        계약금액: item.amt,
        수량: item.item_cnt,
      };
    });
    setClsfcTable(rowData);
  };

  const genDetailTable = async (inputJson) => {
    const apiParams = { ...selectParams, ...inputJson };
    const response = await openlabApi.itemClsfcDetail(apiParams, token);

    const mergedData = clsfcDetailJsonMerge(response.data);
    const rowData = mergedData.map((item) => {
      return {
        ...item,
        물품식별명: item.krnPrdctNm,
        계약금액: item.amt,
        수량: item.item_cnt,
      };
    });

    setDetailTable(rowData);
  };

  const genBuyerTable = async (inputJson) => {
    const apiParams = { ...selectParams, ...inputJson };
    const response = await openlabApi.itemClsfcBuyer(apiParams, token);
    const mergedData = response.data;
    const rowData = mergedData.map((item) => {
      return {
        ...item,
        계약금액: item.amt,
        수량: item.item_cnt,
        기관명: item.cntrctInsttNm,
      };
    });

    setBuyerTable(rowData);
  };

  const genBarChart = async (inputJson) => {
    const apiParams = { ...selectParams, ...inputJson };
    const response = await openlabApi.itemClsfcTrend(apiParams, token);
    const mergedData = response.data;
    const rowData = mergedData.map((item) => {
      return {
        ...item,
        계약금액: item.amt,
        수량: item.item_cnt,
        계약건수: item.cntrct_cnt,
      };
    });
    setBarChartData(rowData);
  };

  const getDetailChartTable = async (inputJson) => {
    const apiParams = { ...selectParams, ...inputJson };
    const responseC = await openlabApi.itemDetailTrend(apiParams, token);
    const responseT = await openlabApi.itemDetailBuyer(apiParams, token);
    const mergedDataC = responseC.data;
    const mergedDataT = responseT.data;
    const rowDataC = mergedDataC.map((item) => {
      return {
        ...item,
        계약금액: item.amt,
        수량: item.item_cnt,
        계약건수: item.cntrct_cnt,
      };
    });
    const rowDataT = mergedDataT.map((item) => {
      return {
        ...item,
        계약금액: item.amt,
        수량: item.item_cnt,
        기관명: item.cntrctInsttNm,
      };
    });

    setBarChartData(rowDataC);
    setBuyerTable(rowDataT);
  };

  const onChart = (data) => {
    const filteredData = data.map((RawMData) => {
      const monthlyData = { yyyymm: RawMData.yyyymm };
      monthlyData[selectedRadio] = RawMData[selectedRadio];
      return monthlyData;
    });
    setBarPlot(filteredData);
  };

  const onClickDetail = (event) => {
    setTableDownload1(true);
    setTimeout(() => {
      setTableDownload1(false);
    }, 0.2);
  };

  const onClickOrg = (event) => {
    setTableDownload2(true);
    setTimeout(() => {
      setTableDownload2(false);
    }, 0.2);
  };

  const onClickChart = (event) => {
    setChartDownload(true);
    setTimeout(() => {
      setChartDownload(false);
    }, 0.2);
  };

  const onOpenItemPopup = () => onHelpPopup("/help_item");

  return (
    <BaseLayout>
      <div className={styles.banner__title}>
        <h1>의약의료기기 물품구매내역분석</h1>
      </div>
      <div className={styles.container}>
        <div className={styles.helpBox}>
          <button onClick={onOpenItemPopup} className={styles.helpBtn}>
            도움말
          </button>
        </div>
        <ItemForm
          openlabApi={openlabApi}
          genClsfcTable={genClsfcTable}
          genBarChart={genBarChart}
          setSelectParams={setSelectParams}
        />
        <div className={styles.contentBoxes}>
          <div className={styles.contentBoxSmall}>
            <div className={styles.contentBox__head}>
              <h4>물품분류별 계약실적</h4>
            </div>

            <div className={styles.boxSmallWrapper}>
              <ClsfcTable
                openlabApi={openlabApi}
                rowData={clsfcTable}
                genDetailTable={genDetailTable}
              />
            </div>
          </div>

          <div className={styles.contentBoxBig}>
            <div className={styles.contentBox__head}>
              <h4>물품세부분류별 계약실적(click)</h4>
              <DownloadForOfflineIcon
                style={{ marginRight: "10px", cursor: "pointer" }}
                onClick={onClickDetail}
              />
            </div>
            <div className={styles.boxBigWrapper}>
              <ClsfcDetailTable
                rowData={detailTable}
                getDetailChartTable={getDetailChartTable}
                downloadTF={tbDownload1}
              />
            </div>
          </div>

          <div className={styles.contentBoxBig}>
            <div className={styles.contentBox__head}>
              <h4>월별물품 계약실적</h4>
              <ItemValueRadio setSelectedRadio={setSelectedRadio} />

              <DownloadForOfflineIcon
                style={{ marginRight: "10px", cursor: "pointer" }}
                onClick={onClickChart}
              />
            </div>

            <div className={styles.boxBigWrapper}>
              <ItemBarChart barChartData={barPlot} downloadTF={chartDownload} />
            </div>
          </div>

          <div className={styles.contentBoxSmall}>
            <div className={styles.contentBox__head}>
              <h4>기관별 계약실적</h4>

              <DownloadForOfflineIcon
                style={{ marginRight: "10px", cursor: "pointer" }}
                onClick={onClickOrg}
              />
            </div>
            <div className={styles.boxSmallWrapper}>
              <BuyerTable rowData={buyerTable} downloadTF={tbDownload2} />
            </div>
          </div>
        </div>
      </div>
    </BaseLayout>
  );
};

export default ItemStat;
