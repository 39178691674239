import React, { createContext, useEffect, useState } from "react";

export const UserContext = createContext();

export const UserProvider = (props) => {
  const [token, setToken] = useState(localStorage.getItem("jwt"));
  const [staff, setStaff] = useState(localStorage.getItem("is_staff"));
  const [passwordCheck, setPasswordCheck] = useState(false);
  const [username, setUsername] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [email, setEmail] = useState("");

  useEffect(() => {
    const fetchUser = async () => {
      const response = await fetch("http://222.113.80.171:8000/api/users/me", {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        credentials: "include",
      });

      const result = await response.json();

      if (!response.ok) {
        setToken(null);
        localStorage.removeItem("jwt");
      }

      if (result.is_staff) {
        localStorage.setItem("is_staff", true);
      } else {
        localStorage.setItem("is_staff", false);
      }

      setStaff(localStorage.getItem("is_staff") === "true" ? "true" : "false");
      setUsername(result.username);
      setCompanyName(result.company_name);
      setEmail(result.email);

      localStorage.setItem("jwt", token);
    };
    fetchUser();
  }, [token]);

  return (
    <UserContext.Provider
      value={[
        token,
        setToken,
        staff,
        passwordCheck,
        setPasswordCheck,
        username,
        companyName,
        email,
      ]}
    >
      {props.children}
    </UserContext.Provider>
  );
};
