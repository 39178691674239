const menuNameCode = {
  노인성질환통계: "seniors",
  환자표본자료분석: "patients",
  수정환자표본자료분석: "new_patients",
  물품구매내역분석: "items",
  기업분석지원: "support",
  "질병-진료처치분석": "disease-treat",
  home: "",
};

const defaultDataYearOption = Array(9)
  .fill(0)
  .map((val, idx) => {
    return { value: `${idx + 2010}`, label: `${idx + 2010}` };
  })
  .reverse();

const monthOption = Array(12)
  .fill(0)
  .map((val, idx) => {
    return { value: `${idx + 1}`, label: `${idx + 1}` };
  });

const ageOption = [
  { value: 14, label: "65이상~70세미만" },
  { value: 15, label: "70이상~75세미만" },
  { value: 16, label: "75세이상" },
];

const regionOption = [
  { value: "11", label: "서울" },
  { value: "21", label: "부산" },
  { value: "22", label: "인천" },
  { value: "23", label: "대구" },
  { value: "24", label: "광주" },
  { value: "25", label: "대전" },
  { value: "26", label: "울산" },
  { value: "31", label: "경기" },
  { value: "32", label: "강원" },
  { value: "33", label: "충북" },
  { value: "34", label: "충남" },
  { value: "35", label: "전북" },
  { value: "36", label: "전남" },
  { value: "37", label: "경북" },
  { value: "38", label: "경남" },
  { value: "39", label: "제주" },
];

const inoutOption = [
  { value: 1, label: "입원" },
  { value: 2, label: "외래" },
];
const diseaseOption = [
  { value: "I21", label: "급성심근경색" },
  { value: "I21", label: "심근경색" },
  { value: "I60", label: "파열된 대뇌동맥류" },
  { value: "I61", label: "뇌내출혈" },
  { value: "I62", label: "기타 비외상성 머리내 출혈" },
  { value: "I63", label: "뇌경색증" },
  { value: "I64", label: "출혈 또는 경색증으로 명시되지 않은 뇌중풍" },
  { value: "I65", label: "대뇌경색증을 유발하지 않은 뇌전동맥의 폐색 및 협착" },
  { value: "I66", label: "대뇌경색증을 유발하지 않은 대뇌동맥의 폐색 및 협착" },
  { value: "I67", label: "기타 뇌혈관 질환" },
  { value: "I68", label: "달리 분류된 질환에서의 뇌혈관 장애" },
  { value: "I69", label: "뇌혈관 질환의 후유증" },
  { value: "I10", label: "본태성 고혈압" },
  { value: "I11", label: "고혈압성 심장병" },
  { value: "I12", label: "고혈압성 신장질환" },
  { value: "I13", label: "심신성질환" },
  { value: "I14", label: "두통" },
  { value: "I15", label: "속발성 고혈압" },
  { value: "E11", label: "당뇨" },
  { value: "H40", label: "녹내장" },
  { value: "H26", label: "백내장" },
  { value: "H36", label: "망막병증" },
  { value: "F00", label: "알쯔하이머병에서의 치매" },
  { value: "F01", label: "혈관성치매" },
  { value: "F02", label: "기타 질환에서의 치매" },
  { value: "F03", label: "상세불명의 치매" },
  { value: "G20", label: "파킨슨병" },
  { value: "G21", label: "속발성 파킨슨병" },
  { value: "G22", label: "달린 분류된 질환에서의 파킨슨증" },
  { value: "G23", label: "기저핵의 기타 퇴행성 질환" },
  { value: "G30", label: "알쯔하이머병" },
  { value: "M54", label: "요통" },
  { value: "M48", label: "척추협착" },
  { value: "M17", label: "무릎관절증 " },
  { value: "M25", label: "관절통" },
  { value: "M06", label: "류마티스관절염 " },
  { value: "I63", label: "뇌경색" },
  { value: "R25", label: "이상적 불수의적 운동" },
  { value: "K30", label: "상복부통증증후군" },
  { value: "M79", label: "근통" },
];
const sexOption = [
  { value: "1", label: "남성" },
  { value: "2", label: "여성" },
];

const periodCodeOption = [
  { value: "m", label: "월" },
  { value: "y", label: "년" },
];

const valueNameCodeOption = [
  { value: "3", label: "환자수" },
  { value: "2", label: "진료건수" },
  { value: "1", label: "급여비용" },
];

const rankLimitOption = [
  { value: 10, label: 10 },
  { value: 20, label: 20 },
  // { value: 30, label: 30 },
  // { value: 50, label: 50 },
];

const recuClCodeOption = [
  { value: "1", label: "상급종합병원" },
  { value: "11", label: "종합병원" },
  { value: "21", label: "병원" },
  { value: "28", label: "요양병원" },
  { value: "29", label: "정신요양병원" },
  { value: "31", label: "의원" },
  { value: "41", label: "치과병원" },
  { value: "51", label: "치과의원" },
  { value: "61", label: "조산원" },
  { value: "71", label: "보건소" },
  { value: "72", label: "보건지소" },
  { value: "73", label: "보건진료소" },
  { value: "74", label: "모자보건센터" },
  { value: "75", label: "보건의료원" },
  { value: "81", label: "약국" },
  { value: "92", label: "한방병원" },
  { value: "93", label: "한의원" },
];

const dgsbjtCodeOption = [
  { value: "00", label: "일반의" },
  { value: "01", label: "내과" },
  { value: "02", label: "신경과" },
  { value: "03", label: "정신건강의학과" },
  { value: "04", label: "외과" },
  { value: "05", label: "정형외과" },
  { value: "06", label: "신경외과" },
  { value: "07", label: "흉부외과" },
  { value: "08", label: "성형외과" },
  { value: "09", label: "마취통증의학과" },
  { value: "10", label: "산부인과" },
  { value: "11", label: "소아청소년과" },
  { value: "12", label: "안과" },
  { value: "13", label: "이비인후과" },
  { value: "14", label: "피부과" },
  { value: "15", label: "비뇨기과" },
  { value: "16", label: "영상의학과" },
  { value: "17", label: "방사선종양학과" },
  { value: "18", label: "병리과" },
  { value: "19", label: "진단검사의학과" },
  { value: "20", label: "결핵과" },
  { value: "21", label: "재활의학과" },
  { value: "22", label: "핵의학과" },
  { value: "23", label: "가정의학과" },
  { value: "24", label: "응급의학과" },
  { value: "25", label: "직업환경의학과" },
  { value: "26", label: "예방의학과" },
  { value: "49", label: "치과" },
  { value: "50", label: "구강악안면외과" },
  { value: "51", label: "치과보철과" },
  { value: "52", label: "치과교정과" },
  { value: "53", label: "소아치과" },
  { value: "54", label: "치주과" },
  { value: "55", label: "치과보존과" },
  { value: "56", label: "구강내과" },
  { value: "57", label: "영상치의학과" },
  { value: "58", label: "구강병리과" },
  { value: "59", label: "예방치과" },
  { value: "60", label: "치과소계" },
  { value: "80", label: "한방내과" },
  { value: "81", label: "한방부인과" },
  { value: "82", label: "한방소아과" },
  { value: "83", label: "한방안·이비인후·피부과" },
  { value: "84", label: "한방신경정신과" },
  { value: "85", label: "침구과" },
  { value: "86", label: "한방재활의학과" },
  { value: "87", label: "사상체질과" },
  { value: "88", label: "한방응급" },
  { value: "90", label: "한방소계" },
  { value: "AA", label: "기관단위" },
  { value: "99", label: "해당사항없음" },
  { value: "$", label: "진료과목코드오류" },
];

const shwSbjtCodeOption = [
  { value: "00", label: "일반의" },
  { value: "01", label: "내과" },
  { value: "02", label: "신경과" },
  { value: "03", label: "정신건강의학과" },
  { value: "04", label: "외과" },
  { value: "05", label: "정형외과" },
  { value: "06", label: "신경외과" },
  { value: "07", label: "흉부외과" },
  { value: "08", label: "성형외과" },
  { value: "09", label: "마취통증의학과" },
  { value: "10", label: "산부인과" },
  { value: "11", label: "소아청소년과" },
  { value: "12", label: "안과" },
  { value: "13", label: "이비인후과" },
  { value: "14", label: "피부과" },
  { value: "15", label: "비뇨기과" },
  { value: "16", label: "진단방사선과, 영상의학과" },
  { value: "17", label: "방사선종양학과" },
  { value: "18", label: "병리과" },
  { value: "19", label: "진단검사의학과" },
  { value: "20", label: "결핵과" },
  { value: "21", label: "재활의학과" },
  { value: "22", label: "핵의학과" },
  { value: "23", label: "가정의학과" },
  { value: "24", label: "응급의학과" },
  { value: "25", label: "직업환경의학과" },
  { value: "26", label: "예방의학과" },
  { value: "27", label: "치과" },
  { value: "28", label: "한방" },
  { value: "99", label: "기타 / 해당없음 " },
];

const diseaseTreatOptions = [
  { value: "E11", label: "청년의 인슐린비의존당뇨병" },
  { value: "E14", label: "당뇨병 NOS" },
  { value: "G20", label: "원발성 파킨슨증 또는 파킨슨병" },
  { value: "G21", label: "이차성 파킨슨증" },
  { value: "G22", label: "매독성 파킨슨증(A52.1†)" },
  { value: "G30", label: "노년 및 초로성 형태" },
  { value: "H26", label: "기타 백내장" },
  { value: "H36", label: "달리 분류된 질환에서의 망막장애" },
  { value: "H40", label: "녹내장" },
  { value: "H81", label: "전정기능의 장애" },
  { value: "H90", label: "선천난청" },
  { value: "H91", label: "기타 청력소실" },
  { value: "H93", label: "달리 분류되지 않은 귀의 기타 장애" },
  { value: "I10", label: "고혈압" },
  { value: "I11", label: "고혈압에 의한 I50.-, I51.4-I51.9에서의 모든 병태" },
  { value: "I12", label: "신장경화증" },
  { value: "I13", label: "심혈관성 신장병" },
  { value: "I15", label: "이차성 고혈압" },
  {
    value: "I21",
    label:
      "급성으로 명시되었거나 또는 기재된 지속기간이 발병으로부터 4주(28일) 이하인 심근경색증",
  },
  { value: "I60", label: "거미막하출혈" },
  { value: "I61", label: "뇌내출혈" },
  { value: "I62", label: "기타 비외상성 두개내출혈" },
  {
    value: "I63",
    label:
      "뇌경색증을 유발하는 대뇌동맥 및 뇌전동맥(팔머리동맥간 포함)의 폐색 및 협착",
  },
  { value: "I64", label: "뇌혈관사고 NOS" },
  {
    value: "I65",
    label:
      "뇌경색증을 유발하지 않은, 뇌기저동맥, 경동맥 또는 척추동맥의 혈전증",
  },
  {
    value: "I66",
    label: "뇌경색증을 유발하지 않은, 중, 전, 후 대뇌동맥 및 소뇌동맥의 혈전증",
  },
  { value: "I68", label: "달리 분류된 질환에서의 뇌혈관장애" },
  { value: "I69", label: "뇌혈관질환의 후유증" },
  { value: "J18", label: "상세불명 병원체의 폐렴" },
  { value: "J37", label: "만성 후두염 및 후두기관염" },
  { value: "J38", label: "달리 분류되지 않은 성대 및 후두의 질환" },
  { value: "K10", label: "턱의 기타 질환" },
  { value: "K30", label: "상복부통증증후군" },
  { value: "K80", label: "담석증" },
  { value: "M06", label: "기타 류마티스관절염" },
  { value: "M17", label: "무릎관절증" },
  { value: "M24", label: "기타 특정 관절장애" },
  { value: "M25", label: "달리 분류되지 않은 기타 관절장애" },
  { value: "M41", label: "척주측후만증" },
  { value: "M48", label: "기타 척추병증" },
  { value: "M54", label: "등통증" },
  { value: "M62", label: "근육의 기타 장애" },
  { value: "M79", label: "달리 분류되지 않은 기타 연조직장애" },
  { value: "N30", label: "방광염" },
  { value: "N31", label: "달리 분류되지 않은 방광의 신경근육기능장애" },
  { value: "N39", label: "비뇨계통의 기타 장애" },
  { value: "N81", label: "골반장기 탈출증" },
  { value: "R25", label: "이상불수의운동" },
  { value: "R30", label: "배뇨와 연관된 통증" },
  { value: "R42", label: "현기증 NOS" },
  { value: "R49", label: "음성장애" },
  { value: "R91", label: "폐종괴 NOS" },
  { value: "S67", label: "손목 및 손의 으깸손상" },
  { value: "S68", label: "손목 및 손의 외상성 절단" },
  { value: "C16", label: "위의 악성 신생물" },
  { value: "C32", label: "후두의 악성 신생물" },
  { value: "C34", label: "기관지 및 폐의 악성 신생물" },
  { value: "C55", label: "자궁의 상세불명 부분의 악성 신생물" },
  { value: "C73", label: "갑상선의 악성 신생물" },
];

const treatDiseaseOptions = [
  { value: "G0430", label: "진단적투시촬영" },
  {
    value: "FA148",
    label: "특수전극뇌파검사(비인두또는단축나비뼈전극뇌파검사)-18채널이상",
  },
  { value: "FA111", label: "근전도검사(정량적검사)-상지[편측]" },
  { value: "FA145", label: "수면뇌파검사-18채널이상" },
  { value: "E7582", label: "후두미세진동검사법" },
  {
    value: "FA144",
    label: "수면뇌파검사-18채널이상(각성뇌파검사와동시에실시한경우)",
  },
  { value: "F6141", label: "각성뇌파검사-18채널미만" },
  { value: "FA112", label: "근전도검사(정량적검사)-하지[편측]" },
  { value: "FY681", label: "수술중신경생리추적감시[뇌파,유발전위,침근전도]" },
  { value: "F6142", label: "각성뇌파검사-18채널미만(이동뇌파를실시한경우)" },
  { value: "E6541", label: "심전도검사-심전도기록및판독[표준12유도]" },
  { value: "FA141", label: "각성뇌파검사-18채널이상" },
  { value: "E7202", label: "연속적중심정맥산소포화도측정[1일당]" },
  { value: "FA142", label: "각성뇌파검사-18채널이상(이동뇌파를실시한경우)" },
  { value: "F6114", label: "근전도검사-두부" },
  { value: "E7230", label: "경피적혈액산소포화도측정[1일당]" },
  { value: "F6111", label: "근전도검사-상지[편측]" },
  { value: "M0586", label: "고압산소요법-1시간까지" },
  { value: "F6113", label: "근전도검사-체간" },
  { value: "F6153", label: "지속적비디오뇌파검사[두개강외,1일당]-64채널이상" },
  { value: "F6112", label: "근전도검사-하지[편측]" },
  {
    value: "F6144",
    label: "수면뇌파검사-18채널미만(각성뇌파검사와동시에실시한경우)",
  },
  { value: "F6116", label: "근전도검사-기타(항문또는요도괄약근)" },
  { value: "M0588", label: "고압산소요법-2시간 초과 1일당" },
  { value: "MX034", label: "요실금전기자극치료[1일당]" },
  { value: "F6115", label: "근전도검사-기타(후두근)" },
  { value: "M0587", label: "고압산소요법-1시간을 초과하여 2시간까지" },
  {
    value: "M0582",
    label: "(상급종합병원·종합병원)고압산소요법-1시간을 초과하여 2시간까지",
  },
  {
    value: "FA147",
    label: "특수뇌파검사-약물또는물리적유발뇌파검사-18채널이상",
  },
  {
    value: "M0583",
    label: "(상급종합병원·종합병원)고압산소요법-2시간 초과 1일당",
  },
  { value: "F6151", label: "지속적비디오뇌파검사[두개강외,1일당]-64채널미만" },
  { value: "M0581", label: "(상급종합병원·종합병원)고압산소요법-1시간까지" },
  { value: "F6145", label: "수면뇌파검사-18채널미만" },
  { value: "FA115", label: "근전도검사(정량적검사)-기타(후두근)" },
  { value: "FA113", label: "근전도검사(정량적검사)-체간" },
];

export {
  defaultDataYearOption,
  menuNameCode,
  monthOption,
  ageOption,
  regionOption,
  inoutOption,
  diseaseOption,
  sexOption,
  periodCodeOption,
  valueNameCodeOption,
  rankLimitOption,
  recuClCodeOption,
  dgsbjtCodeOption,
  shwSbjtCodeOption,
  diseaseTreatOptions,
  treatDiseaseOptions,
};
