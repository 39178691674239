import * as React from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Link from "@mui/material/Link";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import { useForm, SubmitHandler } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import Modal from "./registerPopup";

function Copyright(props) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {"Copyright © "}
      <Link color="inherit" href="https://mui.com/"></Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const Register = () => {
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { isSubmitting, errors },
    watch,
  } = useForm();
  const [errorMessage, setErrorMessage] = React.useState();

  const onSignupSubmit = async (data) => {
    const response = await fetch(
      "http://222.113.80.171:8000/api/users/register",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          email: data.email,
          company_name: data.company_name,
          username: data.username,
          hashed_password: data.hashed_password,
          confirm_password: data.confirm_password,
        }),
      }
    );

    const result = await response.json();

    if (!response.ok) {
      if (typeof result.detail === "string") {
        setErrorMessage(result.detail);
      } else {
        setErrorMessage(result.detail[0].msg);
      }
    } else {
      navigate("/login");
    }
  };

  const [modalOpen, setModalOpen] = React.useState(true);

  const closeModal = () => {
    setModalOpen(false);
  };

  return (
    <Grid
      container
      component="main"
      sx={{
        height: "100vh",
      }}
    >
      <Modal
        open={modalOpen}
        close={closeModal}
        header="이용약관 & 개인정보 수집 및 이용에 대한 동의"
      />
      <CssBaseline />

      <Grid container justifyContent="center" alignItems="center">
        <Box
          sx={{
            my: 8,
            mx: 4,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            회원가입
          </Typography>
          <Box
            component="form"
            noValidate
            onSubmit={handleSubmit(onSignupSubmit)}
            sx={{ mt: 1, width: "30rem" }}
          >
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              autoComplete="email"
              autoFocus
              {...register("email", {
                required: "이메일은 필수 입력입니다.",
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                  message: "이메일 형식에 맞지 않습니다.",
                },
                validate: (val) => {
                  const frontAlias = val.split("@")[0];
                  if (frontAlias.length < 6) {
                    return "이메일이 너무 짧습니다.(최소 6자리 이상)";
                  }
                },
              })}
            />
            {errors.email && (
              <small style={{ color: "red" }}>{errors.email.message}</small>
            )}
            <TextField
              margin="normal"
              required
              fullWidth
              id="company_name"
              label="Company Name"
              autoComplete="company_name"
              {...register("company_name", {
                required: "회사명은 필수입니다.",
                minLength: {
                  value: 2,
                  message: "최소 2자리 이상입니다.",
                },
              })}
            />
            {errors.company_name && (
              <small style={{ color: "red" }}>
                {errors.company_name.message}
              </small>
            )}
            <TextField
              margin="normal"
              required
              fullWidth
              id="username"
              label="User Name"
              autoComplete="username"
              {...register("username", {
                required: "이름은 필수입니다.",
                minLength: {
                  value: 2,
                  message: "최소 2자리 이상입니다.",
                },
              })}
            />
            {errors.username && (
              <small style={{ color: "red" }}>{errors.username.message}</small>
            )}
            <TextField
              margin="normal"
              required
              fullWidth
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
              {...register("hashed_password", {
                required: "비밀번호는 필수 입력입니다.",
                minLength: {
                  value: 7,
                  message: "최소 7자리 이상입니다.",
                },
              })}
            />
            {errors.hashed_password && (
              <small style={{ color: "red" }}>
                {errors.hashed_password.message}
              </small>
            )}
            <TextField
              margin="normal"
              required
              fullWidth
              label="Confirm Password"
              type="password"
              id="confirm_password"
              {...register("confirm_password", {
                required: "비밀번호 확인은 필수 입력입니다.",
                validate: (confirm_password) => {
                  if (watch("hashed_password") !== confirm_password) {
                    return "비밀번호가 일치하지 않습니다.";
                  }
                },
              })}
            />
            {errors.confirm_password && (
              <small style={{ color: "red" }}>
                {errors.confirm_password.message}
              </small>
            )}
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
              disabled={isSubmitting}
            >
              회원가입
            </Button>
            {errorMessage && <p style={{ color: "red" }}>{errorMessage}</p>}
            <Grid container>
              <Grid item>
                <Link
                  component="button"
                  variant="body2"
                  onClick={() => navigate("/login")}
                >
                  {"이미 계정이 있으신가요?"}
                </Link>
              </Grid>
            </Grid>
            {/* <Copyright sx={{ mt: 5 }} /> */}
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
};

export default Register;
