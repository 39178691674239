const inputOptionToApiParams = (inputOptions) => {
  const transformfns = [
    { name: "patients", fn: patientsTransform },
    { name: "patientsRank", fn: patientsRankTransform },
    { name: "trendNormal", fn: apiParamTransform },
    { name: "trendYkiho", fn: apiParamTransform },
    { name: "rankNormal", fn: apiParamTransform },
    { name: "rankYkiho", fn: apiParamTransform },
  ];
  const transformfn = transformfns.filter(
    (s) => s.name === inputOptions.name
  )[0].fn;
  return transformfn(inputOptions);
};

const apiParamTransform = ({
  name,
  fromYear,
  fromMonth,
  toYear,
  toMonth,
  age,
  region,
  inout,
  sex,
  periodCode,
  valueNameCode,
  rankLimit,
  recuClCode,
  disease,
  dgsbjtCode,
  shwSbjtCode,
}) => {
  const tmpParams = {
    bgn_ym:
      fromMonth.length === 1
        ? fromYear + "0" + fromMonth
        : fromYear + fromMonth,
    end_ym: toMonth.length === 1 ? toYear + "0" + toMonth : toYear + toMonth,
    agg_cd: age !== "" ? parseInt(age, 10) : -1,
    sex_cd: sex,
    msick_cd3: disease,
    region_cd: region,
    hosp_cd: inout,
    period_cd: periodCode,
    value_nm_cd: valueNameCode,
    rank_limit: rankLimit,
    recu_cl_cd: recuClCode,
    dgsbjt_cd: dgsbjtCode,
    shw_sbt_cd: shwSbjtCode,
    name,
  };

  const params = {};
  for (let key of Object.keys(tmpParams)) {
    if (tmpParams[key] && tmpParams[key] !== -1 && tmpParams[key] !== "") {
      params[key] = tmpParams[key];
    }
  }
  return params;
};

const patientsRankTransform = ({
  fromYear,
  fromMonth,
  toYear,
  toMonth,
  age,
  region,
  inout,
  sex,
  periodCode,
  valueNameCode,
  rankLimit,
  recuClCode,
}) => {
  const tmpParams = {
    bgn_ym:
      fromMonth.length === 1
        ? fromYear + "0" + fromMonth
        : fromYear + fromMonth,
    end_ym: toMonth.length === 1 ? toYear + "0" + toMonth : toYear + toMonth,
    agg_cd: age !== "" ? parseInt(age, 10) : -1,
    sex_cd: sex,
    region_cd: region,
    hosp_cd: inout,
    period_cd: periodCode,
    value_nm_cd: valueNameCode,
    rank_limit: rankLimit,
  };
  const params = {};

  for (let key of Object.keys(tmpParams)) {
    if (tmpParams[key] !== -1 && tmpParams[key] !== "") {
      params[key] = tmpParams[key];
    }
  }
  return params;
};

const patientsTransform = ({
  fromYear,
  fromMonth,
  toYear,
  toMonth,
  age,
  region,
  inout,
  disease,
  sex,
}) => {
  const tmpParams = {
    msick_cd3: disease,
    bgn_ym:
      fromMonth.length === 1
        ? fromYear + "0" + fromMonth
        : fromYear + fromMonth,
    end_ym: toMonth.length === 1 ? toYear + "0" + toMonth : toYear + toMonth,
    agg_cd: age !== "" ? parseInt(age, 10) : -1,
    sex_cd: sex,
    region_cd: region,
    hosp_cd: inout,
  };
  const params = {};

  for (let key of Object.keys(tmpParams)) {
    if (tmpParams[key] !== -1 && tmpParams[key] !== "") {
      params[key] = tmpParams[key];
    }
  }

  return params;
};

export { inputOptionToApiParams };
