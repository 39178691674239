const trendToChartData = (trendData) => {
  const value_names = [
    { data_id: "cost_sum", data_name: "급여금액" },
    { data_id: "case_count", data_name: "진료건수" },
    { data_id: "patients_number", data_name: "환자수" },
  ];
  const chartData = value_names.map((value_name) => {
    return {
      id: value_name.data_name,
      data_id: value_name.data_id,
      data: trendData.map((s) => {
        return { x: s.yyyymm, y: s[value_name.data_id] };
      }),
    };
  });
  return chartData;
};

class DataTransform {
  constructor() {
    this.value_names = [
      { data_id: "cost_sum", data_name: "급여금액" },
      { data_id: "case_count", data_name: "진료건수" },
      { data_id: "patients_number", data_name: "환자수" },
    ];
    this.trendKeyName = [
      { data_id: "cost_sum", data_name: "급여금액" },
      { data_id: "case_count", data_name: "진료건수" },
      { data_id: "patients_number", data_name: "환자수" },
      { data_id: "yyyymm", data_name: "년월" },
    ];
  }

  trendToChartData = (trendData) => {
    const chartData = this.value_names.map((value_name) => {
      return {
        id: value_name.data_name,
        data_id: value_name.data_id,
        data: trendData.map((s) => {
          return { x: s.yyyymm, y: s[value_name.data_id] };
        }),
      };
    });
    return chartData;
  };

  detailBarchartData = (detailData, params) => {
    return detailData.map((s) => {
      return { ...s, name: params.name };
    });
  };

  trendToTableData = (trendData) => {
    function renameKey(obj, oldKey, newKey) {
      obj[newKey] = obj[oldKey];
      // delete obj[oldKey];
    }

    const tableData = [...trendData];

    this.trendKeyName.forEach((s) => {
      const oldKey = s.data_id;
      const newKey = s.data_name;
      tableData.forEach((obj) => {
        renameKey(obj, oldKey, newKey);
      });
    });

    return tableData;
  };
}

export { trendToChartData, DataTransform };
