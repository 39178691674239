import React, { useState, useRef, useEffect, useContext } from "react";
import Select from "react-select";
import styles from "./itemForm.module.css";
import { itemYearOption, itemClsfcNo } from "../../common/itemVariables";
import { inputValidation } from "../../service/submit_validation";
import { UserContext } from "../../context/UserContext";

const ItemForm = ({
  openlabApi,
  genClsfcTable,
  genBarChart,
  setSelectParams,
}) => {
  const [selectedYear, setSelectedYear] = useState("");
  const [clsfcNo, setClsfcNo] = useState("");

  const selectedYearRef = useRef();
  const clsfcNoRef = useRef();
  const formRef = useRef();

  const onDataEffect = async (apiParams) => {
    setSelectParams(apiParams);
    await genClsfcTable(apiParams);
    await genBarChart(apiParams);
  };

  const onSelectedYearChange = (option) => setSelectedYear(option);
  const onClsfcNoChange = (option) => setClsfcNo(option);

  const onSubmit = async (event) => {
    event.preventDefault();
    const inputOptions = {
      name: "itemClsfc",
      bgn_ym: selectedYear.value + "01" || "",
      end_ym: selectedYear.value + "01" || "",
      prdctClsfcNo: clsfcNo.value,
    };

    if (inputValidation(inputOptions)) {
      onDataEffect(inputOptions);
    } else {
      console.log(inputOptions);
      alert("필수항목을 선택해주세요");
    }
  };

  return (
    <>
      <form ref={formRef} className={styles.form}>
        <div className={styles.selectionWrapper}>
          <div className={styles.selection_item_container}>
            <div className={styles.option_name}>연도</div>
            <div className={styles.select_box}>
              <Select //
                options={itemYearOption}
                placeholder={"연도"}
                onChange={onSelectedYearChange}
                value={selectedYear}
                isSearchable={true}
                ref={selectedYearRef}
              />
            </div>
          </div>

          <div className={styles.selection_item_container}>
            <div className={styles.option_name}>상품구분코드</div>
            <div className={styles.select_box}>
              <Select //
                options={itemClsfcNo}
                placeholder={"상품구분코드"}
                onChange={onClsfcNoChange}
                value={clsfcNo}
                isSearchable={true}
                ref={clsfcNoRef}
              />
            </div>
          </div>
        </div>

        <button className={styles.submit_btn} onClick={onSubmit}>
          조회
        </button>
      </form>
    </>
  );
};

export default ItemForm;
