import React from "react";
import "./registerPopup.css";

const Modal = (props) => {
  // 열기, 닫기, 모달 헤더 텍스트를 부모로부터 받아옴
  const { open, close, header } = props;

  return (
    // 모달이 열릴때 openModal 클래스가 생성된다.
    <div className={open ? "openModal modal" : "modal"}>
      {open ? (
        <section>
          <header>{header}</header>
          <main>
            <div
              style={{ overflow: "scroll", width: "68.5vw", height: "24vh" }}
            >
              <h3 style={{ color: "red" }}>이용약관</h3>
              <p style={{ fontWeight: "bold" }}>제 1 조 (목적) </p>
              <br />
              이 약관은 강원테크노파크가 제공하는 오픈랩 기업 클라우드 신청 포털
              서비스(이하 "포털"이라 합니다)의 이용조건 및 절차에 관한 사항,
              포털과 이용자의 권리와 의무 및 책임에 관한 사항 등을 규정함을
              목적으로 합니다. <br />
              <br />
              <p style={{ fontWeight: "bold" }}>
                제 2 조 (약관의 효력 및 변경){" "}
              </p>
              <br />
              ① 이 약관은 포털을 이용하고자 하는 모든 이용자에 대하여 그 효력을
              발생합니다. <br />② 이 약관의 내용은 서비스 화면에 게시하거나
              기타의 방법으로 회원에게 공시하고, 회원이 서비스에 가입함으로써
              효력을 발생합니다. <br />
              ③ 포털은 필요하다고 인정되는 경우 본 약관을 사전 고지 없이 개정할
              수 있으며, 개정된 약관은 제13조에 정한 방법으로 공지합니다. 회원은
              개정된 약관에 동의하지 아니하는 경우 본인의 회원등록을 취소
              (회원탈퇴)할 수 있으며, 계속 사용하는 경우에는 약관 개정에 대한
              동의로 간주합니다. 개정된 약관은 공지와 동시에 그 효력이
              발생됩니다. <br />
              <br />
              <p style={{ fontWeight: "bold" }}>제 3 조 (약관이외의 준칙) </p>
              <br />
              본 약관에 명시되지 않은 사항에 대하여 관련 법령 등 규정이 있는
              경우에는 그 규정에 따릅니다. <br />
              <br />
              <p style={{ fontWeight: "bold" }}>제 4 조 (용어의 정의) </p>
              <br />
              이 약관에서 사용하는 용어의 정의는 다음과 같습니다. <br />
              ① 이용자 : 포털에서 제공하는 서비스를 받는 회원 및 비회원
              <br />
              ② 회원 : 포털과 서비스 이용계약을 체결한 개인이나 법인 또는 법인에
              준하는 단체 <br />
              ③ ID(고유번호) : 회원식별과 회원의 서비스 이용을 위하여 회원이
              선정하고 포털이 승인한 문자와 숫자의 조합 <br />
              ④ Password(비밀번호) : 회원의 비밀 보호를 위해 회원 자신이 설정한
              문자와 숫자 의 조합 <br />
              ⑤ 서비스 중지 : 정상 이용 중 포털이 정한 일정한 요건에 따라
              일정기간동안 서비스의 제공을 중지하는 것 <br />
              ⑥ 해지 : 포털 또는 회원이 서비스 이용계약을 체결한 이후 그
              이용계약을 종료시키는 의사표시 <br />
              <br />
              <p style={{ fontWeight: "bold" }}>제 5 조 (이용계약의 성립) </p>
              <br />① 고객은 이 약관에 동의한다는 의사표시를 한 후 포털이 정한
              가입양식에 따라 회원정보를 기입함으로써 회원가입을 신청합니다.{" "}
              <br />
              ② 이용계약은 고객의 이용신청에 대하여 포털이 승낙함으로써
              성립합니다. <br />
              <br />
              <p style={{ fontWeight: "bold" }}>제 6 조 (이용신청) </p>
              <br />
              이용신청은 서비스의 회원정보 화면에서 이용자가 가입신청 양식에
              개인의 신상정보를 기록하는 방식으로 신청합니다. <br />
              <br />
              <p style={{ fontWeight: "bold" }}>제 7 조 (이용신청의 승낙) </p>
              <br />
              포털은 제6조에서 정한 사항을 정확히 기재하여 이용신청을 하였을
              경우 특별한 사정이 없는 한 서비스 이용신청을 수락합니다. <br />
              <br />
              <p style={{ fontWeight: "bold" }}>
                제 8 조 (이용신청에 대한 승낙의 제한){" "}
              </p>
              <br />
              포털은 다음의 각 호에 해당하는 신청에 대하여는 승낙을 하지 않을 수
              있습니다. <br />
              ① 기술상 서비스 제공이 불가능한 경우 <br />
              ② 실명이 아니거나, 다른 사람의 명의 사용 등 등록 내용을 허위로
              기재하여 신청하는 경우 <br />
              ③ 등록사항을 누락하거나 오기하여 신청하는 경우 <br />
              ④ 사회의 질서 또는 미풍양속을 저해하거나, 저해할 목적으로 신청한
              경우 <br />
              ⑤ 기타 포털이 정한 이용신청 요건에 충족되지 않는 경우 <br />
              <br />
              <p style={{ fontWeight: "bold" }}>제 9 조 (이용계약의 변경) </p>
              <br />
              ① 회원은 이용 신청 시 등록한 사항이 변경되었을 경우 포털이 정한
              방법에 의하여 변경등록을 하여야 하며, 수정하지 아니하여 발생하는
              문제의 책임은 회원에게 있습니다. <br />
              ② 다음의 경우 포털은 직권으로 회원 ID를 변경하도록 요구하거나,
              회원의 신청에 의해 회원 ID를 변경 할 수 있습니다. <br />
              1. 회원 ID가 회원의 전화번호, 주민등록번호 등으로 등록되어 회원
              사생활 침해의 우려가 있는 경우 <br />
              2. 타인에게 혐오감을 주거나 미풍양속에 저해되는 경우 <br />
              <br />
              <p style={{ fontWeight: "bold" }}>제 10 조 (회원 탈퇴) </p>
              <br />
              회원이 서비스 이용계약을 해지하고자 할 경우 서비스 내 회원가입
              해지 화면에서 해지신청을 하여야 합니다. <br />
              <br />
              <p style={{ fontWeight: "bold" }}>제 11 조 (회원 자격의 상실) </p>
              <br />
              회원이 다음 각 호의 사유에 해당하는 경우, 포털은 사전 통지 없이
              이용계약을 해지 하거나 또는 서비스 이용을 중지할 수 있습니다.{" "}
              <br />
              ① 등록 신청 시 기재사항을 허위로 작성한 경우 <br />
              ② 제23조에 규정한 회원의 의무를 이행하지 않을 경우 <br />
              ③ 타인의 서비스 이용을 방해하거나 그 정보를 도용하는 등 서비스
              운영질서를 저해 하는 경우 <br />
              ④ 서비스를 이용하여 법령과 이 약관이 금지하거나, 미풍양속에 반하는
              행위를 하는 경우 <br />
              ⑤ 제2항 내지 제4항의 사유에 의한 회사조치에 대하여 회원은 포털이
              정한 절차에 따라 이의 신청을 할 수 있습니다. <br />
              ⑥ 제5항의 이의신청이 정당하다고 포털이 인정하는 경우, 포털은 즉시
              서비스의 이용을 재개합니다. <br />
              <br />
              <p style={{ fontWeight: "bold" }}>제 12 조 (회원 정보의 파기) </p>
              <br />
              포털은 회원이 마지막 서비스 이용일(최종 로그인 날)로부터 1년간
              서비스 이용 이력이 없을 경우 해당 회원의 정보를 파기한다. <br />
              ① 파기대상 항목은 회원 가입 시 등록한 모든 정보(이름, 비밀번호,
              이메일주소 등)이다. <br />
              ② 위 정보 파기 후 서비스를 이용하고자 하는 회원은 다시 회원 가입
              절차를 거쳐야 한다. <br />
              ③ 포털은 1년간 미이용 고객 정보의 파기 전 30일 이전에 이메일, SMS
              등을 통해 해당 회원에게 사전 안내한다. <br />
              ④ 회원 정보의 파기 기간을 달리 정하고자 할 경우 회원이 별도 기간을
              요청 할 수 있다. <br />
              <br />
              <p style={{ fontWeight: "bold" }}>제 13 조 (회원에 대한 통지) </p>
              <br />
              ① 포털이 회원에 대해 통지를 하는 경우, 회원이 포털에 제출한
              전자우편 주소로 할 수 있습니다. <br />
              ② 포털은 불특정 다수 회원에 대한 통지의 경우 서비스 게시판에
              게시함으로 써 개별통지에 갈음할 수 있습니다. <br />
              ③ 포털은 서비스를 통해 회원의 컴퓨터에 쿠키를 전송할 수 있습니다.
              회원은 쿠키 수신을 거부하거나 쿠키 수신에 대해 경고하도록
              브라우저설정을 변경할 수 있습니다. <br />
              <br />
              <p style={{ fontWeight: "bold" }}>
                제 14 조 (회원 정보 사용에 대한 동의){" "}
              </p>
              <br />① 포털이 이용 신청서에 기재를 요구하는 회원 정보는 본
              이용계약을 이행하고 이용 계약상 서비스 제공을 위한 목적으로
              이용합니다. <br />
              ② 회원들이 서비스를 편리하게 사용할 수 있도록 하기 위해 회원정보는
              포털에 제공될 수 있습니다. 이 경우 포털은 사전에 공지하며 이에
              동의하지 않는 회원은 등록을 취소 할 수 있습니다. 다만, 계속
              이용하는 경우 동의하는 것으로 간주합니다. <br />
              ③ 포털이 이용자의 개인 식별이 가능한 개인정보를 수집하는 때에는
              반드시 당해 이용자의 동의를 받습니다. <br />
              ④ 제공된 개인정보는 당해 이용자의 동의 없이 제3자에게 제공할 수
              없으며, 이에 대한 모든 책임은 회사가 집니다. 다만, 다음의 경우에는
              예외로 합니다. <br />
              1. 관계법령에 의하여 국가기관으로부터 요구받은 경우 <br />
              2. 범죄에 대한 수사상의 목적이 있거나, 정보통신 윤리위원회의
              요청이 있는 경우 <br />
              3. 통계작성, 학술연구 또는 시장조사를 위하여 필요한 경우로서 특정
              개인을 식별 할 수 없는 형태로 제공하는 경우 <br />
              4. 기타 관계법령에서 정한 절차에 따른 요청이 있는 경우 <br />⑤
              제10조(회원탈퇴) 및 제11조(회원자격의 상실)의 규정에 의하여 서비스
              이용계약이 종료된 때에는 포털은 회원정보를 즉시 삭제합니다. <br />
              <br />
              <p style={{ fontWeight: "bold" }}>
                제 15 조 (서비스의 이용 시간){" "}
              </p>
              <br />
              ① 서비스의 이용은 연중무휴 1일 24시간을 원칙으로 합니다. 다만,
              포털의 업무상이나 기술상의 사유로 서비스가 일시 중지될 수 있으며,
              운영상의 목적으로 포털이 정한 기간 동안 서비스가 일시 중지될 수
              있습니다. 이러한 경우에는 사전 또는 사후에 이를 공지합니다. <br />
              ② 포털은 회원의 이용신청을 승낙한 때부터 서비스를 무료로
              개시합니다. 단, 포털의 업무상 또는 기술상의 장애로 인해 서비스를
              곧바로 개시하지 못하는 경우 그 내용을 공지 합니다. <br />③ 포털은
              서비스를 일정범위로 분할하여 각 범위별로 이용 가능한 시간을 별도로
              정할 수 있으며, 이 경우 그 내용을 사전에 공지합니다. <br />
              ④ 회원에 가입한 후라도 일부 서비스 이용 시 서비스 제공자의 요구에
              따라 특정 회원에게만 서비스를 제공할 수도 있습니다. <br />⑤ 포털은
              서비스를 일정범위로 분할하여 각 범위별로 이용가능 시간을 별도로
              정할 수 있습니다. 이 경우 그 내용을 사전에 공지합니다. <br />
              <br />
              <p style={{ fontWeight: "bold" }}>제 16 조 (서비스의 내용) </p>
              <br />
              ① 포털에서는 포털이 지정한 서비스를 제공합니다. <br />② 포털이
              필요한 경우 서비스의 내용을 추가 또는 변경할 수 있습니다. <br />
              <br />
              <p style={{ fontWeight: "bold" }}>
                제 17 조 (게시물 또는 내용물의 삭제){" "}
              </p>
              <br />
              포털은 서비스의 게시물 또는 내용물이 관계법령에 위반되거나
              게시기간을 초과하는 경우 사전 통지나 동의 없이 이를 보완 또는 삭제
              할 수 있습니다. <br />
              <br />
              <p style={{ fontWeight: "bold" }}>
                제 18 조 (서비스 제공의 중지){" "}
              </p>
              <br />
              ① 포털은 다음 각 호에 해당하는 경우 서비스의 전부 또는 일부를
              제한하거나 중지 할 수 있습니다. <br />
              1. 서비스용 설비의 보수 등 공사로 인한 부득이한 경우 <br />
              2. 회원이 포털의 영업활동을 방해하는 경우 <br />
              3. 정전, 제반 설비의 장애 또는 이용량의 폭주 등으로 정상적인
              서비스 이용에 지장이 있는 경우 <br />
              4. 제23조에 따른 회원의 의무를 다하지 아니한 경우 <br />
              5. 기타 천재지변, 국가비상사태 등의 불가항력적 사유가 있는 경우{" "}
              <br />
              ② 제1항에 의한 서비스 중단의 경우에는 제12조에 정한 방법으로
              이용자에게 통지합니다. <br />
              ③ 포털은 제1항의 사유로 서비스의 제공이 일시적으로 중단됨으로
              인하여 이용자 또는 제 3자가 입은 손해에 대하여 배상하지
              아니합니다. 단, 고의 또는 중과실이 있는 경우에는 그러하지
              아니합니다. <br />
              <br />
              <p style={{ fontWeight: "bold" }}>
                제 19 조 (정보의 제공 및 광고의 게재){" "}
              </p>
              <br />① 포털은 서비스를 운영함에 있어 각종 정보를 서비스에
              게재하거나 전자우편 및 서신우편 등의 방법으로 회원에게 제공할 수
              있습니다. <br />
              ② 포털은 서비스의 운용과 관련하여 서비스 화면, 홈페이지,
              E-Mail등에 광고 등을 게재할 수 있습니다. <br />
              <br />
              <p style={{ fontWeight: "bold" }}>
                제 20 조 (게시물 또는 내용물의 삭제){" "}
              </p>
              <br />
              ① 포털은 회원이 게시하거나 전달하는 서비스 내의 모든 내용물이
              다음의 경우에 해당 한다고 판단되는 경우 사전통지 없이 삭제할 수
              있으며, 이에 대해 포털은 어떠한 책임도 지지 않습니다. <br />
              1. 다른 회원 또는 제3자를 비방하거나 중상모략으로 명예를
              손상시키는 내용인 경우 <br />
              2. 공공질서 및 미풍양속에 위반되는 내용인 경우 <br />
              3. 범죄적 행위에 결부된다고 인정되는 내용인 경우 <br />
              4. 포털 또는 제3자의 저작권 및 기타의 권리를 침해하는 내용인 경우{" "}
              <br />
              5. 기타 관계법령 및 회사의 세부 지침 등에 위반된다고 판단되는 경우{" "}
              <br />
              ② 포털은 게시물에 관련된 세부 이용지침을 별도로 정하여 시행할 수
              있으며, 회원은 그 지침에 따라 각종 게시물을 등록 또는 삭제하여야
              합니다. <br />
              <br />
              <p style={{ fontWeight: "bold" }}>
                제 21 조 (게시물에 대한 권리 및 책임){" "}
              </p>
              <br />
              ① 포털은 회원이 게시하거나 등록하는 서비스 내의 모든 내용물이 다음
              각 호의 경우에 해당된다고 판단되는 경우 사전 통지 없이 삭제할 수
              있으며, 이에 대해 포털은 어떠한 책임도 지지 않습니다. <br />
              1. 포털, 다른 회원 또는 제3자를 비방하거나 중상 모략으로 명예를
              손상시키는 내용인 경우 <br />
              2. 공공질서 및 미풍양속에 위반되는 내용인 경우 <br />
              3. 범죄적 행위에 결부된다고 인정되는 내용일 경우 <br />
              4. 제3자의 저작권 등 기타 권리를 침해하는 내용인 경우 <br />
              5. 서비스 성격에 부합하지 않는 정보의 경우 <br />
              6. 기타 관계 법령 및 회사에서 정한 규정 등에 위배되는 경우 <br />
              ② 포털이 작성한 저작물에 대한 저작권 및 기타 지적재산권은 포털에
              있습니다. <br />
              ③ 회원은 서비스를 이용하여 얻은 정보를 가공, 판매하는 행위 등
              서비스에 게재된 자료를 영리목적으로 이용하거나 제3자에게 이용하게
              할 수 없으며, 게시물에 대한 저작권침해는 관계법령에 적용을
              받습니다. <br />
              <br />
              <p style={{ fontWeight: "bold" }}>제 22 조 (서비스 이용책임) </p>
              <br />
              회원은 포털에서 공식적으로 인정한 경우를 제외하고는 서비스를
              이용하여 어떠한 종류의 영업활동도 할 수 없으며 특히 해킹, 광고를
              통한 수익창출행위, 음란사이트를 통한 상업행위, 상용S/W 불법배포
              등을 할 수 없습니다. <br />
              이를 어기고 발생한 영업활동의 결과 및 손실, 관계기관에 의한 구속
              등 법적 조치에 관해서는 포털이 책임을 지지 않으며, 회원은 이와
              같은 영업활동 등과 관련하여 포털에 대하여 손해배상 의무를 집니다.{" "}
              <br />
              <br />
              <p style={{ fontWeight: "bold" }}>제 23 조 (포털의 의무) </p>
              <br />
              ① 포털은 특별한 사유가 없는 한 서비스 제공 설비를 항상 운용 가능한
              상태로 유지 보수하여야 하며, 안정적으로 서비스를 제공할 수 있도록
              최선의 노력을 다하여야 합니다. <br />
              ② 포털은 이용자가 안전하게 서비스를 이용할 수 있도록 이용자의
              개인정보 보호를 위한 보안시스템을 갖추어야 합니다. <br />
              ③ 포털은 서비스와 관련한 회원의 불만사항이 접수되는 경우 이를
              즉시처리 할 수 있도록 최선을 다하여야 하며 이와 관련된 접수된
              내용은 미공개로 처리합니다. <br />
              <br />
              <p style={{ fontWeight: "bold" }}>제 24 조 (회원의 의무) </p>
              <br />
              ① 회원은 관계 법령, 본 약관의 규정, 이용안내 및 서비스 상에 공지한
              주의사항, 포털이 통지하는 사항을 준수 하여야 하며, 포털의 업무에
              방해되는 행위를 하여서는 안됩니다. <br />
              ② 회원은 포털의 사전 동의 없이 서비스를 이용하여 어떠한
              영리행위도할 수 없으며, 법에 저촉되는 자료를 배포 또는 게재할 수
              없습니다. <br />
              ③ 회원은 전화번호, 이메일주소 등 신상정보의 변경사항 발생 시 즉각
              변경하여야 합니다. 회원정보를 수정하지 않아 발생하는 모든 결과에
              대한 책임은 회원에게 있습니다. <br />
              ④ 회원은 서비스와 관련하여 다음과 같은 행위를 하지 않기로
              동의합니다. <br />
              1. 회원가입 신청 또는 회원정보 변경시 허위내용의 등록 <br />
              2. 서비스에 게시된 정보의 변경 <br />
              3. 포털이 정한 정보 이외의 정보(컴퓨터프로그램 등)의 송신 또는
              게시 <br />
              4. 다른 회원의 ID를 부정하게 사용하는 행위 <br />
              5. 해킹행위 또는 컴퓨터 바이러스 유포행위 <br />
              6. 포털 혹은 기타 제3자의 저작권 등 지적재산권에 대한 침해 <br />
              7. 포털의 서비스 운영의 방해 <br />
              8. 포털 혹은 기타 제3자의 명예를 손상시키거나 업무를 방해하는 행위{" "}
              <br />
              9. 외설 또는 폭력적인 메시지/화상/음성 기타 미풍양속에 반하는
              정보를 서비스에 공개 또는 게시하는 행위 <br />
              10. 타인(소수를 포함)에게 여러 위해를 가하는 행위 <br />
              <br />
              <p style={{ fontWeight: "bold" }}>
                제 25 조 (회원 ID와 비밀번호 관리에 대한 회원의 의무와 책임){" "}
              </p>
              <br />
              ① ID와 비밀번호에 관한 모든 관리책임은 회원에게 있습니다. 회원에게
              부여된 ID와 비밀번호의 관리소홀, 부정사용에 의하여 발생하는 모든
              결과에 대한 책임은 회원에게 있습니다. <br />
              ② 회원은 자신의 ID 및 비밀번호를 제3자에게 이용하게 해서는 안되며,
              자신의 ID및 비밀번호를 도난 당하거나 제3자가 사용하고 있음을
              인지하는 경우에는 즉시 포털에 통보하여야 하며 포털의 안내가 있는
              경우 그에 따라야 합니다. <br />
              <br />
              <p style={{ fontWeight: "bold" }}>제 26 조 (양도 금지) </p>
              <br />
              회원은 서비스의 이용권한, 기타 이용 계약상 지위를 타인에게 양도,
              증여할 수 없으며 이를 담보로 제공할 수 없습니다. <br />
              <br />
              <p style={{ fontWeight: "bold" }}>제 27 조 (손해배상) </p>
              <br />
              ① 포털이 제공하는 서비스와 관련하여 회원에게 어떠한 손해가
              발생하더라도 포털의 중대한 과실에 의한 경우를 제외하고 이에 대하여
              책임을 지지 않습니다. <br />
              ② 포털은 회원이 제23조에 따른 의무를 다하지 않아 회원에게 발생한
              손해에 대해서는 어떠한 경우라도 책임을 지지 않습니다. <br />
              <br />
              <p style={{ fontWeight: "bold" }}>제 28 조 (면책사항) </p>
              <br />
              ① 포털은 회원이 서비스를 통해 게재 또는 전송한 정보, 자료, 사실의
              정확성, 신뢰성 등 내용에 관하여 어떠한 보증도 하지 아니하며,
              회원의 서비스 자료에 대한 취사 선택 또는 이용으로 발생하는 손해
              등에 대해 책임을 지지 아니합니다. <br />
              ② 포털은 회원이 서비스의 이용과 관련하여 기대하는 이익의 상실이나
              서비스를 통하여 얻은 자료로 인한 손해 등에 관하여 책임을 지지 아니
              합니다. <br />
              ③ 포털은 회원 상호간 또는 회원과 제3자 상호간에 서비스를 매개로
              발생한 분쟁에 대해서는 개입할 의무가 없으며 이로 인한 손해를
              배상할 책임도 없습니다. <br />
              ④ 포털은 회원의 귀책사유로 인하여 서비스 이용의 장애가 발생한
              경우에는 책임이 면제됩니다. 특히, E-Mail발송과 관련하여 회원 측
              서버의 장애나 문제로 발생한 미수신에 관하여 포털은 책임을 지지
              아니합니다. <br />
              ⑤ 본 약관의 규정을 위반함으로 인하여 포털에 손해가 발생하게 되는
              경우, 이 약관을 위반한 회원은 포털에 발생되는 모든 손해를
              배상하여야하며, 동손해로부터 포털은 면책시켜야 합니다. <br />
              ⑥ 포털은 서비스에서 제공되는 전자우편의 메시지, 게시판의 게시물
              등이 보유되는 최대일수, 송수신 할 수 있는 전자우편 메시지의
              최대크기 등을 제한 할 수 있습니다. <br />
              <br />
              <p style={{ fontWeight: "bold" }}>제 29 조 (분쟁의 해결) </p>
              <br />
              ① 포털과 회원은 서비스와 관련하여 발생한 분쟁을 원만하게 해결하기
              위하여 필요한 모든 노력을 하여야 합니다. <br />
              ② 제1항의 노력에도 불구하고 분쟁이 해결되지 않을 경우, 양 당사자는
              민사 소송법상의 관할법원에 소송을 제기할 수 있습니다. <br />
              ③ 동 소송에는 대한민국 법령을 적용합니다. <br />
              부칙 <br />
              <br />
              (시행일) 이 약관은 2016년 12월 01일부터 시행합니다
            </div>
            <div
              style={{ overflow: "scroll", width: "68.5vw", height: "24vh" }}
            >
              <h3 style={{ color: "red" }}>
                개인정보 수집 및 이용에 대한 동의
              </h3>
              포털에서 취급하는 모든 개인정보는 관련 법령에 근거하거나
              정보주체의 동의에 의하여 수집·보유 및 처리되고 있으며, 보유하고
              있는 개인정보를 보호하고 정보주체의 고충을 신속하고 원활하게
              처리할 수 있도록 개인정보보호법 제30조에 따라 다음과 같이 개인
              정보 처리방침을 수립·공개합니다. 개인정보파일 중 해당 홈페이지가
              있는 경우『개인정보 보호법』제30조 제1항 및 동법『시행령』제31조
              제1항의 규정에 의하여 다음 사항에 대해 『개인정보처리방침』을
              정하여 운영하고(개인정보가 있는 홈페이지에 개인정보처리방침 개별
              공개) 있습니다. <br />
              <br />
              <p style={{ fontWeight: "bold" }}>제1조(개인정보의 처리목적) </p>
              <br />
              포털은 다음의 목적을 위하여 개인정보를 처리합니다. 처리하고 있는
              개인정보는 다음의 목적 이외의 용도로는 이용되지 않으며, 이용
              목적이 변경되는 경우에는 개인정보보호법 제18조에 따라 별도의
              동의를 받는 등 필요한 조치를 이행할 예정입니다. <br />
              <br />
              홈페이지 관리 <br />
              회원 가입의사 확인, 회원제 서비스 제공에 따른 본인 식별·인증,
              회원자격 유지·관리, 제한적 본인확인제 시행에 따른 본인확인, 서비스
              부정이용 방지, 각종 고지·통지, 고충처리 등을 목적으로 개인정보를
              처리합니다. <br />
              <br />
              <p style={{ fontWeight: "bold" }}>
                제2조(개인정보의 처리 및 보유기간){" "}
              </p>
              <br />
              포털은 법령에 따른 개인정보 보유·이용기간 또는 정보주체로부터 동의
              받은 개인정보 보유·이용기간 내에서 개인정보를 처리·보유합니다.{" "}
              <br />
              개인정보보호법 제32조에 따라 등록·공개하는 각각의 개인정보파일의
              개인정보 처리 및 보유기간에 대한 자세한 사항은 제1조 제2항과
              같습니다. <br />
              <br />
              <p style={{ fontWeight: "bold" }}>
                제3조(개인정보의 제3자 제공){" "}
              </p>
              <br />
              포털은 개인정보를 제1조에서 명시한 범위 내에서만 처리하며,
              정보주체의 동의, 법률의 특별한 규정 등 개인정보보호법 제17조 및
              제18조에 해당하는 경우에만 개인정보를 제3자에게 제공합니다. <br />
              <br />
              <p style={{ fontWeight: "bold" }}>제4조(개인정보처리의 위탁) </p>
              <br />
              포털은 위탁계약 체결시 개인정보보호법 제25조에 따라 위탁업무
              수행목적 외 개인정보 처리금지, 기술적·관리적 보호조치, 재위탁
              제한, 수탁자에 대한 관리·감독, 손해배상 등 책임에 관한 사항을
              계약서 등 문서에 명시하고, 수탁자가 개인정보를 안전하게
              처리하는지를 감독하고 있습니다. <br />
              위탁업무의 내용이나 수탁자가 변경될 경우에는 지체 없이 본 개인정보
              처리방침을 통하여 공개하도록 하겠습니다. <br />
              <br />
              <p style={{ fontWeight: "bold" }}>
                제5조(정보주체의 권리·의무 및 행사방법){" "}
              </p>
              <br />
              정보주체는 포털의 제1조 제2항에 따라 보유하고 있는 개인정보에 대해
              언제든지 다음 각 호의 권리를 포털에 행사할 수 있습니다. <br />
              정보주체의 권리·의무 및 행사방법 <br />
              1. 열람청구 <br />
              2. 정정·삭제청구 <br />
              3. 처리정지청구 <br />
              <br />
              제1항에 따른 권리 행사는 포털에 대해 개인정보보호법 시행규칙 별지
              제8호 서식에 따라 서면, 전자우편 등을 통하여 하실 수 있으며 포털은
              이에 대해 지체없이 조치하겠습니다. 정보주체가 개인정보의 오류등에
              대한 정정 또는 삭제를 요구한 경우에는 정정 또는 삭제를 완료할
              때까지 당해 개인정보를 이용하거나 제공하지 않습니다. 제1항에 따른
              권리 행사는 정보주체의 법정대리인이나 위임을 받은 자 등 대리인을
              통하여 하실 수 있습니다. 이 경우 개인정보 보호법 시행규칙 별지
              제11호 서식에 따른 위임장을 제출하셔야 합니다. <br />
              정보주체께서는 제1항의 권리를 포털 이외에, 안전행정부의
              "개인정보보호 종합지원 포털" 웹사이트(www.privacy.go.kr)에서도
              개인정보를 청구 하실 수 있습니다. <br />
              ※ 안전행정부 개인정보보호 종합지원 포털 → 개인정보 민원 → 개인정보
              열람등 요구 (본인 확인을 위하여 공공아이핀(I-PIN)을 통한 실명인증
              필요) <br />
              정보주체가 개인정보의 오류 등에 대한 정정 또는 삭제를 요구한
              경우에 포털은 정정 또는 삭제를 완료할 때까지 당해 개인정보를
              이용하거나 제공하지 않습니다. 정보주체는 개인정보보호법 등 관계
              법령을 위반하여 포털이 처리하고 있는 정보주체 본인이나 타인의
              개인정보 및 사생활을 침해하여서는 아니됩니다. <br />
              <br />
              <p style={{ fontWeight: "bold" }}>
                제6조(처리하는 개인정보 항목){" "}
              </p>
              <br />
              포털의 개인정보보호법 제32조에 따라 등록·공개하는 각각의
              개인정보파일의 개인정보 항목은 제1조 제2항과 같습니다. <br />
              포털 홈페이지 등의 인터넷 서비스 이용과정에서 인터넷서버 도메인과
              방문일시 및 홈페이지내 방문 기록 정보 등은 자동적으로
              수집·저장되며 이러한 정보는 관련 법령에 따라 제출할 수 있습니다.{" "}
              <br />
              <br />
              <p style={{ fontWeight: "bold" }}>제7조(개인정보의 파기) </p>
              <br />
              포털은 개인정보 보유기간의 경과, 처리목적 달성 등 개인정보가
              불필요하게 되었을 때에는 지체 없이 해당 개인정보를 파기합니다.{" "}
              <br />
              정보주체로부터 동의 받은 개인정보 보유기간이 경과하거나 처리목적이
              달성되었음에도 불구하고 다른 법령에 따라 개인정보를 계속
              보존하여야 하는 경우에는, 해당 개인정보(또는 개인정보파일)는
              별도의 데이터베이스(DB)로 옮기거나 보관장소를 달리하여 보존합니다.{" "}
              <br />
              개인정보 파기의 절차 및 방법은 다음과 같습니다. <br />
              <br />
              파기절차 <br />
              파기사유가 발생한 개인정보를 선정하고 파기계획을 수립하는 등 포털
              개인정보 보호책임자(또는 분야별 관리책임자)의 승인을 받아
              개인정보를 파기합니다. <br />
              <br />
              파기방법 <br />
              전자적 파일 형태로 기록·저장된 개인정보는 복원이 불가능한 방법으로
              영구 삭제하며, 전자적 파일 외의 기록물, 서면, 그 밖의 기록매체에
              저장된 개인정보인 경우에는 분 쇄기로 분쇄하거나 소각하여
              파기합니다. <br />
              <br />
              <p style={{ fontWeight: "bold" }}>
                제8조(개인정보 보호책임 및 열람청구){" "}
              </p>
              <br />
              포털은 개인정보 처리에 관한 업무를 총괄해서 책임지고, 개인정보
              처리와 관련한 정보주체의 불만처리 및 피해구제 등을 위하여 아래와
              같이 개인정보 보호책임자를 지정하고 있습니다. <br />
              - 개인정보보호 책임관 : 최창영소장
              <br />
              - 분야별 개인정보 책임관 : 최창영 소장 <br />
              - 이메일 : ccy333@infracube.co.kr <br />
              - 전화번호 : 070-7774-3600 <br />
              <br />
              ※ 개인정보침해신고센터 : 국번없이 1366 <br />
              정보주체께서는 포털의 서비스(또는 사업)를 이용하시면서 발생한 모든
              개인정보 보호 관련 문의, 불만처리, 피해구제 등에 관한 사항을
              개인정보 보호책임자 및 담당자에게 문의하실 수 있습니다. 포털은
              정보주체의 문의에 대해 지체 없이 답변 및 처리해드릴 것입니다.{" "}
              <br />
              <br />
              <p style={{ fontWeight: "bold" }}>
                제9조(개인정보의 안전성 확보조치){" "}
              </p>
              <br />
              포털은 개인정보보호법 제29조에 따라 다음과 같이 안전성 확보에
              필요한 기술적/관리적 및 물리적 조치를 하고 있습니다. <br />
              <br />
              개인정보 취급 직원의 최소화 및 교육 <br />
              개인정보를 취급하는 직원을 지정하고 담당자에 한정시켜 최소화 하여
              개인정보를 관리하는 대책을 시행하고 있습니다. <br />
              <br />
              내부관리계획의 수립 및 시행 <br />
              개인정보의 안전한 처리를 위하여 내부관리계획을 수립하고 시행하고
              있습니다. <br />
              <br />
              개인정보의 암호화 <br />
              이용자의 개인정보는 비밀번호는 암호화 되어 저장 및 관리되고 있어,
              본인만이 알 수 있으며 중요한 데이터는 파일 및 전송 데이터를 암호화
              하거나 파일 잠금 기능을 사용하는 등의 별도 보안기능을 사용하고
              있습니다. <br />
              <br />
              해킹 등에 대비한 기술적 대책 <br />
              해킹이나 컴퓨터 바이러스 등에 의한 개인정보 유출 및 훼손을 막기
              위하여 보안프로그램을 설치하고 주기적인 갱신,점검을 시행하며
              외부로부터 접근이 통제된 구역에 시스템을 설치하고
              기술적/물리적으로 감시 및 차단하고 있습니다. <br />
              <br />
              개인정보에 대한 접근 제한 <br />
              개인정보를 처리하는 데이터베이스시스템에 대한 접근권한의
              부여,변경,말소를 통하여 개인정보에 대한 접근통제를 위하여 필요한
              조치를 하고 있으며 침입차단시스템을 이용하여 외부로부터의 무단
              접근을 통제하고 있습니다. <br />
              <br />
              접속기록의 보관 및 위변조 방지 <br />
              개인정보처리시스템에 접속한 기록을 최소 6개월 이상 보관, 관리하고
              있으며, 접속 기록이 위변조 및 도난, 분실되지 않도록 보안기능
              사용하고 있습니다. <br />
              <br />
              문서보안을 위한 잠금장치 사용 <br />
              개인정보가 포함된 서류, 보조저장매체 등을 잠금장치가 있는 안전한
              장소에 보관하고 있습니다. <br />
              <br />
              비인가자에 대한 출입 통제 <br />
              개인정보를 보관하고 있는 물리적 보관 장소를 별도로 두고 이에 대해
              출입통제 절차를 수립, 운영하고 있습니다. <br />
              <br />
              <p style={{ fontWeight: "bold" }}>제10조(권익침해 구제방법) </p>
              <br />
              개인정보 관련 민원 및 침해신고 처리절차는 다음과 같습니다. <br />
              1. 침해신고접수 <br />
              2. 침해사실조사 <br />
              3. 결과통보 <br />
              4.위법사실조치 <br />
              <br />
              정보주체는 아래의 기관에 대해 개인정보 침해에 대한 피해구제, 상담
              등을 문의하실 수 있습니다. <br />
              <br />
              개인정보 침해신고센터(한국인터넷진흥원 운영) <br />
              소관업무 : 개인정보 침해사실 신고, 상담 신청 <br />
              홈페이지 : privacy.kisa.or.kr <br />
              전 화 : (국번없이) 118 <br />
              주소:(138-950)서울 송파구 중대로 135 한국인터넷진흥원
              개인정보침해신고센터 <br />
              <br />
              개인정보 분쟁조정위원회(한국인터넷진흥원 운영) <br />
              소관업무 : 개인정보 분쟁조정신청, 집단분쟁조정 <br />
              홈페이지 : privacy.kisa.or.kr <br />
              전 화 : (국번없이) 118 <br />
              주소:(138-950)서울 송파구 중대로 135 한국인터넷진흥원 개인정보
              침해신고센터 <br />
              <br />
              대검찰청 사이버범죄수사단 : 02-3480-3573 (www.spo.go.kr) <br />
              경찰청 사이버테러대응센터 : 1566-0112 (www.netan.go.kr)
            </div>
          </main>
          <footer>
            <button className="close" onClick={close}>
              동의
            </button>
          </footer>
        </section>
      ) : null}
    </div>
  );
};

export default Modal;
