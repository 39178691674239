import React from "react";
import styles from "./bizSupProcess.module.css";
import processImg from "../../img/support/datacenter_process.png";

const BizSupProcess = (props) => (
  <div className={styles.dataContainer}>
    <div className={styles.content_title}>
      <h2>의료빅데이터센터분석 프로세스</h2>
    </div>
    <div className={styles.data_img_content}>
      <img className={styles.data_img} src={processImg} alt="" />
    </div>
  </div>
);

export default BizSupProcess;
