import React from "react";
import PatientsTrendYkihoForm from "./forms/patientsTrendYkihoForm";

const patientsTrendYkihoInput = ({ upDateApiParams, updateRadioValue }) => {
  const formName = "trendYkiho";

  return (
    <>
      <PatientsTrendYkihoForm
        upDateApiParams={upDateApiParams}
        formName={formName}
        updateRadioValue={updateRadioValue}
      />
    </>
  );
};

export default patientsTrendYkihoInput;
