import React, { useState, useRef } from "react";
import Select from "react-select";
import styles from "./patientsForm.module.css";
import {
  defaultDataYearOption,
  monthOption,
  regionOption,
  inoutOption,
  ageOption,
  diseaseOption,
  sexOption,
} from "../../../common/variables";

import PatientsValueRadio from "./paritentsValueRadio";
import {
  inputValidation,
  inputPeriodValidation,
} from "../../../service/submit_validation";

const PatientsTrendForm = ({ formName, upDateApiParams, updateRadioValue }) => {
  const [endYearOptions, setEndYearOptions] = useState(defaultDataYearOption);
  const [fromYear, setFromYear] = useState("");
  const [fromMonth, setFromMonth] = useState("");
  const [toYear, setToYear] = useState("");
  const [toMonth, setToMonth] = useState("");
  const [age, setAge] = useState("");
  const [region, setRegion] = useState("");
  const [sex, setSex] = useState("");
  const [inout, setInout] = useState("");
  const [disease, setDisease] = useState("");

  const startYearRef = useRef();
  const startMonthRef = useRef();
  const endYearRef = useRef();
  const endMonthRef = useRef();
  const formRef = useRef();
  const ageRef = useRef();
  const sexRef = useRef();
  const regionRef = useRef();
  const inoutRef = useRef();
  const diseaseRef = useRef();

  const onStartYearChange = (option) => {
    setFromYear(option);
    setEndYearOptions(
      defaultDataYearOption.filter(
        (yearOption) => yearOption.value >= option.value
      )
    );
  };

  const onEndYearChange = (option) => setToYear(option);
  const onStartMonthChange = (option) => setFromMonth(option);
  const onEndMonthChange = (option) => setToMonth(option);
  const onAgeChange = (option) => setAge(option);
  const onRegionChange = (option) => setRegion(option);
  const onSexChange = (option) => setSex(option);
  const onInoutChange = (option) => setInout(option);
  const onDiseaseChange = (option) => setDisease(option);

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      fontWeight: state.isSelected ? "bold" : "normal",
      color: "black",
      fontSize: state.selectProps.myFontSize,
    }),
    singleValue: (provided, state) => ({
      ...provided,
      fontSize: state.selectProps.myFontSize,
    }),
  };

  const onSubmit = (event) => {
    event.preventDefault();
    console.log(age);
    const inputOptions = {
      name: formName,
      fromYear: fromYear.value || "",
      fromMonth: fromMonth.value || "",
      toYear: toYear.value || "",
      toMonth: toMonth.value || "",
      disease: disease.value || "",
      age: Boolean(age) ? age.value || "" : "",
      region: Boolean(region) ? region.value || "" : "",
      inout: Boolean(inout) ? inout.value || "" : "",
      sex: Boolean(sex) ? sex.value || "" : "",
    };
    console.log("inputOptions", inputOptions);

    if (inputPeriodValidation(inputOptions)) {
      if (inputValidation(inputOptions)) {
        upDateApiParams(inputOptions);
      } else {
        alert("필수항목을 선택해주세요");
      }
    } else {
      alert("기간을 3년내로 설정해주세요");
    }
  };
  const selectClassName = "apsSelectBox";

  return (
    <>
      <form ref={formRef} className={styles.form}>
        <div className={styles.selectionWrappers}>
          <div className={styles.selectionRow}>
            <div className={styles.selection_period_container}>
              <div className={styles.option_name}>*기간 </div>
              <div className={styles.select_box}>
                <Select //
                  value={fromYear}
                  onChange={onStartYearChange}
                  options={defaultDataYearOption}
                  placeholder={"연도"}
                  ref={startYearRef}
                  className={selectClassName}
                  styles={customStyles}
                  myFontSize={"10px"}
                />
              </div>
              <div className={styles.select_box}>
                <Select //
                  options={monthOption}
                  placeholder={"월"}
                  onChange={onStartMonthChange}
                  value={fromMonth}
                  ref={startMonthRef}
                />
              </div>

              <div className={styles.option_name}> ~ </div>
              <div className={styles.select_box}>
                <Select //
                  options={endYearOptions}
                  placeholder={"연도"}
                  isSearchable={true}
                  ref={endYearRef}
                  onChange={onEndYearChange}
                  value={toYear}
                />
              </div>
              <div className={styles.select_box}>
                <Select //
                  options={monthOption}
                  placeholder={"월"}
                  ref={endMonthRef}
                  onChange={onEndMonthChange}
                  value={toMonth}
                />
              </div>
            </div>

            <div className={styles.selection_item_container}>
              <div className={styles.option_name}>*관심상병</div>
              <div className={styles.select_box}>
                <Select //
                  options={diseaseOption}
                  placeholder={"질병 코드"}
                  ref={diseaseRef}
                  onChange={onDiseaseChange}
                  value={disease}
                />
              </div>
            </div>

            <div className={styles.selection_item_container}>
              <div className={styles.option_name}>연령</div>
              <div className={styles.select_box}>
                <Select //
                  options={ageOption}
                  placeholder={"연령"}
                  ref={ageRef}
                  isClearable={true}
                  onChange={onAgeChange}
                  value={age}
                />
              </div>
            </div>
            <div className={styles.selection_item_container}>
              <div className={styles.option_name}>성별</div>
              <div className={styles.select_box}>
                <Select //
                  options={sexOption}
                  placeholder={"성별"}
                  ref={sexRef}
                  isClearable={true}
                  onChange={onSexChange}
                  value={sex}
                />
              </div>
            </div>
            <div className={styles.selection_item_container}>
              <div className={styles.option_name}>관심지역</div>
              <div className={styles.select_box}>
                <Select //
                  options={regionOption}
                  placeholder={"관심지역"}
                  ref={regionRef}
                  isClearable={true}
                  onChange={onRegionChange}
                  value={region}
                  isSearchable={true}
                />
              </div>
            </div>
            {/* <div className={styles.selection_item_container}>
              <div className={styles.option_name}>입원여부</div>
              <div className={styles.select_box}>
                <Select //
                  options={inoutOption}
                  placeholder={"입원여부"}
                  ref={inoutRef}
                  isClearable={true}
                  onChange={onInoutChange}
                  value={inout}
                />
              </div>
            </div> */}
          </div>
        </div>

        <button className={styles.submit_btn} onClick={onSubmit}>
          조회
        </button>
      </form>

      <div className={styles.value_radio}>
        <h5>값선택</h5>
        <PatientsValueRadio updateRadioValue={updateRadioValue} />
      </div>
    </>
  );
};

export default PatientsTrendForm;
