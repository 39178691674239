import * as React from "react";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Link from "@mui/material/Link";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../../context/UserContext";
import logout from "../../apis/logout";

const ModifyPassword = () => {
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { isSubmitting, errors },
    watch,
  } = useForm();

  const [errorMessage, setErrorMessage] = React.useState();
  const [token] = React.useContext(UserContext);

  const onPasswordModifySubmit = async (data) => {
    const response = await fetch(
      "http://222.113.80.171:8000/api/users/passwd_modify",
      {
        method: "POST",
        headers: {
          accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        body: JSON.stringify({
          new_password: data.new_password,
          confirm_password: data.confirm_password,
        }),
      }
    );

    const result = await response.json();

    if (!response.ok) {
      if (typeof result.detail === "object") {
        setErrorMessage(result.detail[0].msg);
      }
    } else {
      window.alert("비밀번호가 변경되었습니다.");
      logout();
    }
  };

  return (
    <Grid container component="main" sx={{ height: "100vh" }}>
      <CssBaseline />
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
      >
        <Box
          sx={{
            my: 8,
            mx: 4,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Typography component="h1" variant="h5" sx={{ mt: 10 }}>
            비밀번호 변경
          </Typography>

          <Box
            component="form"
            noValidate
            onSubmit={handleSubmit(onPasswordModifySubmit)}
            sx={{ mt: 1, width: "30rem" }}
          >
            <TextField
              margin="normal"
              required
              fullWidth
              label="New Password"
              type="password"
              id="new_password"
              autoComplete="new_password"
              {...register("new_password", {
                required: "비밀번호는 필수 입력입니다.",
                minLength: {
                  value: 6,
                  message: "최소 6자리 이상입니다.",
                },
              })}
            />

            {errors.password && (
              <small style={{ color: "red" }}>{errors.password.message}</small>
            )}

            <TextField
              margin="normal"
              required
              fullWidth
              label="Confirm Password"
              type="password"
              id="confirm_password"
              {...register("confirm_password", {
                required: "비밀번호 확인은 필수 입력입니다.",
                validate: (confirm_password) => {
                  if (watch("new_password") !== confirm_password) {
                    return "비밀번호가 일치하지 않습니다.";
                  }
                },
              })}
            />
            {errors.confirm_password && (
              <small style={{ color: "red" }}>
                {errors.confirm_password.message}
              </small>
            )}

            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
              disabled={isSubmitting}
            >
              비밀번호 변경
            </Button>
            {errorMessage && <p style={{ color: "red" }}>{errorMessage}</p>}
            <Grid container>
              <Grid item>
                <Link
                  component="button"
                  variant="body2"
                  onClick={() => navigate("/")}
                >
                  {"홈으로"}
                </Link>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
};

export default ModifyPassword;
