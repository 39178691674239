import React, { useState, useRef } from "react";
import Select from "react-select";
import styles from "./diseaseTreatForm.module.css";
import { diseaseTreatOptions } from "../../../common/variables";

const DiseaseTreatForm = ({ upDateApiParams }) => {
  const [disease, setDisease] = useState("");
  const formRef = useRef();
  const diseaseRef = useRef();
  const onDiseaseChange = (option) => setDisease(option);

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      fontWeight: state.isSelected ? "bold" : "normal",
      color: "black",
      fontSize: state.selectProps.myFontSize,
    }),
    singleValue: (provided, state) => ({
      ...provided,
      fontSize: state.selectProps.myFontSize,
    }),
    control: (css) => ({
      ...css,
      width: "auto",
      opacity: 1,
    }),
  };

  const onSubmit = (event) => {
    event.preventDefault();
    const inputOptions = {
      disease_cd: disease.value || "",
    };
    console.dir(inputOptions);

    upDateApiParams(inputOptions);
  };

  return (
    <>
      <form ref={formRef} className={styles.form}>
        <div className={styles.selectionWrappers}>
          <div className={styles.selectionRow}>
            <div className={styles.selection_item_container}>
              <div className={styles.option_name}>*관심상병</div>
              <div className={styles.select_box}>
                <Select //
                  options={diseaseTreatOptions}
                  placeholder={"질병 코드"}
                  ref={diseaseRef}
                  onChange={onDiseaseChange}
                  value={disease}
                  styles={customStyles}
                />
              </div>
            </div>
          </div>
        </div>

        <button className={styles.submit_btn} onClick={onSubmit}>
          조회
        </button>
      </form>
    </>
  );
};

export default DiseaseTreatForm;
