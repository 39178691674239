// const logout = async () => {
//   const response = await fetch("http://222.113.80.171:8000/api/users/logout", {
//     method: "GET",
//     headers: {
//       "Content-Type": "application/json",
//     },
//   });

//   if (!response.ok) {
//   }

//   window.location.href = "/";
// };

// export default logout;

const logout = () => {
  localStorage.clear();
  window.location.href = "/";
};

export default logout;
