import React, { useContext } from "react";
import { Navigate } from "react-router-dom";
import { UserContext } from "../../context/UserContext";

const LoginRoute = ({ component: Compoent }) => {
  const [token, , staff, passwordCheck] = useContext(UserContext);

  if (Compoent.type.name === "ChangePassword" && !passwordCheck) {
    return <Navigate to="/my_info/check_pw" replace={true} />;
  }
  return token !== "null" &&
    token !== null &&
    token === localStorage.getItem("jwt") ? (
    staff === "true" ? (
      Compoent
    ) : (
      <Navigate to="/" replace={true} {...alert("조회 권한이 없습니다.")} />
    )
  ) : (
    <Navigate
      to="/login"
      replace={true}
      {...alert("로그인 후 이용해주세요.")}
    />
  );
};

export default LoginRoute;
