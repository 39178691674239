import React from "react";
import PatientsTrendForm from "./forms/patientsTrendForm";

const patientsTrendInput = ({ upDateApiParams, updateRadioValue }) => {
  const formName = "trendNormal";

  return (
    <>
      <PatientsTrendForm
        upDateApiParams={upDateApiParams}
        formName={formName}
        updateRadioValue={updateRadioValue}
      />
    </>
  );
};

export default patientsTrendInput;
