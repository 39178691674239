import { ResponsiveBump } from "@nivo/bump";
import React, { createRef, useEffect, useState } from "react";
import { useScreenshot } from "use-react-screenshot";
import FileSaver from "file-saver";
import styles from "./patientsChart.module.css";

const BumpChart = ({ data, updateClickedRankChartInfo }) => {
  const onClick = (event) => {
    updateClickedRankChartInfo(event.id);
  };

  return (
    <ResponsiveBump
      data={data}
      margin={{ top: 40, right: 100, bottom: 40, left: 60 }}
      colors={{ scheme: "spectral" }}
      animate={false}
      lineWidth={3}
      activeLineWidth={6}
      inactiveLineWidth={3}
      inactiveOpacity={0.15}
      pointSize={10}
      activePointSize={16}
      inactivePointSize={0}
      pointColor={{ theme: "background" }}
      pointBorderWidth={3}
      activePointBorderWidth={3}
      pointBorderColor={{ from: "serie.color" }}
      axisTop={{
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        legend: "",
        legendPosition: "middle",
        legendOffset: -36,
      }}
      axisRight={null}
      axisBottom={{
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        legend: "",
        legendPosition: "middle",
        legendOffset: 32,
      }}
      axisLeft={{
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        legend: "ranking",
        legendPosition: "middle",
        legendOffset: -40,
      }}
      onClick={onClick}
    />
  );
};

const PatientsRankChart = ({
  rankPatientsData,
  updateClickedRankChartInfo,
  downloadTF,
}) => {
  const chartRef = createRef();
  const [image, takeScreenShot] = useScreenshot("");
  const [downloadCheck, setDownloadCheck] = useState(false);

  useEffect(() => {
    takeScreenShot(chartRef.current);
  });

  useEffect(() => {
    takeScreenShot(chartRef.current);
    downloadCheck && onChartDownload();
  }, [downloadCheck]);

  if (downloadCheck !== downloadTF) {
    setDownloadCheck(downloadTF);
  }

  const downloadImage = () => {
    image && FileSaver.saveAs(image, "./chart.png");
  };

  const onChartDownload = () => {
    downloadImage();
  };

  return (
    <section>
      <div className={styles.Chart} ref={chartRef}>
        {rankPatientsData && (
          <BumpChart
            data={rankPatientsData}
            updateClickedRankChartInfo={updateClickedRankChartInfo}
          />
        )}
      </div>
    </section>
  );
};

export default PatientsRankChart;
