import React from "react";
import styles from "./header.module.css";
import { useNavigate } from "react-router";
import logo_png from "../../img/logo_sitemap_negative.png";
import { UserContext } from "../../context/UserContext";
import logout from "../../apis/logout";

const Header = () => {
  const navigate = useNavigate();
  const [token, setToken] = React.useContext(UserContext);

  return (
    <nav className={styles.navbar}>
      <div>
        <img
          className={styles.logo}
          src={logo_png}
          alt=""
          onClick={() => navigate("/")}
        />
      </div>

      <ul className={styles.menu}>
        <li className={styles.menu__item} onClick={() => navigate("/home")}>
          HOME
        </li>
        <li className={styles.menu__item} onClick={() => navigate("/patients")}>
          환자표본자료분석
        </li>
        <li
          className={styles.menu__item}
          onClick={() => navigate("/disease-treat")}
        >
          질병-진료처치분석
        </li>
        <li className={styles.menu__item} onClick={() => navigate("/items")}>
          물품구매내역분석
        </li>
        <li className={styles.menu__item} onClick={() => navigate("/support")}>
          기업분석지원
        </li>
        {token !== "null" && token !== null ? (
          <div>
            <li
              className={styles.menu__item}
              onClick={() => navigate("/my_info")}
            >
              내정보
            </li>
            <li
              className={styles.menu__item}
              onClick={() => {
                logout();
              }}
            >
              로그아웃
            </li>
          </div>
        ) : (
          <div>
            <li
              className={styles.menu__item}
              onClick={() => navigate("/register")}
            >
              회원가입
            </li>
            <li
              className={styles.menu__item}
              onClick={() => navigate("/login")}
            >
              로그인
            </li>
          </div>
        )}
      </ul>
    </nav>
  );
};

export default React.memo(Header);
