import React, { createRef, useEffect, useState } from "react";
import { useScreenshot } from "use-react-screenshot";
import FileSaver from "file-saver";
import styles from "./patientsChart.module.css";

import BarChart from "../../nivo/barChart";

const PatientsDetailChart = ({
  trendDetailData,
  selectedRadio,
  downloadTF,
}) => {
  const chartRef = createRef();
  const [image, takeScreenShot] = useScreenshot("");
  const [downloadCheck, setDownloadCheck] = useState(false);

  useEffect(() => {
    takeScreenShot(chartRef.current);
  });

  useEffect(() => {
    takeScreenShot(chartRef.current);
    downloadCheck && onChartDownload();
  }, [downloadCheck]);

  if (downloadCheck !== downloadTF) {
    setDownloadCheck(downloadTF);
  }

  // chart Info
  const keys = [];
  const indexBy = "yyyymm";

  const downloadImage = () => {
    image && FileSaver.saveAs(image, "./chart.png");
  };

  const onChartDownload = () => {
    downloadImage();
  };

  const barChartData = selectedRadio
    ? trendDetailData
        .filter((data) => data.data_id === selectedRadio)
        .map((s) => s.items)
    : trendDetailData
        .filter((data) => data.data_id === "cost_sum")
        .map((s) => s.items);

  const useKeys = {
    region_cd: [
      "서울",
      "부산",
      "인천",
      "대구",
      "광주",
      "대전",
      "울산",
      "경기",
      "강원",
      "충남",
      "전북",
      "경북",
      "경남",
      "제주",
    ],
    recu_cl_cd: [
      "상급종합병원",
      "종합병원",
      "병원",
      "요양병원",
      "정신요양병원",
      "의원",
      "치과병원",
      "치과의원조산원",
      "보건소",
      "보건지소",
      "보건진료소",
      "모자보건센터",
      "보건의료원",
      "약국",
      "한방병원",
      "한의원",
    ],
  };

  return (
    <div className={styles.Chart} ref={chartRef}>
      <BarChart
        data={barChartData}
        onClick={console.log}
        keys={useKeys[trendDetailData[0].target]}
        indexBy={"yyyymm"}
      />
    </div>
  );
};
export default PatientsDetailChart;
