import React, { useState, useRef } from "react";
import Select from "react-select";
import styles from "./patientsForm.module.css";
import {
  defaultDataYearOption,
  monthOption,
  regionOption,
  diseaseOption,
  recuClCodeOption,
  dgsbjtCodeOption,
  shwSbjtCodeOption,
} from "../../../common/variables";

import PatientsValueRadio from "./paritentsValueRadio";
import {
  inputValidation,
  inputPeriodValidation,
} from "../../../service/submit_validation";

const PatientsTrendYkihoForm = ({
  formName,
  upDateApiParams,
  updateRadioValue,
}) => {
  const [endYearOptions, setEndYearOptions] = useState(defaultDataYearOption);
  const [fromYear, setFromYear] = useState("");
  const [fromMonth, setFromMonth] = useState("");
  const [toYear, setToYear] = useState("");
  const [toMonth, setToMonth] = useState("");
  const [region, setRegion] = useState("");
  const [recuClCd, setRecuClCd] = useState("");
  const [disease, setDisease] = useState("");
  const [dgsbjtCode, setDgsbjtCode] = useState("");
  const [shwSbjtCode, setShowSbjtCode] = useState("");

  const startYearRef = useRef();
  const startMonthRef = useRef();
  const endYearRef = useRef();
  const endMonthRef = useRef();
  const regionRef = useRef();
  const recuClCdRef = useRef();
  const diseaseRef = useRef();
  const dgsbjtCodeRef = useRef();
  const shwSbjtCodeRef = useRef();

  const formRef = useRef();

  const onStartYearChange = (option) => {
    setFromYear(option);
    setEndYearOptions(
      defaultDataYearOption.filter(
        (yearOption) => yearOption.value >= option.value
      )
    );
  };

  const onEndYearChange = (option) => setToYear(option);
  const onStartMonthChange = (option) => setFromMonth(option);
  const onEndMonthChange = (option) => setToMonth(option);
  const onRegionChange = (option) => setRegion(option);
  const onDiseaseChange = (option) => setDisease(option);
  const onRecuClCdChange = (option) => setRecuClCd(option);
  const onDgsbjtCodeChage = (option) => setDgsbjtCode(option);
  const onShwSbjtCodeChange = (option) => setShowSbjtCode(option);

  const onSubmit = (event) => {
    event.preventDefault();
    const inputOptions = {
      name: formName,
      fromYear: fromYear.value || "",
      fromMonth: fromMonth.value || "",
      toYear: toYear.value || "",
      toMonth: toMonth.value || "",
      region: region.value || "",
      disease: Boolean(disease) ? disease.value || "" : "",
      recuClCode: Boolean(recuClCd) ? recuClCd.value || "" : "",
      dgsbjtCode: Boolean(dgsbjtCode) ? dgsbjtCode.value || "" : "",
      shwSbjtCode: Boolean(shwSbjtCode) ? shwSbjtCode.value || "" : "",
    };

    if (inputPeriodValidation(inputOptions)) {
      if (inputValidation(inputOptions)) {
        upDateApiParams(inputOptions);
      } else {
        alert("필수항목을 선택해주세요");
      }
    } else {
      alert("기간을 3년내로 설정해주세요");
    }
  };
  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      fontWeight: state.isSelected ? "bold" : "normal",
      color: "black",
      fontSize: state.selectProps.myFontSize,
    }),
    singleValue: (provided, state) => ({
      ...provided,
      fontSize: state.selectProps.myFontSize,
    }),
  };

  const selectClassName = "apsSelectBox";

  return (
    <>
      <form ref={formRef} className={styles.form}>
        <div className={styles.selectionWrappers}>
          <div className={styles.selectionRow}>
            <div className={styles.selection_period_container}>
              <div className={styles.option_name}>*기간 </div>
              <div className={styles.select_box}>
                <Select //
                  value={fromYear}
                  onChange={onStartYearChange}
                  options={defaultDataYearOption}
                  placeholder={"연도"}
                  ref={startYearRef}
                  className={selectClassName}
                  styles={customStyles}
                  myFontSize={"10px"}
                />
              </div>

              <div className={styles.select_box}>
                <Select //
                  options={monthOption}
                  placeholder={"월"}
                  onChange={onStartMonthChange}
                  value={fromMonth}
                  ref={startMonthRef}
                />
              </div>

              <div className={styles.option_name}> ~ </div>
              <div className={styles.select_box}>
                <Select //
                  options={endYearOptions}
                  placeholder={"연도"}
                  isSearchable={true}
                  ref={endYearRef}
                  onChange={onEndYearChange}
                  value={toYear}
                />
              </div>
              <div className={styles.select_box}>
                <Select //
                  options={monthOption}
                  placeholder={"월"}
                  ref={endMonthRef}
                  onChange={onEndMonthChange}
                  value={toMonth}
                />
              </div>
            </div>

            <div className={styles.selection_item_container}>
              <div className={styles.option_name}>*관심지역</div>
              <div className={styles.select_box}>
                <Select //
                  options={regionOption}
                  placeholder={"관심지역"}
                  ref={regionRef}
                  onChange={onRegionChange}
                  value={region}
                  isSearchable={true}
                />
              </div>
            </div>
            <div className={styles.selection_item_container}>
              <div className={styles.option_name}>의료기관종류</div>
              <div className={styles.select_box}>
                <Select //
                  options={recuClCodeOption}
                  placeholder={"의료기관"}
                  ref={recuClCdRef}
                  isClearable={true}
                  onChange={onRecuClCdChange}
                  value={recuClCd}
                  isSearchable={true}
                />
              </div>
            </div>
            <div className={styles.selection_item_container}>
              <div className={styles.option_name}>진료과목</div>
              <div className={styles.select_box}>
                <Select //
                  options={dgsbjtCodeOption}
                  placeholder={"진료과목"}
                  ref={dgsbjtCodeRef}
                  isClearable={true}
                  onChange={onDgsbjtCodeChage}
                  value={dgsbjtCode}
                />
              </div>
            </div>

            <div className={styles.selection_item_container}>
              <div className={styles.option_name}>표시과목</div>
              <div className={styles.select_box}>
                <Select //
                  options={shwSbjtCodeOption}
                  placeholder={"표시과목"}
                  ref={shwSbjtCodeRef}
                  isClearable={true}
                  onChange={onShwSbjtCodeChange}
                  value={shwSbjtCode}
                />
              </div>
            </div>

            <div className={styles.selection_item_container}>
              <div className={styles.option_name}>질병코드</div>

              <div className={styles.select_box}>
                <Select //
                  options={diseaseOption}
                  placeholder={"질병코드"}
                  ref={diseaseRef}
                  isClearable={true}
                  onChange={onDiseaseChange}
                  value={disease}
                />
              </div>
            </div>
          </div>
        </div>

        <button className={styles.submit_btn} onClick={onSubmit}>
          조회
        </button>
      </form>
      <div className={styles.value_radio}>
        <h5>값선택</h5>
        <PatientsValueRadio updateRadioValue={updateRadioValue} />
      </div>
    </>
  );
};

export default PatientsTrendYkihoForm;
