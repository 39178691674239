import React, { useState } from "react";
import styles from "./itemValueRadio.module.css";

const ItemValueRadio = ({ setSelectedRadio }) => {
  const [dataValue, setDataValue] = useState("계약금액");
  const chartValueRadio = (event) => {
    const targetName = event.target.value;
    setDataValue(targetName);
    setSelectedRadio(targetName);
  };

  return (
    <>
      <ul>
        <li>
          <label>
            <input
              type="radio"
              value="계약금액"
              name="yvalue"
              onChange={chartValueRadio}
              checked={dataValue === "계약금액"}
            />
            <span>계약금액</span>
          </label>
        </li>
        <li>
          <label>
            <input
              type="radio"
              value="수량"
              name="yvalue"
              onChange={chartValueRadio}
              checked={dataValue === "수량"}
            />
            <span>수량</span>
          </label>
        </li>
        <li>
          <label>
            <input
              type="radio"
              value="계약건수"
              name="yvalue"
              onChange={chartValueRadio}
              checked={dataValue === "계약건수"}
            />
            <span>계약건수</span>
          </label>
        </li>
      </ul>
    </>
  );
};

export default ItemValueRadio;
