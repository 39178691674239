import "./App.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "./components/home/home";
import BizSup from "./components/bizSup/bizSup";
import ItemStat from "./components/itemStat/itemStat";
import NewPatientsState from "./components/NewPatientsStat/patientsStat";
import "./components/plugins/font-awesome";
import TrendHelpPopup from "./components/helpPopup/trendHelpPopup";
import RankHelpPopup from "./components/helpPopup/rankHelpPopup";
import ItemHelpPopup from "./components/helpPopup/itemHelpPopup";
import Register from "./components/register/Register";
import Login from "./components/login/Login";
import LoginRoute from "./components/authRoutes/LoginRoute";
import DiseaseTreat from "./components/diseaseTreatRelation/diseaseTreat";
import MyInfo from "./components/MyInfo/MyInfo";
import PasswordCheck from "./components/ChangePassword/PasswordCheck";
import ModifyPassword from "./components/ChangePassword/ModifyPassword";
import EmailCheck from "./components/ChangePassword/EmailCheck";

function App({ openlabApi, dataTrsf, tableInfo }) {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/home" element={<Home />} />

        <Route
          path="/support"
          element={<LoginRoute component={<BizSup />} />}
        />

        <Route path="/my_info">
          <Route path="" element={<LoginRoute component={<MyInfo />} />} />
          <Route path="chceck_email" element={<EmailCheck />} />
          <Route path="check_pw" element={<PasswordCheck />} />
          <Route
            path="modify_pw"
            element={<LoginRoute component={<ModifyPassword />} />}
          />
        </Route>

        <Route
          path="/items"
          element={
            <LoginRoute
              component={
                <ItemStat
                  openlabApi={openlabApi}
                  dataTrsf={dataTrsf}
                  tableInfo={tableInfo}
                />
              }
            />
          }
        />

        <Route
          path="/patients"
          element={
            <LoginRoute
              component={
                <NewPatientsState
                  openlabApi={openlabApi}
                  dataTrsf={dataTrsf}
                  tableInfo={tableInfo}
                />
              }
            />
          }
        />

        <Route
          path="/disease-treat"
          element={
            <LoginRoute
              component={
                <DiseaseTreat
                  openlabApi={openlabApi}
                  dataTrsf={dataTrsf}
                  tableInfo={tableInfo}
                />
              }
            />
          }
        />

        <Route path="/help_trend" element={<TrendHelpPopup />} />
        <Route path="/help_rank" element={<RankHelpPopup />} />
        <Route path="/help_item" element={<ItemHelpPopup />} />
        <Route path="/register" element={<Register />} />
        <Route path="/login" element={<Login />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
