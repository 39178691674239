import React, { useState } from "react";
import styles from "./patientsValueRadio.module.css";
const PatientsValueRadio = ({ updateRadioValue }) => {
  const [dataValue, setDataValue] = useState("cost_sum");
  const chartValueRadio = (event) => {
    const targetName = event.target.value;
    setDataValue(targetName);
    updateRadioValue(targetName);
  };
  const radioStyles = {
    pdding: 0,
    margin: 0,
  };

  return (
    <>
      <ul style={{ margin: "0px", padding: "7px" }}>
        <li>
          <label>
            <input
              type="radio"
              value="cost_sum"
              name="yvalue"
              onChange={chartValueRadio}
              checked={dataValue === "cost_sum"}
            />
            <span>진료비</span>
          </label>
        </li>
        <li>
          <label>
            <input
              type="radio"
              value="case_count"
              name="yvalue"
              onChange={chartValueRadio}
              checked={dataValue === "case_count"}
            />
            <span>진료건수</span>
          </label>
        </li>
        <li>
          <label>
            <input
              type="radio"
              value="patients_number"
              name="yvalue"
              onChange={chartValueRadio}
              checked={dataValue === "patients_number"}
            />
            <span>환자수</span>
          </label>
        </li>
      </ul>
    </>
  );
};

export default PatientsValueRadio;
