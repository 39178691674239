import React, { useRef, useState, useEffect } from "react";
import { AgGridColumn, AgGridReact } from "ag-grid-react";
import styles from "./itemStat.module.css";

import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";

const ClsfcDetailTable = ({ rowData, getDetailChartTable, downloadTF }) => {
  const gridRef = useRef();
  const [gridApi, setGridApi] = useState(false);
  const [downloadCheck, setDownloadCheck] = useState(false);

  useEffect(() => {
    downloadCheck && gridApi.exportDataAsCsv();
  }, [downloadCheck]);

  if (downloadCheck !== downloadTF) {
    setDownloadCheck(downloadTF);
  }

  const onGridReady = (params) => {
    setGridApi(params.api);
    params.api.sizeColumnsToFit();
  };

  const onSelectionChanged = async (event) => {
    const selectedDataNode = event.api.getSelectedNodes();
    const rowData = selectedDataNode[0].data;
    await getDetailChartTable(rowData);
  };

  return (
    <>
      <div
        className="ag-theme-alpine"
        style={{ height: "100%", width: "100%" }}
      >
        <AgGridReact
          rowData={rowData}
          ref={gridRef}
          rowSelection={"single"}
          onGridReady={onGridReady}
          onSelectionChanged={onSelectionChanged}
        >
          <AgGridColumn
            field="물품식별명"
            sortable={true}
            filter={true}
            minWidth={350}
            maxWidth={550}
          ></AgGridColumn>
          <AgGridColumn
            field="계약금액"
            sortable={true}
            suppressSizeToFit={true}
            minWidth={150}
            maxWidth={200}
          ></AgGridColumn>
          <AgGridColumn
            field="수량"
            sortable={true}
            suppressSizeToFit={true}
            minWidth={80}
            maxWidth={100}
          ></AgGridColumn>
        </AgGridReact>
      </div>
    </>
  );
};

export default React.memo(ClsfcDetailTable);
