import React from "react";
import banner from "../../img/home-banner2.jpeg";
import intro_img from "../../img/home_intro.jpeg";
import styles from "./home.module.css";
import Data_info from "./data_info";
import BaseLayout from "../layout/BaseLayout";

const Home = (props) => {
  return (
    <BaseLayout>
      <div
        className={styles.banner}
        style={{ backgroundImage: "url(" + banner + ")" }}
      >
        <h1 className={styles.h1}> 데이터 분석존 </h1>
      </div>

      <section className={styles.section}>
        <div className={styles.container}>
          <div clasName={styles.content}>
            <div className={styles.content_title}>
              <h2>데이터 분석존 소개</h2>
            </div>

            <div className={styles.content_box}>
              <div className={styles.content_img_box}>
                <img className={styles.content_img} src={intro_img} alt="" />
              </div>

              <div className={styles.content_elements}>
                <div className={styles.content_element}>
                  <div className={styles.content_number}>01</div>
                  <div className={styles.content_sep}></div>
                  <div className={styles.content_text}>
                    <p>
                      혁신도시 이전공공기관 연계 사업으로 오픈랩에서 보유하고
                      있는 데이터를 기업들이 활용할 수 있도록 돕고 있습니다.
                    </p>
                  </div>
                </div>

                <div className={styles.content_element}>
                  <div className={styles.content_number}>02</div>
                  <div className={styles.content_sep}></div>
                  <div className={styles.content_text}>
                    <p>
                      심사평가원 의료빅데이터 센터의 의료자원 빅데이터에 대한
                      정보를 알아보고 활용에 도움을 받아보세요.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Data_info />
        </div>
      </section>
    </BaseLayout>
  );
};

export default Home;
