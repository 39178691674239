import * as React from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Link from "@mui/material/Link";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../../context/UserContext";

function Copyright(props) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {"Copyright © "}
      <Link color="inherit" href="https://mui.com/"></Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const Login = () => {
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { isSubmitting, errors },
    watch,
  } = useForm();
  const [errorMessage, setErrorMessage] = React.useState();
  const [, setToken] = React.useContext(UserContext);

  const onSignupSubmit = async (data) => {
    const response = await fetch("http://222.113.80.171:8000/api/users/login", {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      body: JSON.stringify(
        `grant_type=&username=${data.email}&password=${data.password}&scope=&client_id=&client_secret=`
      ),
    });

    const result = await response.json();

    if (!response.ok) {
      setErrorMessage(result.detail);
    } else {
      localStorage.setItem("jwt", result.access_token);
      setToken(result.access_token);

      window.location.href = "/";
    }
  };

  return (
    <Grid container component="main" sx={{ height: "100vh" }}>
      <CssBaseline />
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
      >
        <Box
          sx={{
            my: 8,
            mx: 4,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            로그인
          </Typography>
          <Box
            component="form"
            noValidate
            onSubmit={handleSubmit(onSignupSubmit)}
            sx={{ mt: 1, width: "30rem" }}
          >
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              autoComplete="email"
              autoFocus
              {...register("email", {
                required: "이메일은 필수 입력입니다.",
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                  message: "이메일 형식에 맞지 않습니다.",
                },
                validate: (val) => {
                  const frontAlias = val.split("@")[0];
                  if (frontAlias.length < 6) {
                    return "이메일이 너무 짧습니다.(최소 6자리 이상)";
                  }
                },
              })}
            />
            {errors.email && (
              <small style={{ color: "red" }}>{errors.email.message}</small>
            )}

            <TextField
              margin="normal"
              required
              fullWidth
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
              {...register("password", {
                required: "비밀번호는 필수 입력입니다.",
                minLength: {
                  value: 6,
                  message: "최소 6자리 이상입니다.",
                },
              })}
            />

            {errors.password && (
              <small style={{ color: "red" }}>{errors.password.message}</small>
            )}

            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
              disabled={isSubmitting}
            >
              로그인
            </Button>
            {errorMessage && <p style={{ color: "red" }}>{errorMessage}</p>}
            <Grid container>
              <Grid item xs>
                <Link
                  component="button"
                  variant="body2"
                  onClick={() => navigate("/register")}
                >
                  {"아직 계정이 없으신가요?"}
                </Link>
              </Grid>
              <Grid item>
                <Link
                  component="button"
                  variant="body2"
                  onClick={() => navigate("/my_info/chceck_email")}
                >
                  {"비밀번호를 잊어버리셨나요?"}
                </Link>
              </Grid>
            </Grid>
            {/* <Copyright sx={{ mt: 5 }} /> */}
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
};

export default Login;
