import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import OpenlabApi from "./service/api_service";
import { DataTransform } from "./service/transform_chartData";
import TableInfo from "./common/tableVariables";
import { UserProvider } from "./context/UserContext";

//const openlabApi = new OpenlabApi(process.env.OPENLAB_API_HOST);
const openlabApi = new OpenlabApi("http://222.113.80.171:8000/");
const dataTrsf = new DataTransform();
const tableInfo = new TableInfo();

ReactDOM.render(
  <>
    <UserProvider>
      <App openlabApi={openlabApi} dataTrsf={dataTrsf} tableInfo={tableInfo} />
    </UserProvider>
  </>,
  document.getElementById("root")
);
