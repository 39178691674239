class TableInfo {
  constructor() {
    this.defaultColDef = {
      editable: false,
      sortable: true,
      resizable: true,
      pagination: true,
      filter: true,
    };
    this.patientsTrendColumnDefs = [
      { field: "년월" },
      { field: "급여금액" },
      { field: "진료건수" },
      { field: "환자수" },
    ];
    this.patientsDataColumnDefs = [
      { field: "서식코드" },
      { field: "연령대" },
      { field: "성별" },
      { field: "입원여부" },
      { field: "지역" },
      { field: "표시과목코드" },
      { field: "진료과목코드" },
      { field: "요양기관종류" },
      { field: "주상병" },
      { field: "부상병" },
      { field: "급여금액총액" },
    ];
  }
}

export default TableInfo;
