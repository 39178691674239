import React, { useRef, useState, useEffect } from "react";
import { AgGridReact } from "ag-grid-react";

import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";

const TrendTable = ({ rowData, onSelectRow, tableInfo, downloadTF }) => {
  const gridRef = useRef();
  const [gridApi, setGridApi] = useState(null);
  const [downloadCheck, setDownloadCheck] = useState(false);

  useEffect(() => {
    downloadCheck && gridApi.exportDataAsCsv();
  }, [downloadCheck]);

  if (downloadCheck !== downloadTF) {
    setDownloadCheck(downloadTF);
  }

  const onGridReady = (params) => {
    setGridApi(params.api);
    params.api.sizeColumnsToFit();
  };

  const onSelectionChanged = (event) => {
    const selectedDataNode = event.api.getSelectedNodes();
    const rowData = selectedDataNode[0].data;
    onSelectRow({ yyyymm: rowData.yyyymm });
  };

  return (
    <>
      <div
        className="ag-theme-alpine"
        style={{ height: "100%", width: "100%" }}
      >
        <AgGridReact
          defaultColDef={tableInfo.defaultColDef}
          rowData={rowData}
          columnDefs={tableInfo.patientsTrendColumnDefs}
          ref={gridRef}
          onGridReady={onGridReady}
          rowSelection={"single"}
          onSelectionChanged={onSelectionChanged}
          pagenation={true}
        ></AgGridReact>
      </div>
    </>
  );
};

export default TrendTable;
