const genItemYearOption = () => {
  const now = new Date();
  const thisYear = now.getFullYear();
  const startYear = "2017";
  const years = [];
  for (let year = parseInt(startYear); year <= parseInt(thisYear); year++) {
    years.push(`${year}`);
  }

  return years.map((year) => {
    return { value: year, label: year };
  });
};

const itemYearOption = genItemYearOption();
const itemClsfcNo = [
  {
    "value" : "42141802",
    "label" : "헤어밴드형 헬스케어 기기"
  },
  {
    "value" : "42141804",
    "label" : "의료용전기자극기"
  },
  {
    "value" : "42141805",
    "label" : "저주파자극기"
  },
  {
    "value" : "42141806",
    "label" : "극초단파자극기,단파투열요법장치"
  },
  {
    "value" : "42141807",
    "label" : "경피용전기신경자극기"
  },
  {
    "value" : "42141809",
    "label" : "근자극용도선세트"
  },
  {
    "value" : "42141810",
    "label" : "이온도입기전극"
  },
  {
    "value" : "42141811",
    "label" : "체외충격파치료기"
  },
  {
    "value" : "42141816",
    "label" : "의료용전자기발생기"
  },
  {
    "value" : "42141817",
    "label" : "네오디뮴 자석"
  },
  {
    "value" : "42141903",
    "label" : "관장세트또는액세서리"
  },
  {
    "value" : "42142001",
    "label" : "병동용포셉또는지혈기"
  },
  {
    "value" : "42142102",
    "label" : "의료용냉장장치또는액세서리"
  },
  {
    "value" : "42142104",
    "label" : "의료용열치료기또는액세서리"
  },
  {
    "value" : "42142105",
    "label" : "의료용온열기,온냉치료용기기또는장치"
  },
  {
    "value" : "42142108",
    "label" : "온냉치료용찜질패드또는팩"
  },
  {
    "value" : "42142112",
    "label" : "치료용파라핀욕조또는액세서리"
  },
  {
    "value" : "42142201",
    "label" : "사지용수치료욕조또는탱크"
  },
  {
    "value" : "42131599",
    "label" : "정형외과용환자복"
  },
  {
    "value" : "42131601",
    "label" : "의료인용앞치마또는가슴받이"
  },
  {
    "value" : "42131604",
    "label" : "의료인용수술모자"
  },
  {
    "value" : "42131606",
    "label" : "의료인용격리·수술덴탈마스크,덴탈마스크"
  },
  {
    "value" : "42131607",
    "label" : "의료인용가운"
  },
  {
    "value" : "42131612",
    "label" : "의료인용격리복또는덧가운"
  },
  {
    "value" : "42131701",
    "label" : "일회용외과용드레이프"
  },
  {
    "value" : "42131702",
    "label" : "수술가운, 수술복"
  },
  {
    "value" : "421322",
    "label" : "의료용장갑및액세서리"
  },
  {
    "value" : "42132202",
    "label" : "외과용골무"
  },
  {
    "value" : "42132203",
    "label" : "진료용장갑"
  },
  {
    "value" : "42132205",
    "label" : "수술용장갑"
  },
  {
    "value" : "421415",
    "label" : "Huby Swab"
  },
  {
    "value" : "42141501",
    "label" : "탈지면또는솜"
  },
  {
    "value" : "42141502",
    "label" : "외과용면봉"
  },
  {
    "value" : "42141602",
    "label" : "범용변기"
  },
  {
    "value" : "42141603",
    "label" : "조립식물받이"
  },
  {
    "value" : "42141607",
    "label" : "범용환자소변기"
  },
  {
    "value" : "42141699",
    "label" : "변기및요기보관대"
  },
  {
    "value" : "42141701",
    "label" : "교대압력장치,낙상방지감지기"
  },
  {
    "value" : "42141702",
    "label" : "블랭킷프레임또는블랭킷리프터"
  },
  {
    "value" : "42141705",
    "label" : "환자체위변경용쿠션,패드또는베개"
  },
  {
    "value" : "42141801",
    "label" : "복합전기치료유닛"
  },

  {
    "value" : "42142302",
    "label" : "의학차트시스템부품또는액세서리"
  },
  {
    "value" : "42142303",
    "label" : "환자신원확인제품"
  },
  {
    "value" : "42142402",
    "label" : "의료용흡인캐뉼라,튜브또는액세서리"
  },
  {
    "value" : "42142404",
    "label" : "의료용흡인기,의료용석션또는진공장치"
  },
  {
    "value" : "42142406",
    "label" : "의료용저압지속흡인기"
  },
  {
    "value" : "42142502",
    "label" : "마취바늘"
  },
  {
    "value" : "42142504",
    "label" : "생검용바늘"
  },
  {
    "value" : "42142515",
    "label" : "진공관바늘"
  },
  {
    "value" : "42142521",
    "label" : "채혈용바늘"
  },
  {
    "value" : "42142523",
    "label" : "피하주사용바늘"
  },
  {
    "value" : "42142529",
    "label" : "바늘트레이또는홀더"
  },
  {
    "value" : "42142598",
    "label" : "의료용손상성폐기물처리기"
  },
  {
    "value" : "421426",
    "label" : "주사기및액세서리"
  },
  {
    "value" : "42142603",
    "label" : "의료용카트리지주사기"
  },
  {
    "value" : "42142606",
    "label" : "의료용계량투약주사기"
  },
  {
    "value" : "42142608",
    "label" : "의료용바늘없는주사기"
  },
  {
    "value" : "42142609",
    "label" : "의료용바늘있는주사기"
  },
  {
    "value" : "42142610",
    "label" : "구강유액치료주사기"
  },
  {
    "value" : "42142612",
    "label" : "세척주사기세트"
  },
  {
    "value" : "42142613",
    "label" : "자동주사기"
  },
  {
    "value" : "42142616",
    "label" : "채혈기,채혈용주사기"
  },
  {
    "value" : "42142699",
    "label" : "채혈세트"
  },
  {
    "value" : "42142701",
    "label" : "치골상부카테터"
  },
  {
    "value" : "42142702",
    "label" : "요도카테터"
  },
  {
    "value" : "42142801",
    "label" : "혈관순차압박장치또는배관"
  },
  {
    "value" : "42142802",
    "label" : "혈관압박의복또는지지물"
  },
  {
    "value" : "42142901",
    "label" : "안경"
  },
  {
    "value" : "42142902",
    "label" : "안경렌즈"
  },
  {
    "value" : "42142903",
    "label" : "안경테,운동전용안경테"
  },
  {
    "value" : "42142905",
    "label" : "선글라스"
  },
  {
    "value" : "42142913",
    "label" : "콘택트렌즈"
  },
  {
    "value" : "42143108",
    "label" : "복부감압챔버"
  },
  {
    "value" : "42143299",
    "label" : "정자·정액분석장치"
  },
  {
    "value" : "42143399",
    "label" : "알칼리이온수생성기"
  },
  {
    "value" : "42143502",
    "label" : "이비인후과용진료장치,비세척장치또는액세서리"
  },
  {
    "value" : "42143503",
    "label" : "비출혈조절장치또는벌룬"
  },
  {
    "value" : "42143709",
    "label" : "광선조사기"
  },
  {
    "value" : "42143797",
    "label" : "신생아황달치료용광선조사기"
  },
  {
    "value" : "42143798",
    "label" : "자외선조사기"
  },
  {
    "value" : "42143799",
    "label" : "적외선조사기"
  },
  {
    "value" : "42143801",
    "label" : "방광내압측정시스템또는액세서리,방광내압계"
  },
  {
    "value" : "42143899",
    "label" : "방광잔뇨량측정기,소변유량·용적측정장치"
  },
  {
    "value" : "42143902",
    "label" : "변실금관리시스템또는키트"
  },
  {
    "value" : "42144201",
    "label" : "비멸균침"
  },
  {
    "value" : "42151501",
    "label" : "심미치과용광중합기또는액세서리"
  },
  {
    "value" : "42151503",
    "label" : "크라운또는크라운폼"
  },
  {
    "value" : "421516",
    "label" : "치과,관련분야기구및장치"
  },
  {
    "value" : "42151606",
    "label" : "치과용아말감성형기"
  },
  {
    "value" : "42151614",
    "label" : "치과용버"
  },
  {
    "value" : "42151617",
    "label" : "치과용드릴또는드릴비트"
  },
  {
    "value" : "42151618",
    "label" : "치과용엘리베이터"
  },
  {
    "value" : "42151619",
    "label" : "치과용천공기"
  },
  {
    "value" : "42151621",
    "label" : "치과용충전재수복기기"
  },
  {
    "value" : "42151623",
    "label" : "치과용포셉"
  },
  {
    "value" : "42151624",
    "label" : "치과용핸드피스또는액세서리"
  },
  {
    "value" : "42151627",
    "label" : "치과용거울또는미러핸들"
  },
  {
    "value" : "42151632",
    "label" : "치과용리머"
  },
  {
    "value" : "42151635",
    "label" : "치과용배타기,구강석션장치또는용품"
  },
  {
    "value" : "42151636",
    "label" : "치과용치석제거기또는액세서리,고주파 건식 스케일러(P5-Newtron),고주파 습식 스케일러(P5-Newtron Xs)"
  },
  {
    "value" : "42151638",
    "label" : "치과용가위"
  },
  {
    "value" : "42151639",
    "label" : "치과용설압자"
  },
  {
    "value" : "42151644",
    "label" : "치과용마우스프롭"
  },
  {
    "value" : "42151655",
    "label" : "치수또는활력도테스터"
  },
  {
    "value" : "42151660",
    "label" : "치과용주걱또는탈지면"
  },
  {
    "value" : "42151664",
    "label" : "치과용커팅또는분리용디스크"
  },
  {
    "value" : "42151665",
    "label" : "치과용측정기또는액세서리"
  },
  {
    "value" : "42151678",
    "label" : "안테나 고정 Plate 제작,믹싱패드또는판대기용고정대"
  },
  {
    "value" : "42151679",
    "label" : "치과용믹싱백"
  },
  {
    "value" : "42151696",
    "label" : "치과임플란트동요도측정장치"
  },
  {
    "value" : "42151699",
    "label" : "전류식치아우식증탐지기"
  },
  {
    "value" : "42151701",
    "label" : "치과진찰용의자,관련부품또는액세서리"
  },
  {
    "value" : "42151704",
    "label" : "치과용테이블또는액세서리,치과용진료장치및의자"
  },
  {
    "value" : "42151801",
    "label" : "치과용아말감수송기"
  },
  {
    "value" : "42151802",
    "label" : "치과용플러거,팁또는액세서리"
  },
  {
    "value" : "42151803",
    "label" : "치과용아말감캡슐"
  },
  {
    "value" : "42151810",
    "label" : "치과마감제또는광택용키트"
  },
  {
    "value" : "42151901",
    "label" : "치과용판또는의치솔"
  },
  {
    "value" : "42152002",
    "label" : "교합저지기,교익또는바이트탭"
  },
  {
    "value" : "42152003",
    "label" : "치과용현상기"
  },
  {
    "value" : "42152006",
    "label" : "치과용방사선사진받침대"
  },
  {
    "value" : "42152008",
    "label" : "치과용방사선기기"
  },
  {
    "value" : "42152009",
    "label" : "치과용방사선사진판독기또는액세서리"
  },
  {
    "value" : "42152103",
    "label" : "치과인상용트레이접착제"
  },
  {
    "value" : "42152105",
    "label" : "치과용인상재트레이"
  },
  {
    "value" : "42152111",
    "label" : "치과용인상재경화기"
  },
  {
    "value" : "42152112",
    "label" : "치과용건조기구또는액세서리"
  },
  {
    "value" : "42152198",
    "label" : "교합인기용재료"
  },
  {
    "value" : "42152199",
    "label" : "트레이용레진"
  },
  {
    "value" : "42152204",
    "label" : "치과기공용주조기계,그부품또는액세서리"
  },
  {
    "value" : "42152205",
    "label" : "치과기공용경화장치"
  },
  {
    "value" : "42152208",
    "label" : "치과기공용동력기기또는액세서리"
  },
  {
    "value" : "42152209",
    "label" : "치과기공용가열로"
  },
  {
    "value" : "42152212",
    "label" : "치과기공용모델트리머또는액세서리"
  },
  {
    "value" : "42152215",
    "label" : "치과기공용샌딩기또는용품"
  },
  {
    "value" : "42152216",
    "label" : "치과기공용납땜기또는용품"
  },
  {
    "value" : "42152218",
    "label" : "치과기공용진동기"
  },
  {
    "value" : "42152220",
    "label" : "치과용석고"
  },
  {
    "value" : "42152401",
    "label" : "치과아말감용합금"
  },
  {
    "value" : "42152410",
    "label" : "치과용알긴산인상재"
  },
  {
    "value" : "42152414",
    "label" : "치과주조용합금"
  },
  {
    "value" : "42152415",
    "label" : "치과용도재"
  },
  {
    "value" : "42152416",
    "label" : "치과용복사재"
  },
  {
    "value" : "42152417",
    "label" : "치과용에틸실리케이트내화재"
  },
  {
    "value" : "42152423",
    "label" : "치면열구전색제"
  },
  {
    "value" : "42152424",
    "label" : "치과접착용시멘트"
  },
  {
    "value" : "42152425",
    "label" : "의치상용수지"
  },
  {
    "value" : "42152430",
    "label" : "근관충전재료"
  },
  {
    "value" : "42152434",
    "label" : "치과아말감혼합기"
  },
  {
    "value" : "42152437",
    "label" : "도제치아"
  },
  {
    "value" : "42152446",
    "label" : "치과용충치라이닝또는희석용재료또는세트"
  },
  {
    "value" : "42152450",
    "label" : "치과기구용세척화합물"
  },
  {
    "value" : "42152457",
    "label" : "치과용접착제키트"
  },
  {
    "value" : "42152464",
    "label" : "치아임플란트용키트"
  },
  {
    "value" : "42152470",
    "label" : "치과교정장치용레진"
  },
  {
    "value" : "42152494",
    "label" : "금속계인공치아"
  },
  {
    "value" : "42152496",
    "label" : "왁스계의치받침"
  },
  {
    "value" : "42152497",
    "label" : "치관용레진"
  },
  {
    "value" : "42152507",
    "label" : "치과용코튼롤"
  },
  {
    "value" : "42152518",
    "label" : "치과용재료분배기"
  },
  {
    "value" : "42152601",
    "label" : "치과수술용교합지또는관련용품"
  },
  {
    "value" : "42152701",
    "label" : "치과용교합기또는액세서리"
  },
  {
    "value" : "42152703",
    "label" : "치과용핀포스트또는관련용품"
  },
  {
    "value" : "42152704",
    "label" : "치과용고정장치"
  },
  {
    "value" : "42152709",
    "label" : "치아교정용고무줄"
  },
  {
    "value" : "42152801",
    "label" : "치주용끌"
  },
  {
    "value" : "42152808",
    "label" : "치주용큐렛"
  },
  {
    "value" : "4216",
    "label" : "혈액투석기기및용품"
  },
  {
    "value" : "42161506",
    "label" : "복막투석용분로카테터또는접근장치"
  },
  {
    "value" : "42161601",
    "label" : "혈액투석용투여키트또는세트"
  },
  {
    "value" : "42161610",
    "label" : "혈액투석용배관"
  },
  {
    "value" : "42161614",
    "label" : "혈액투석기용중공필라멘트필터"
  },
  {
    "value" : "42161631",
    "label" : "혈액투석장치용정수장치,인공신장실용 정수장치"
  },
  {
    "value" : "42161632",
    "label" : "혈액투석장치,인공신장기 및 전산화장비"
  },
  {
    "value" : "4217",
    "label" : "응급및병동의료서비스제품"
  },
  {
    "value" : "421715",
    "label" : "응급의료용재난구호제품"
  },
  {
    "value" : "42171601",
    "label" : "항공후송용들것, 수난구조용들것"
  },
  {
    "value" : "42171603",
    "label" : "쇼크방지복"
  },
  {
    "value" : "42171604",
    "label" : "바스켓들것(일체형)"
  },
  {
    "value" : "42171606",
    "label" : "응급의료용공기부목"
  },
  {
    "value" : "42171608",
    "label" : "응급의료용머리고정대. 경추보호대또는구출고정대"
  },
  {
    "value" : "42171609",
    "label" : "응급의료용억제대또는척추고정판스트랩"
  },
  {
    "value" : "42171611",
    "label" : "수상구조용,응급구조용들것"
  },
  {
    "value" : "42171612",
    "label" : "분리형들것"
  },
  {
    "value" : "42171613",
    "label" : "척추고정판"
  },
  {
    "value" : "42171802",
    "label" : "특수기도 관리장비,경성후두경"
  },
  {
    "value" : "421719",
    "label" : "응급의료용보관케이스및가방"
  },
  {
    "value" : "42171913",
    "label" : "응급의료용구급케이스또는가방"
  },
  {
    "value" : "42172001",
    "label" : "응급처치용유닛,휴대용구급함세트"
  },
  {
    "value" : "42172004",
    "label" : "응급의료용구명키트"
  },
  {
    "value" : "42172013",
    "label" : "응급의료용후송키트"
  },
  {
    "value" : "42172018",
    "label" : "위세척키트"
  },
  {
    "value" : "421721",
    "label" : "응급의료용심폐소생제품"
  },
  {
    "value" : "42172101",
    "label" : "심실제세동기,스탠드형보관함"
  },
  {
    "value" : "42172103",
    "label" : "심폐인공소생기, 응급심폐소생기또는흡입기키트"
  },
  {
    "value" : "421722",
    "label" : "응급의료용품"
  },
  {
    "value" : "42172201",
    "label" : "응급의료용지혈대또는클램프"
  },
  {
    "value" : "421815",
    "label" : "범용진단및검진제품"
  },
  {
    "value" : "42181501",
    "label" : "설압자"
  },
  {
    "value" : "42181502",
    "label" : "검진용투조기"
  },
  {
    "value" : "42181504",
    "label" : "혈구계수기세트,혈구계산기및자동분석시약류"
  },
  {
    "value" : "42181507",
    "label" : "의료검사용머리반사경또는액세서리"
  },
  {
    "value" : "42181510",
    "label" : "피부용황달측정기"
  },
  {
    "value" : "42181514",
    "label" : "HbA1c측정기,혈색소측정기"
  },
  {
    "value" : "42181519",
    "label" : "의료용드롭수송기"
  },
  {
    "value" : "42181522",
    "label" : "보행분석계"
  },
  {
    "value" : "42181523",
    "label" : "운동성시험평가장치"
  },
  {
    "value" : "42181524",
    "label" : "경직성분석기"
  },
  {
    "value" : "42181525",
    "label" : "운동실조묘화기"
  },
  {
    "value" : "42181526",
    "label" : "의료용압통계"
  },
  {
    "value" : "42181528",
    "label" : "피부저항측정기"
  },
  {
    "value" : "42181531",
    "label" : "피부전위측정기"
  },
  {
    "value" : "42181532",
    "label" : "갈바닉피부응답측정기"
  },
  {
    "value" : "42181533",
    "label" : "신경전도속도측정기"
  },
  {
    "value" : "42181535",
    "label" : "생체신호처리장치"
  },
  {
    "value" : "42181591",
    "label" : "실시간유전자증폭장치"
  },
  {
    "value" : "42181592",
    "label" : "기타검사용시약"
  },
  {
    "value" : "42181593",
    "label" : "검체수송배지"
  },
  {
    "value" : "42181594",
    "label" : "워크스루진단부스,진단용격리부스,이동식음압기"
  },
  {
    "value" : "42181599",
    "label" : "핵산증폭키트,유전자증폭장치및자동분석시약류"
  },
  {
    "value" : "421816",
    "label" : "혈압기기및관련제품"
  },
  {
    "value" : "42181601",
    "label" : "아네로이드혈압계"
  },
  {
    "value" : "42181602",
    "label" : "자동전자혈압계"
  },
  {
    "value" : "42181603",
    "label" : "수은주식혈압계"
  },
  {
    "value" : "42181605",
    "label" : "혈압계용커프또는공기주머니"
  },
  {
    "value" : "42181608",
    "label" : "혈압계용액세서리"
  },
  {
    "value" : "42181609",
    "label" : "혈압감시기돔키트"
  },
  {
    "value" : "42181611",
    "label" : "중심정맥(CVP)혈압계"
  },
  {
    "value" : "42181701",
    "label" : "심전계"
  },
  {
    "value" : "42181712",
    "label" : "심전도장치분석기,심전도감시기"
  },
  {
    "value" : "42181713",
    "label" : "장시간지속심전도기또는홀터모니터링장치"
  },
  {
    "value" : "42181715",
    "label" : "전극용용액또는크림"
  },
  {
    "value" : "42181720",
    "label" : "부하심전도장치"
  },
  {
    "value" : "42181721",
    "label" : "맥파계"
  },
  {
    "value" : "42181722",
    "label" : "맥파분석기"
  },
  {
    "value" : "42181794",
    "label" : "심자계"
  },
  {
    "value" : "42181795",
    "label" : "심박수계또는맥박수계"
  },
  {
    "value" : "421818",
    "label" : "산소포화도측정기"
  },
  {
    "value" : "42181801",
    "label" : "펄스옥시미터,의료용산소포화도측정기"
  },
  {
    "value" : "42181803",
    "label" : "산소포화도측정기탐침또는센서"
  },
  {
    "value" : "421819",
    "label" : "급성질환자모니터링장치및관련제품"
  },
  {
    "value" : "42181901",
    "label" : "응급태아또는산모모니터링장치"
  },
  {
    "value" : "42181902",
    "label" : "뇌압감시장치또는액세서리"
  },
  {
    "value" : "42181903",
    "label" : "심박출량감시장치또는액세서리"
  },
  {
    "value" : "42181904",
    "label" : "환자개인감시장치,환자감시장치"
  },
  {
    "value" : "42181908",
    "label" : "분획간압력감시세트또는액세서리"
  },
  {
    "value" : "421820",
    "label" : "검진용확대경,반사경및액세서리"
  },
  {
    "value" : "42182002",
    "label" : "항문경또는직장경,항문경"
  },
  {
    "value" : "42182005",
    "label" : "검안경,이경또는검안검이경세트"
  },
  {
    "value" : "42182006",
    "label" : "후두검사경또는구강경"
  },
  {
    "value" : "42182012",
    "label" : "비경또는확장기구"
  },
  {
    "value" : "42182013",
    "label" : "질검사경"
  },
  {
    "value" : "42182016",
    "label" : "비인두경또는액세서리"
  },
  {
    "value" : "42182017",
    "label" : "검이경,인후두경"
  },
  {
    "value" : "42182018",
    "label" : "기관지경또는액세서리"
  },
  {
    "value" : "42182020",
    "label" : "혈관경또는액세서리"
  },
  {
    "value" : "42182092",
    "label" : "기복기"
  },
  {
    "value" : "42182093",
    "label" : "기관확장기"
  },
  {
    "value" : "42182094",
    "label" : "범용의료용확장기"
  },
  {
    "value" : "42182097",
    "label" : "식도확장기"
  },
  {
    "value" : "421821",
    "label" : "청진기및관련제품"
  },
  {
    "value" : "42182101",
    "label" : "전자식청진기또는액세서리"
  },
  {
    "value" : "42182103",
    "label" : "기계식청진기또는액세서리"
  },
  {
    "value" : "42182106",
    "label" : "청진기식심음계"
  },
  {
    "value" : "42182108",
    "label" : "환자체온조절기"
  },
  {
    "value" : "421822",
    "label" : "의료용체온계및액세서리"
  },
  {
    "value" : "42182201",
    "label" : "전자체온계"
  },
  {
    "value" : "42182206",
    "label" : "수은체온계,체온계"
  },
  {
    "value" : "42182207",
    "label" : "환자용체온연속감시기또는트렌드모니터"
  },
  {
    "value" : "42182208",
    "label" : "붙이는체온계,환자용체온스트립"
  },
  {
    "value" : "42182212",
    "label" : "액정온도측정기구"
  },
  {
    "value" : "42182297",
    "label" : "의료용적외선촬영장치"
  },
  {
    "value" : "42182298",
    "label" : "알코올모세관체온계"
  },
  {
    "value" : "42182299",
    "label" : "안면인식 열화상 스마트패스(체온측정기)"
  },
  {
    "value" : "42182308",
    "label" : "뇌파기또는액세서리"
  },
  {
    "value" : "42182310",
    "label" : "근전도기"
  },
  {
    "value" : "42182313",
    "label" : "신경진단용세트"
  },
  {
    "value" : "42182314",
    "label" : "의료용바이오피드백장치"
  },
  {
    "value" : "42182315",
    "label" : "뇌파신호스펙트럼분석기"
  },
  {
    "value" : "42182316",
    "label" : "근이완감시장치,유발반응측정장치"
  },
  {
    "value" : "421824",
    "label" : "청력검사용제품"
  },
  {
    "value" : "42182401",
    "label" : "청력계또는액세서리"
  },
  {
    "value" : "42182402",
    "label" : "임피던스청력검사기"
  },
  {
    "value" : "42182403",
    "label" : "청력측정부스또는청력검사실"
  },
  {
    "value" : "42182502",
    "label" : "비강플로우미터또는리노아네모미터"
  },
  {
    "value" : "421826",
    "label" : "검사등또는램프"
  },
  {
    "value" : "42182601",
    "label" : "직립형검사등또는램프"
  },
  {
    "value" : "42182603",
    "label" : "검진용헤드라이트헤드램프또는액세서리"
  },
  {
    "value" : "42182701",
    "label" : "전자식 수평계,각도계"
  },
  {
    "value" : "42182702",
    "label" : "의료용테이프측정기"
  },
  {
    "value" : "42182703",
    "label" : "환자신장측정용자"
  },
  {
    "value" : "42182705",
    "label" : "체수분측정기, 인바디"
  },
  {
    "value" : "421828",
    "label" : "의료용체중계"
  },
  {
    "value" : "42182803",
    "label" : "범용병상또는테이블체중계"
  },
  {
    "value" : "42182901",
    "label" : "산부인과용진료대"
  },
  {
    "value" : "42183001",
    "label" : "시력검사표또는검사카드"
  },
  {
    "value" : "42183002",
    "label" : "각막토포그래퍼"
  },
  {
    "value" : "42183005",
    "label" : "안과용비색계"
  },
  {
    "value" : "42183009",
    "label" : "검안용렌즈"
  },
  {
    "value" : "42183010",
    "label" : "안과용렌조미터"
  },
  {
    "value" : "42183011",
    "label" : "안과용페리미터"
  },
  {
    "value" : "42183013",
    "label" : "안과용프리즘"
  },
  {
    "value" : "42183014",
    "label" : "안과용검영기"
  },
  {
    "value" : "42183015",
    "label" : "안과용세극등"
  },
  {
    "value" : "42183018",
    "label" : "안과용안압계또는액세서리"
  },
  {
    "value" : "42183021",
    "label" : "안과용시력검사기"
  },
  {
    "value" : "42183023",
    "label" : "검안기"
  },
  {
    "value" : "42183024",
    "label" : "안과용기구테이블또는액세서리"
  },
  {
    "value" : "42183026",
    "label" : "눈동맥혈압계"
  },
  {
    "value" : "42183029",
    "label" : "종합굴절검사기"
  },
  {
    "value" : "42183030",
    "label" : "눈가리개"
  },
  {
    "value" : "42183031",
    "label" : "가성동색표세트또는액세서리"
  },
  {
    "value" : "42183033",
    "label" : "안경맞춤세트"
  },
  {
    "value" : "42183034",
    "label" : "시력검사입체경"
  },
  {
    "value" : "42183035",
    "label" : "조합형굴절각막곡률계"
  },
  {
    "value" : "42183037",
    "label" : "차트프로젝터또는액세서리"
  },
  {
    "value" : "42183039",
    "label" : "안과용렌즈홀더"
  },
  {
    "value" : "42183040",
    "label" : "3D프린터 공정 자동화용 모듈"
  },
  {
    "value" : "42183041",
    "label" : "색인지검사용등"
  },
  {
    "value" : "42183045",
    "label" : "망막전위측정기"
  },
  {
    "value" : "42183046",
    "label" : "양안시각검사기구또는액세서리"
  },
  {
    "value" : "42183050",
    "label" : "안진계"
  },
  {
    "value" : "42183051",
    "label" : "동공계"
  },
  {
    "value" : "42183053",
    "label" : "동공거리측정기"
  },
  {
    "value" : "42183057",
    "label" : "안구운동감시장치"
  },
  {
    "value" : "42183059",
    "label" : "검안용광자극기"
  },
  {
    "value" : "42183060",
    "label" : "쉬머스트립"
  },
  {
    "value" : "42183061",
    "label" : "안구가압기"
  },
  {
    "value" : "42183062",
    "label" : "홍채촬영기"
  },
  {
    "value" : "42183098",
    "label" : "안저카메라"
  },
  {
    "value" : "421833",
    "label" : "이비인후과검사기용액세서리및관련제품"
  },
  {
    "value" : "42183399",
    "label" : "이비인후과용진료장치및의자"
  },
  {
    "value" : "42191602",
    "label" : "수술실조명또는액세서리"
  },
  {
    "value" : "42191607",
    "label" : "환자용큐비클커튼천막또는커튼트랙철물"
  },
  {
    "value" : "42191704",
    "label" : "의료용공기압축장치"
  },
  {
    "value" : "42191706",
    "label" : "의료용가스공급장치,의료용가스매니폴드"
  },
  {
    "value" : "42191710",
    "label" : "의료용가스실린더카트,스탠드또는액세서리"
  },
  {
    "value" : "42191802",
    "label" : "임상용인큐베이터또는유아용보온기"
  },
  {
    "value" : "42191803",
    "label" : "유아용침대"
  },
  {
    "value" : "42191804",
    "label" : "병상또는수술대용난간"
  },
  {
    "value" : "42191807",
    "label" : "수동식의료용침대,범용간호침상또는액세서리"
  },
  {
    "value" : "42191808",
    "label" : "중환자용간호침상또는액세서리"
  },
  {
    "value" : "42191809",
    "label" : "교압패드또는펌프"
  },
  {
    "value" : "42191899",
    "label" : "의료용공기분사침대"
  },
  {
    "value" : "42191904",
    "label" : "마약보관함또는보관고,시약보관함"
  },
  {
    "value" : "42191906",
    "label" : "모포또는용액가온기함"
  },
  {
    "value" : "42191907",
    "label" : "의료기구보관용캐비닛또는장,의료기구장"
  },
  {
    "value" : "42191908",
    "label" : "의료차트보관함또는액세서리"
  },
  {
    "value" : "42192001",
    "label" : "범용전동식진료대"
  },
  {
    "value" : "42192099",
    "label" : "범용진료대"
  },
  {
    "value" : "421921",
    "label" : "의료용의자,스툴및관련제품"
  },
  {
    "value" : "42192102",
    "label" : "병원용젖히는의자또는액세서리"
  },
  {
    "value" : "42192107",
    "label" : "검진용의자또는액세서리"
  },
  {
    "value" : "42192201",
    "label" : "환자운반기"
  },
  {
    "value" : "42192204",
    "label" : "환자이송용인큐베이터또는액세서리"
  },
  {
    "value" : "42192208",
    "label" : "휠체어액세서리,휠체어용안전판"
  },
  {
    "value" : "42192209",
    "label" : "휠체어경사로"
  },
  {
    "value" : "42192210",
    "label" : "휠체어"
  },
  {
    "value" : "421923",
    "label" : "환자용리프트"
  },
  {
    "value" : "42192301",
    "label" : "환자용리프트또는액세서리"
  },
  {
    "value" : "42192303",
    "label" : "환자용부유리프트또는슬링"
  },
  {
    "value" : "42192404",
    "label" : "의료용카트"
  },
  {
    "value" : "42192405",
    "label" : "관주기이동스탠드"
  },
  {
    "value" : "421926",
    "label" : "약품분배기,측정기및용품"
  },
  {
    "value" : "42192602",
    "label" : "약,알약분배기또는액세서리,약품분배기및포장기"
  },
  {
    "value" : "42192603",
    "label" : "투약용컵또는병또는액세서리"
  },
  {
    "value" : "42192605",
    "label" : "동맥류캡슐화키트"
  },
  {
    "value" : "42192606",
    "label" : "의료용흡입기"
  },
  {
    "value" : "42199098",
    "label" : "혈액냉동고"
  },
  {
    "value" : "42199099",
    "label" : "혈액.약품냉장고,혈액.약품냉장고"
  },
  {
    "value" : "42201501",
    "label" : "CT,CAT고정설치운영장비"
  },
  {
    "value" : "42201601",
    "label" : "MRI고정설치용장치"
  },
  {
    "value" : "42201701",
    "label" : "심장초음파진단기"
  },
  {
    "value" : "42201702",
    "label" : "태아또는산부인과용초음파장치"
  },
  {
    "value" : "42201704",
    "label" : "의료용초음파골밀도측정기"
  },
  {
    "value" : "42201712",
    "label" : "초음파영상진단장치"
  },
  {
    "value" : "42201714",
    "label" : "진동감각측정장치"
  },
  {
    "value" : "42201716",
    "label" : "혈관용초음파장치"
  },
  {
    "value" : "42201799",
    "label" : "초음파뇌조영장치"
  },
  {
    "value" : "422018",
    "label" : "의료용진단엑스선장치"
  },
  {
    "value" : "42201802",
    "label" : "흉부방사선장치"
  },
  {
    "value" : "42201803",
    "label" : "유방방사선장치"
  },
  {
    "value" : "42201804",
    "label" : "의료용C암방사선기기"
  },
  {
    "value" : "42201805",
    "label" : "의료용CINE투시장치"
  },
  {
    "value" : "42201806",
    "label" : "의료용방사선투시(RF)장치"
  },
  {
    "value" : "42201807",
    "label" : "의료용방사성동위원소스캐너"
  },
  {
    "value" : "42201810",
    "label" : "의료용방사선필름또는카세트"
  },
  {
    "value" : "42201812",
    "label" : "의료용방사선테이블,스탠드,의자,캐비닛또는액세서리"
  },
  {
    "value" : "42201813",
    "label" : "의료용방사선단층촬영장치,단층촬영용엑스선장치"
  },
  {
    "value" : "42201815",
    "label" : "의료용일반방사선장치"
  },
  {
    "value" : "42201817",
    "label" : "방사선골밀도측정기"
  },
  {
    "value" : "42201820",
    "label" : "의료용방사선촬영용격자그리드"
  },
  {
    "value" : "42201821",
    "label" : "방사선촬영용카세트홀더"
  },
  {
    "value" : "42201823",
    "label" : "의료용방사선튜브및고압변압기장치"
  },
  {
    "value" : "42201825",
    "label" : "의료용방사선기기용관구"
  },
  {
    "value" : "42201830",
    "label" : "Flexible CsI  신틸레이터"
  },
  {
    "value" : "42201835",
    "label" : "의료용방사선기기튜브장치조립품"
  },
  {
    "value" : "42201846",
    "label" : "방사선촬영용콜리메이터"
  },
  {
    "value" : "42201849",
    "label" : "형광스캐너"
  },
  {
    "value" : "42201850",
    "label" : "핵단층촬영장치"
  },
  {
    "value" : "42201852",
    "label" : "엑스선 검출기용 CsI 신틸레이터,방사선용형광지"
  },
  {
    "value" : "42201891",
    "label" : "감마계수기"
  },
  {
    "value" : "42201892",
    "label" : "베타계수기"
  },
  {
    "value" : "42201897",
    "label" : "포터블엑스선촬영장치"
  },
  {
    "value" : "42201898",
    "label" : "이동형엑스선촬영장치"
  },
  {
    "value" : "42201899",
    "label" : "Digital Radiography,디지털진단용엑스선촬영장치"
  },
  {
    "value" : "42201903",
    "label" : "의료용방사선필름판독기"
  },
  {
    "value" : "42201908",
    "label" : "의료용형광투시스크린후드"
  },
  {
    "value" : "42202001",
    "label" : "의료용일반감마카메라"
  },
  {
    "value" : "42202105",
    "label" : "근접방사선치료기"
  },
  {
    "value" : "42202401",
    "label" : "의료용PET장치"
  },
  {
    "value" : "42202501",
    "label" : "의료용SPECT기기"
  },
  {
    "value" : "42202702",
    "label" : "방사선원격치료용선형가속기"
  },
  {
    "value" : "42202703",
    "label" : "방사선원격치료관용전압방사선기"
  },
  {
    "value" : "42202801",
    "label" : "체외충격파쇄석기"
  },
  {
    "value" : "42203202",
    "label" : "CT또는CAT방사선치료시뮬레이터"
  },
  {
    "value" : "42203301",
    "label" : "고정틀,무고정틀정위방사선치료장치"
  },
  {
    "value" : "42203401",
    "label" : "관상동맥스텐트"
  },
  {
    "value" : "42203402",
    "label" : "진단혈관중재적카테터또는세트"
  },
  {
    "value" : "42203405",
    "label" : "혈관성형용풍선카테터"
  },
  {
    "value" : "42203407",
    "label" : "혈관조영제주입기세트"
  },
  {
    "value" : "42203416",
    "label" : "심장절제카테터"
  },
  {
    "value" : "42203430",
    "label" : "전극카테터"
  },
  {
    "value" : "42203493",
    "label" : "범용카테터"
  },
  {
    "value" : "42203498",
    "label" : "거치형디지털식순환기용엑스선투시진단장치"
  },
  {
    "value" : "42203499",
    "label" : "거치형아날로그식순환기용엑스선투시진단장치"
  },
  {
    "value" : "42203501",
    "label" : "인공심장박동기,심장재동기화치료용박동기또는액세서리"
  },
  {
    "value" : "42203502",
    "label" : "심장조율리드,전극또는액세서리"
  },
  {
    "value" : "42203598",
    "label" : "비이식형인공심장박동기수리교체재료"
  },
  {
    "value" : "42203599",
    "label" : "체외형인공심장박동기"
  },
  {
    "value" : "422036",
    "label" : "의료용방사선영상정보및저장제품"
  },
  {
    "value" : "42203603",
    "label" : "의료영상저장전송장치, 의료영상기록용컴퓨터장치(PACS)"
  },
  {
    "value" : "42203604",
    "label" : "의료용방사선필름기록장치하드웨어"
  },
  {
    "value" : "42203605",
    "label" : "의료용방사선필름기록장치소프트웨어"
  },
  {
    "value" : "42203606",
    "label" : "원격방사선영상진단시스템"
  },
  {
    "value" : "42203699",
    "label" : "치과용영상처리장치"
  },
  {
    "value" : "42203701",
    "label" : "스탬퍼"
  },
  {
    "value" : "42203704",
    "label" : "의료용토너또는현상액"
  },
  {
    "value" : "42203705",
    "label" : "의료영상건식레이저프린터또는영상표시기"
  },
  {
    "value" : "42204002",
    "label" : "방사선차단에이프런,마스크또는휘장"
  },
  {
    "value" : "42204005",
    "label" : "방사선차단장갑"
  },
  {
    "value" : "42204006",
    "label" : "설치용또는휴대용방사선차단막"
  },
  {
    "value" : "42204008",
    "label" : "방사선차단실챔버또는저장고"
  },
  {
    "value" : "42204009",
    "label" : "의료용방사선차단안경또는액세서리"
  },
  {
    "value" : "42204099",
    "label" : "방사선장해방어용기구"
  },
  {
    "value" : "4221",
    "label" : "장애인용자활보조기"
  },
  {
    "value" : "422115",
    "label" : "장애인용보행,이동,체위고정보조기"
  },
  {
    "value" : "42211501",
    "label" : "지팡이또는지팡이액세서리"
  },
  {
    "value" : "42211502",
    "label" : "목발또는목발액세서리,목발 및 코아네트 등"
  },
  {
    "value" : "42211503",
    "label" : "신체자세교정기"
  },
  {
    "value" : "42211504",
    "label" : "스탠더또는직립보조기"
  },
  {
    "value" : "42211506",
    "label" : "보행보조기또는롤레이터,보행보조기"
  },
  {
    "value" : "42211507",
    "label" : "전신슬라이딩또는회전장치"
  },
  {
    "value" : "422116",
    "label" : "장애인용욕실및목욕액세서리"
  },
  {
    "value" : "42211602",
    "label" : "장애인용목욕솔스펀지또는때밀이"
  },
  {
    "value" : "42211603",
    "label" : "장애인용목욕리프트또는액세서리"
  },
  {
    "value" : "42211606",
    "label" : "장애인용이동식변기또는액세서리"
  },
  {
    "value" : "42211608",
    "label" : "장애인용손잡이봉또는미끄럼방지레일"
  },
  {
    "value" : "42211610",
    "label" : "장애인용목욕의자또는샤워시트"
  },
  {
    "value" : "42211611",
    "label" : "장애인용좌욕기"
  },
  {
    "value" : "42211615",
    "label" : "장애인화장실용자세보조기"
  },
  {
    "value" : "42211617",
    "label" : "장애인용이동의자"
  },
  {
    "value" : "422117",
    "label" : "장애인용의사소통보조기"
  },
  {
    "value" : "42211701",
    "label" : "장애인용의사소통스위치"
  },
  {
    "value" : "42211702",
    "label" : "시각장애인용점자독서기"
  },
  {
    "value" : "42211704",
    "label" : "장애인용헤드포인터또는마우스스틱"
  },
  {
    "value" : "42211705",
    "label" : "보청기"
  },
  {
    "value" : "42211706",
    "label" : "장애인용글자판또는그림판"
  },
  {
    "value" : "42211710",
    "label" : "장애인용필기보조기"
  },
  {
    "value" : "42211796",
    "label" : "청각훈련기"
  },
  {
    "value" : "42211797",
    "label" : "약시인용확대장치"
  },
  {
    "value" : "42211798",
    "label" : "장애인용발표력훈련보조기"
  },
  {
    "value" : "42211807",
    "label" : "장애인용구강관리용품"
  },
  {
    "value" : "42211901",
    "label" : "장애인용미끄럼방지재"
  },
  {
    "value" : "42211907",
    "label" : "장애인용숟가락"
  },
  {
    "value" : "42211908",
    "label" : "장애인용도마또는조리대,일상생활훈련도구세트"
  },
  {
    "value" : "42211915",
    "label" : "장애인용식사도구또는액세서리"
  },
  {
    "value" : "42212002",
    "label" : "장애인용키터너또는열쇠고리"
  },
  {
    "value" : "42212003",
    "label" : "장애인용문고리손잡이"
  },
  {
    "value" : "42212107",
    "label" : "장애인용수영보조장비"
  },
  {
    "value" : "42212109",
    "label" : "장애인용공예도구또는재료"
  },
  {
    "value" : "42212111",
    "label" : "장애인용게임카드홀더"
  },
  {
    "value" : "42221502",
    "label" : "동맥연속카테터관류밸브"
  },
  {
    "value" : "42221512",
    "label" : "정맥또는동맥캐뉼러또는액세서리"
  },
  {
    "value" : "42221513",
    "label" : "심혈관용카테터삽입키트"
  },
  {
    "value" : "42221604",
    "label" : "정맥,동맥튜빙어댑터,커넥터,락,캡또는프로텍터"
  },
  {
    "value" : "42221607",
    "label" : "범용정맥,동맥튜빙필터또는스크린"
  },
  {
    "value" : "42221609",
    "label" : "정맥또는동맥튜빙투여세트"
  },
  {
    "value" : "42221612",
    "label" : "정맥,동맥튜빙무침주입구,스탑코크또는매니폴드"
  },
  {
    "value" : "42221701",
    "label" : "정맥,동맥주입싱글포트백또는용기"
  },
  {
    "value" : "42221703",
    "label" : "정맥또는동맥주입액가온기"
  },
  {
    "value" : "42221704",
    "label" : "정맥또는동맥압력주입주머니"
  },
  {
    "value" : "42221903",
    "label" : "동정맥루혈류량측정기,초음파용혈류탐지기"
  },
  {
    "value" : "42221904",
    "label" : "혈류계"
  },
  {
    "value" : "42221999",
    "label" : "혈액용적측정기"
  },
  {
    "value" : "42222001",
    "label" : "의약품주입펌프"
  },
  {
    "value" : "42222002",
    "label" : "정맥용주사기주입펌프"
  },
  {
    "value" : "42222006",
    "label" : "정맥주입펌프분석기또는감지기"
  },
  {
    "value" : "42222008",
    "label" : "주사자동주입기"
  },
  {
    "value" : "42222009",
    "label" : "의약품동기주입기"
  },
  {
    "value" : "42222301",
    "label" : "수혈투여키트"
  },
  {
    "value" : "42222307",
    "label" : "혈액가온또는수혈장치"
  },
  {
    "value" : "42222308",
    "label" : "혈액투여,수혈백또는용기"
  },
  {
    "value" : "42231701",
    "label" : "비강위장관"
  },
  {
    "value" : "422318",
    "label" : "영양보조용유동식및제품"
  },
  {
    "value" : "422319",
    "label" : "모유수유장비및용품"
  },
  {
    "value" : "42231901",
    "label" : "모유유축기또는액세서리"
  },
  {
    "value" : "42232001",
    "label" : "정제분쇄기또는액세서리"
  },
  {
    "value" : "42241501",
    "label" : "캐스트신발"
  },
  {
    "value" : "42241502",
    "label" : "캐스트또는스프린트패딩재"
  },
  {
    "value" : "42241505",
    "label" : "정형외과용캐스팅롤또는테이프"
  },
  {
    "value" : "42241507",
    "label" : "부목"
  },
  {
    "value" : "42241602",
    "label" : "캐스트절단기또는톱"
  },
  {
    "value" : "42241701",
    "label" : "발목또는발보호용정형자재"
  },
  {
    "value" : "42241703",
    "label" : "무릎보조기또는무릎보호대"
  },
  {
    "value" : "42241704",
    "label" : "무릎고정기또는무릎랩"
  },
  {
    "value" : "42241705",
    "label" : "하퇴보호용정형자재"
  },
  {
    "value" : "42241708",
    "label" : "대퇴부골절받침"
  },
  {
    "value" : "42241802",
    "label" : "허리보호용정형자재"
  },
  {
    "value" : "42241805",
    "label" : "팔꿈치 관절 고정 프레임 좌 ,팔꿈치 관절 고정 프레임 우"
  },
  {
    "value" : "42241806",
    "label" : "팔,손목또는엄지보호용정형자재,팔토시"
  },
  {
    "value" : "42241807",
    "label" : "손또는손가락보호용정형자재"
  },
  {
    "value" : "42241811",
    "label" : "탈장대"
  },
  {
    "value" : "42242001",
    "label" : "하지보철장치"
  },
  {
    "value" : "42242002",
    "label" : "상지보철장치"
  },
  {
    "value" : "42242003",
    "label" : "보철클램프장치또는액세서리"
  },
  {
    "value" : "42242105",
    "label" : "이동식견인카트"
  },
  {
    "value" : "42242108",
    "label" : "정형외과용견인장치설비또는견인추"
  },
  {
    "value" : "422423",
    "label" : "정형외과용기기및용품"
  },
  {
    "value" : "42242399",
    "label" : "정형용교정장치"
  },
  {
    "value" : "42251502",
    "label" : "인지수동기민성지각감각평가또는검사제품"
  },
  {
    "value" : "42251504",
    "label" : "Olfactor effector,치료용나무판또는반응판"
  },
  {
    "value" : "42251506",
    "label" : "색상훈련기"
  },
  {
    "value" : "422516",
    "label" : "재활운동장치및기기"
  },
  {
    "value" : "42251601",
    "label" : "균형잡기흔들판"
  },
  {
    "value" : "42251603",
    "label" : "정형용운동장치"
  },
  {
    "value" : "42251605",
    "label" : "근육등속및등장운동장치, 등속성관절진단기"
  },
  {
    "value" : "42251606",
    "label" : "재활또는치료용파우더보드"
  },
  {
    "value" : "42251607",
    "label" : "재활또는치료용도르래또는액세서리"
  },
  {
    "value" : "42251608",
    "label" : "저항운동밴드"
  },
  {
    "value" : "42251610",
    "label" : "치료용공또는액세서리"
  },
  {
    "value" : "42251611",
    "label" : "재활또는치료용전정운동장치"
  },
  {
    "value" : "42251612",
    "label" : "재활또는치료용추세트또는액세서리"
  },
  {
    "value" : "42251613",
    "label" : "치료용부하운동기구"
  },
  {
    "value" : "42251614",
    "label" : "재활또는치료용모래주머니또는모래주머니세트,의무 파우치"
  },
  {
    "value" : "42251616",
    "label" : "재활또는치료용전기진동기"
  },
  {
    "value" : "42251617",
    "label" : "치료용쿠션의자또는액세서리"
  },
  {
    "value" : "42251618",
    "label" : "재활또는치료용손목운동기"
  },
  {
    "value" : "42251619",
    "label" : "치료용세라믹점토또는액세서리"
  },
  {
    "value" : "42251620",
    "label" : "재활또는치료용매트또는플랫폼, 미끄럼방지매트"
  },
  {
    "value" : "42251621",
    "label" : "재활또는치료용다리운동기구"
  },
  {
    "value" : "42251623",
    "label" : "초음파치료기또는용품"
  },
  {
    "value" : "42251624",
    "label" : "재활또는치료용하중치료기"
  },
  {
    "value" : "42251698",
    "label" : "안마기, 안마의자"
  },
  {
    "value" : "42251699",
    "label" : "로봇보조정형운동장치, 반원형적목꽂이"
  },
  {
    "value" : "42251702",
    "label" : "재활또는치료용경사대"
  },
  {
    "value" : "42251703",
    "label" : "재활또는치료용계단"
  },
  {
    "value" : "42251705",
    "label" : "재활또는치료용평행봉, 희망전동평행봉운동기"
  },
  {
    "value" : "42251706",
    "label" : "보행훈련용보행기,기립훈련치료기"
  },
  {
    "value" : "42251803",
    "label" : "재활또는치료용파이프트리"
  },
  {
    "value" : "42251805",
    "label" : "재활또는치료용작업대또는액세서리"
  },
  {
    "value" : "42261509",
    "label" : "부검용세트,부검용해부키트"
  },
  {
    "value" : "42261512",
    "label" : "부검용톱"
  },
  {
    "value" : "42261611",
    "label" : "검시용직장체온계"
  },
  {
    "value" : "42261703",
    "label" : "부검대또는액세서리"
  },
  {
    "value" : "42261705",
    "label" : "동물해부용부검대또는액세서리"
  },
  {
    "value" : "422618",
    "label" : "시신운반,보관기기및용품"
  },
  {
    "value" : "42261802",
    "label" : "시신운반기"
  },
  {
    "value" : "42261804",
    "label" : "시신보관냉장고"
  },
  {
    "value" : "42261806",
    "label" : "시신냉동고"
  },
  {
    "value" : "42261809",
    "label" : "시신리프트또는운반보조장치"
  },
  {
    "value" : "42271505",
    "label" : "호흡감시용키트또는액세서리"
  },
  {
    "value" : "42271506",
    "label" : "산소감시기또는용품"
  },
  {
    "value" : "42271602",
    "label" : "호흡측정기,그액세서리또는용품"
  },
  {
    "value" : "42271604",
    "label" : "폐활량측정기"
  },
  {
    "value" : "42271605",
    "label" : "폐기능계량기"
  },
  {
    "value" : "42271609",
    "label" : "수면분석모니터또는액세서리"
  },
  {
    "value" : "42271611",
    "label" : "폐환기감시기"
  },
  {
    "value" : "42271612",
    "label" : "폐가스분석기또는감시기"
  },
  {
    "value" : "42271614",
    "label" : "호흡온도감시기"
  },
  {
    "value" : "42271617",
    "label" : "고빈도흉벽진동기,가슴타진기"
  },
  {
    "value" : "42271701",
    "label" : "산소공급기"
  },
  {
    "value" : "42271702",
    "label" : "의료용산소발생기,산소농축기"
  },
  {
    "value" : "42271703",
    "label" : "의료용산소혼합공급기"
  },
  {
    "value" : "42271707",
    "label" : "반도체용 가스 공급 장치"
  },
  {
    "value" : "42271708",
    "label" : "의료용산소마스크또는부속품"
  },
  {
    "value" : "42271712",
    "label" : "의료용에어로졸텐트"
  },
  {
    "value" : "42271713",
    "label" : "고압산소챔버"
  },
  {
    "value" : "42271714",
    "label" : "의료용인큐베이터"
  },
  {
    "value" : "42271715",
    "label" : "의료용산소튜빙또는커넥터"
  },
  {
    "value" : "42271718",
    "label" : "의료가스분배장치,산소치료공급시스템제품관련액세서리또는용품"
  },
  {
    "value" : "42271799",
    "label" : "의료용격리챔버,환자격리챔버"
  },
  {
    "value" : "42271801",
    "label" : "가습기또는기화기"
  },
  {
    "value" : "42271903",
    "label" : "기관내관"
  },
  {
    "value" : "42271904",
    "label" : "기관절개관"
  },
  {
    "value" : "42271911",
    "label" : "호흡압력계키트"
  },
  {
    "value" : "42271912",
    "label" : "비인두관용튜브"
  },
  {
    "value" : "42271916",
    "label" : "상기도진단장치또는액세서리"
  },
  {
    "value" : "42272001",
    "label" : "연성후두경,후두경또는액세서리"
  },
  {
    "value" : "42272004",
    "label" : "기관내삽관용탐침"
  },
  {
    "value" : "42272006",
    "label" : "삽입기"
  },
  {
    "value" : "42272010",
    "label" : "석션펌프"
  },
  {
    "value" : "422721",
    "label" : "기계식음압호흡기"
  },
  {
    "value" : "42272101",
    "label" : "아이언렁"
  },
  {
    "value" : "42272102",
    "label" : "체스트큐이레스"
  },
  {
    "value" : "42272206",
    "label" : "유아중환자용인공호흡기"
  },
  {
    "value" : "42272209",
    "label" : "인공호흡기,자동산소소생기,고유량기(양압지속유지기)"
  },
  {
    "value" : "42272220",
    "label" : "수동식 인공호흡기(BVM), 실리콘 재질,인공호흡기액세서리"
  },
  {
    "value" : "42272299",
    "label" : "재호흡기"
  },
  {
    "value" : "422723",
    "label" : "심폐소생용용품"
  },
  {
    "value" : "42272301",
    "label" : "수동심폐소생기"
  },
  {
    "value" : "42272303",
    "label" : "심폐소생용마스크또는액세서리"
  },
  {
    "value" : "42272304",
    "label" : "심폐소생기부품또는액세서리"
  },
  {
    "value" : "42272306",
    "label" : "심폐소생용키트"
  },
  {
    "value" : "42272501",
    "label" : "가스마취기"
  },
  {
    "value" : "42272502",
    "label" : "가스마취기용흡수기기"
  },
  {
    "value" : "42272505",
    "label" : "가스마취기용튜브튜브부속품튜브조립품또는액세서리"
  },
  {
    "value" : "42272509",
    "label" : "마취기용측정기또는액세서리"
  },
  {
    "value" : "42272598",
    "label" : "전기마취기"
  },
  {
    "value" : "42272599",
    "label" : "가스마취기용기화기"
  },
  {
    "value" : "4228",
    "label" : "의료용소독용품"
  },
  {
    "value" : "422815",
    "label" : "고압증기멸균기,살균기기및액세서리"
  },
  {
    "value" : "42281501",
    "label" : "에틸렌옥사이드가스멸균기"
  },
  {
    "value" : "42281502",
    "label" : "건열멸균기또는고온공기멸균기"
  },
  {
    "value" : "42281503",
    "label" : "의료용무균수장치"
  },
  {
    "value" : "42281508",
    "label" : "고압증기멸균기"
  },
  {
    "value" : "42281510",
    "label" : "멸균기클립,랙,스트링거또는홀더"
  },
  {
    "value" : "42281515",
    "label" : "멸균용캐비닛"
  },
  {
    "value" : "42281531",
    "label" : "의료용자외선소독기"
  },
  {
    "value" : "42281596",
    "label" : "고압멸균기"
  },
  {
    "value" : "42281597",
    "label" : "의료용저온플라즈마멸균기"
  },
  {
    "value" : "42281599",
    "label" : "의료용자비소독기"
  },
  {
    "value" : "422816",
    "label" : "냉멸균및멸균용액"
  },
  {
    "value" : "42281606",
    "label" : "의료용가스훈증기"
  },
  {
    "value" : "422817",
    "label" : "소독또는전멸균세척장비및세정제"
  },
  {
    "value" : "42281704",
    "label" : "기구용클리너또는세척제"
  },
  {
    "value" : "42281705",
    "label" : "의료기기및기구용살균세척기기"
  },
  {
    "value" : "42281706",
    "label" : "기구용윤활제또는윤활유"
  },
  {
    "value" : "42281709",
    "label" : "멸균용클리닝브러시"
  },
  {
    "value" : "42281712",
    "label" : "초음파세척기"
  },
  {
    "value" : "42281713",
    "label" : "멸균용배액팬"
  },
  {
    "value" : "422819",
    "label" : "멸균용랩및포장용품"
  },
  {
    "value" : "42281902",
    "label" : "스크래치랩"
  },
  {
    "value" : "42281904",
    "label" : "멸균용파우치,토니킷파우치"
  },
  {
    "value" : "42281915",
    "label" : "멸균용릴"
  },
  {
    "value" : "4229",
    "label" : "수술용제품"
  },
  {
    "value" : "42291601",
    "label" : "레이저수술용메스,나이프또는나이프핸들"
  },
  {
    "value" : "42291604",
    "label" : "수술용골핸드소,줄톱,톱손잡이"
  },
  {
    "value" : "42291607",
    "label" : "수술용큐렛또는루프"
  },
  {
    "value" : "42291609",
    "label" : "와이어커터"
  },
  {
    "value" : "42291612",
    "label" : "의료용겸자"
  },
  {
    "value" : "42291613",
    "label" : "수술용메스"
  },
  {
    "value" : "42291614",
    "label" : "구조사용가위"
  },
  {
    "value" : "42291620",
    "label" : "수술용범용투관침또는액세서리"
  },
  {
    "value" : "42291627",
    "label" : "수술용버또는액세서리"
  },
  {
    "value" : "42291701",
    "label" : "수술용 드릴"
  },
  {
    "value" : "42291702",
    "label" : "수술용핸드리머또는미세천공기"
  },
  {
    "value" : "42291703",
    "label" : "수술용천공기"
  },
  {
    "value" : "42291704",
    "label" : "수술용펀치펀치홀더또는액세서리"
  },
  {
    "value" : "42291707",
    "label" : "개두술용키트"
  },
  {
    "value" : "42291799",
    "label" : "의료용핸드피스, Micro100 Wire Driver,Articular arm,의료용핸드피스,Articulated Arm"
  },
  {
    "value" : "42291801",
    "label" : "수술용밴드결찰기,밴드또는관련제품"
  },
  {
    "value" : "42291802",
    "label" : "DUCHEFA FORCEPS,수술용클램프,클립,포셉또는액세서리"
  },
  {
    "value" : "42291901",
    "label" : "수술용기구홀더또는고정기"
  },
  {
    "value" : "42292301",
    "label" : "수술용벤딩기구"
  },
  {
    "value" : "42292303",
    "label" : "수술용섭자"
  },
  {
    "value" : "42292306",
    "label" : "수술용와이어홀딩포셉또는트위스터"
  },
  {
    "value" : "42292403",
    "label" : "수술용래칫핸들"
  },
  {
    "value" : "42292603",
    "label" : "수술용탐침또는지시기"
  },
  {
    "value" : "42292899",
    "label" : "광선환부표시기"
  },
  {
    "value" : "42292901",
    "label" : "수술용봉합기구"
  },
  {
    "value" : "42292907",
    "label" : "피부스트레칭장비"
  },
  {
    "value" : "42293002",
    "label" : "수술용계량기또는계측자"
  },
  {
    "value" : "42293102",
    "label" : "수술용리트랙션후크"
  },
  {
    "value" : "42293104",
    "label" : "수술용마우스개그또는액세서리"
  },
  {
    "value" : "42293106",
    "label" : "수술용리트랙터세트"
  },
  {
    "value" : "42293107",
    "label" : "외과 수술기구 세트,수술용일반리트랙터"
  },
  {
    "value" : "42293112",
    "label" : "심혈관또는흉부용리트랙터"
  },
  {
    "value" : "42293119",
    "label" : "복부용리트랙터"
  },
  {
    "value" : "42293130",
    "label" : "허파용리트랙터"
  },
  {
    "value" : "422933",
    "label" : "ZrO2, ZrO2"
  },
  {
    "value" : "42293303",
    "label" : "수술용스페큘라"
  },
  {
    "value" : "42293401",
    "label" : "수술용가이드"
  },
  {
    "value" : "42293406",
    "label" : "수술용임플란트포지셔너"
  },
  {
    "value" : "422935",
    "label" : "수술용석션캐뉼라,세척용캐뉼라,팁,스타일렛및관련제품"
  },
  {
    "value" : "42293505",
    "label" : "수술용흡인관프로브"
  },
  {
    "value" : "42293509",
    "label" : "수술용세척세트또는액세서리"
  },
  {
    "value" : "42294202",
    "label" : "외고정술기구세트또는장치"
  },
  {
    "value" : "42294203",
    "label" : "일반외과기구세트"
  },
  {
    "value" : "42294204",
    "label" : "미세수술또는성형수술기구세트"
  },
  {
    "value" : "42294206",
    "label" : "안과수술기구세트"
  },
  {
    "value" : "42294215",
    "label" : "개두술용수술기기세트"
  },
  {
    "value" : "42294218",
    "label" : "이비인후과용수술기구세트"
  },
  {
    "value" : "42294219",
    "label" : "정형외과용수술기구세트"
  },
  {
    "value" : "42294220",
    "label" : "혈액복구및운반장치"
  },
  {
    "value" : "422944",
    "label" : "혈관계및심장계장치"
  },
  {
    "value" : "42294511",
    "label" : "안과수술용칼,날,가위또는액세서리"
  },
  {
    "value" : "42294521",
    "label" : "공막용버클링컴포넌트"
  },
  {
    "value" : "42294531",
    "label" : "시계조사레이저장치"
  },
  {
    "value" : "42294603",
    "label" : "자가수혈장치"
  },
  {
    "value" : "422947",
    "label" : "개심술관류기기,모니터,액세서리및관련제품"
  },
  {
    "value" : "42294701",
    "label" : "심폐기계또는액세서리"
  },
  {
    "value" : "42294704",
    "label" : "관류필터또는관련제품"
  },
  {
    "value" : "42294706",
    "label" : "관류용버블트랩"
  },
  {
    "value" : "42294710",
    "label" : "Recirculating chiller,냉각기"
  },
  {
    "value" : "42294711",
    "label" : "관류용혈액농축기또는액세서리"
  },
  {
    "value" : "42294718",
    "label" : "관류용펌프"
  },
  {
    "value" : "42294723",
    "label" : "대동맥내풍선펌프키트"
  },
  {
    "value" : "42294802",
    "label" : "연성내시경액세서리또는관련제품"
  },
  {
    "value" : "42294803",
    "label" : "요관경"
  },
  {
    "value" : "42294804",
    "label" : "절제경"
  },
  {
    "value" : "42294805",
    "label" : "연성 복강경(FLEXIBLE SCOPE)"
  },
  {
    "value" : "42294806",
    "label" : "방광경"
  },
  {
    "value" : "42294808",
    "label" : "식도경또는액세서리"
  },
  {
    "value" : "42294880",
    "label" : "근막하절제술용내시경"
  },
  {
    "value" : "42294881",
    "label" : "흉강경"
  },
  {
    "value" : "42294883",
    "label" : "척추요추척수경"
  },
  {
    "value" : "42294885",
    "label" : "부비강경"
  },
  {
    "value" : "42294886",
    "label" : "초음파내시경"
  },
  {
    "value" : "42294887",
    "label" : "신경내시경"
  },
  {
    "value" : "42294888",
    "label" : "식도·위·십이지장경"
  },
  {
    "value" : "42294889",
    "label" : "비인후경,비인후경 및 영상장비, Storz, Image1 X-LINK"
  },
  {
    "value" : "42294891",
    "label" : "골반강경"
  },
  {
    "value" : "42294892",
    "label" : "신우경"
  },
  {
    "value" : "42294894",
    "label" : "십이지장경"
  },
  {
    "value" : "42294895",
    "label" : "위내시경"
  },
  {
    "value" : "42294896",
    "label" : "대장경,Sigmoidoscopy(결장경)"
  },
  {
    "value" : "42294897",
    "label" : "담도췌장경"
  },
  {
    "value" : "42294898",
    "label" : "관절경"
  },
  {
    "value" : "42294901",
    "label" : "내시경,내시경고정장치또는홀더"
  },
  {
    "value" : "42294902",
    "label" : "관절경 구매 ,내시경용시술장치또는승강장치"
  },
  {
    "value" : "42294903",
    "label" : "내시경용흡인또는생검바늘"
  },
  {
    "value" : "42294905",
    "label" : "내시경용청소브러시또는관련제품"
  },
  {
    "value" : "42294907",
    "label" : "내시경용세포또는미생물브러시"
  },
  {
    "value" : "42294908",
    "label" : "내시경용클램프,박리기,그래스퍼,포셉또는결찰기"
  },
  {
    "value" : "42294910",
    "label" : "내시경용전극또는케이블"
  },
  {
    "value" : "42294914",
    "label" : "내시경기구세트"
  },
  {
    "value" : "42294919",
    "label" : "내시경용삽입유도기구,가이드와이어또는글라이드와이어,bending section 외 12종 "
  },
  {
    "value" : "42294925",
    "label" : "내시경용바늘또는펀치"
  },
  {
    "value" : "42294930",
    "label" : "내시경용스네어스네어와이어또는액세서리"
  },
  {
    "value" : "42294933",
    "label" : "내시경용석션세척팁응고탐침또는액세서리"
  },
  {
    "value" : "42294935",
    "label" : "내시경용트로커시스캐뉼러옵튜레이터처리트레이키트"
  },
  {
    "value" : "422950",
    "label" : "내시경기기,액세서리및관련제품"
  },
  {
    "value" : "42295002",
    "label" : "내시경보관함또는액세서리,내시경 소독보관장"
  },
  {
    "value" : "42295004",
    "label" : "내시경기기또는프로시저카트또는액세서리"
  },
  {
    "value" : "42295007",
    "label" : "내시경용영상장치또는액세서리"
  },
  {
    "value" : "42295009",
    "label" : "복합-다파장 광원모듈 설계 및 샘플제작"
  },
  {
    "value" : "42295011",
    "label" : "내시경용비디오카메라,레코더,어댑터또는액세서리"
  },
  {
    "value" : "42295015",
    "label" : "120-34,내시경용렌즈"
  },
  {
    "value" : "42295099",
    "label" : "외과용비디오카메라"
  },
  {
    "value" : "42295102",
    "label" : "냉동수술기기또는액세서리"
  },
  {
    "value" : "42295103",
    "label" : "분만실,제왕절개시술대,액세서리또는관련제품"
  },
  {
    "value" : "42295104",
    "label" : "게이트회로 모듈 제작,전기수술기"
  },
  {
    "value" : "42295108",
    "label" : "수술실용환자프랙처테이블,정형외과용테이블액세서리"
  },
  {
    "value" : "42295111",
    "label" : "환자고정보조대,척추고정장치"
  },
  {
    "value" : "42295112",
    "label" : "전동식수술대, 전동유압식"
  },
  {
    "value" : "42295114",
    "label" : "안과수술용초음파유화흡입술압출기기또는액세서리"
  },
  {
    "value" : "42295115",
    "label" : "회전의자,실험실용 의자,의사용회전의자또는액세서리"
  },
  {
    "value" : "42295119",
    "label" : "수술용레이저또는액세서리"
  },
  {
    "value" : "42295120",
    "label" : "수술용쇄석기또는액세서리"
  },
  {
    "value" : "42295121",
    "label" : "신경외과 수술용 현미경,수술용현미경,루페,확대기또는액세서리"
  },
  {
    "value" : "42295122",
    "label" : "수술용공압,전기지혈대또는액세서리"
  },
  {
    "value" : "42295138",
    "label" : "비뇨기과수술용확장장치또는액세서리"
  },
  {
    "value" : "42295144",
    "label" : "극초단파수술기"
  },
  {
    "value" : "42295147",
    "label" : "초음파수술기"
  },
  {
    "value" : "42295201",
    "label" : "수술용피부절제기,더마브레이더,더마메셔또는액세서리"
  },
  {
    "value" : "42295202",
    "label" : "수술용공압,전지,전기톱,드릴,핀드라이버또는액세서리"
  },
  {
    "value" : "42295302",
    "label" : "수술용관류캐뉼러"
  },
  {
    "value" : "42295303",
    "label" : "수술용관류카테터,커넥터또는액세서리"
  },
  {
    "value" : "42295399",
    "label" : "의료용광응고기"
  },
  {
    "value" : "42295401",
    "label" : "충전용수술소작펜"
  },
  {
    "value" : "42295402",
    "label" : "수술용마킹펜"
  },
  {
    "value" : "42295405",
    "label" : "무내시경수술또는개심술용삽관기구,안내핀,가이드와이어"
  },
  {
    "value" : "42295409",
    "label" : "외과용어플리케이터"
  },
  {
    "value" : "42295419",
    "label" : "수술용신경자극기또는액세서리"
  },
  {
    "value" : "42295420",
    "label" : "수술용카테터내시경카테터삽입키트또는배액주머니"
  },
  {
    "value" : "42295421",
    "label" : "수술준비용스크럽또는도포액"
  },
  {
    "value" : "42295426",
    "label" : "수술용표본수집트랩또는용기"
  },
  {
    "value" : "42295433",
    "label" : "비뇨기과수술용카테터또는액세서리"
  },
  {
    "value" : "42295453",
    "label" : "수술용배액세트또는액세서리"
  },
  {
    "value" : "42295467",
    "label" : "수술용본시멘트,시멘트혼합장치또는액세서리"
  },
  {
    "value" : "42295502",
    "label" : "인체조직임플란트"
  },
  {
    "value" : "42295503",
    "label" : "이식형주입포트또는액세서리"
  },
  {
    "value" : "42295505",
    "label" : "안과용임플란트"
  },
  {
    "value" : "42295506",
    "label" : "구강악안면임플란트또는세트"
  },
  {
    "value" : "42295508",
    "label" : "이후두과용임플란트또는세트"
  },
  {
    "value" : "42295513",
    "label" : "수술용메시또는조직막제품"
  },
  {
    "value" : "42295515",
    "label" : "합성조직임플란트"
  },
  {
    "value" : "42295517",
    "label" : "심혈관용확장기"
  },
  {
    "value" : "42295519",
    "label" : "음경보철용준비물품세트"
  },
  {
    "value" : "42295597",
    "label" : "의료용충전기"
  },
  {
    "value" : "42295801",
    "label" : "수술용열절제카세트"
  },
  {
    "value" : "42295802",
    "label" : "수술용열절체튜브세트"
  },
  {
    "value" : "42295902",
    "label" : "기관지스텐트"
  },
  {
    "value" : "42295906",
    "label" : "식도스텐트"
  },
  {
    "value" : "42296006",
    "label" : "혈관그라프트"
  },
  {
    "value" : "42301501",
    "label" : "의학교육또는실습용인체해부모형,인체해부모형,피부봉합실습모형"
  },
  {
    "value" : "42301502",
    "label" : "장탈출모형, 여성도뇨실습모형"
  },
  {
    "value" : "42301504",
    "label" : "의학교육또는실습키트"
  },
  {
    "value" : "42301506",
    "label" : "이중귀꽂이청진기"
  },
  {
    "value" : "42311505",
    "label" : "범용붕대또는드레싱"
  },
  {
    "value" : "42311506",
    "label" : "압박붕대또는드레싱"
  },
  {
    "value" : "42311508",
    "label" : "드레싱트레이"
  },
  {
    "value" : "42311509",
    "label" : "플러프드레싱"
  },
  {
    "value" : "42311510",
    "label" : "폼드레싱"
  },
  {
    "value" : "42311511",
    "label" : "거즈붕대"
  },
  {
    "value" : "42311512",
    "label" : "거즈스펀지"
  },
  {
    "value" : "42311517",
    "label" : "붕대또는드레싱용접착액"
  },
  {
    "value" : "42311518",
    "label" : "의료용안대또는아이패치"
  },
  {
    "value" : "42311525",
    "label" : "키네시오테이프"
  },
  {
    "value" : "42311531",
    "label" : "드레싱커버"
  },
  {
    "value" : "42311532",
    "label" : "드라이드레싱"
  },
  {
    "value" : "42311537",
    "label" : "붕대가위,붕대가위또는그용품"
  },
  {
    "value" : "423117",
    "label" : "의료용수술용특수접착테이프및관련제품"
  },
  {
    "value" : "42311703",
    "label" : "의료또는수술용피부접착테이프"
  },
  {
    "value" : "42311708",
    "label" : "범용의료용또는수술용접착테이프"
  },
  {
    "value" : "42312005",
    "label" : "피부봉합용의료접착제또는풀"
  },
  {
    "value" : "42312006",
    "label" : "체내장착용의료클립어플라이어"
  },
  {
    "value" : "42312007",
    "label" : "체내용의료클립"
  },
  {
    "value" : "42312012",
    "label" : "피부봉합용스태플"
  },
  {
    "value" : "42312014",
    "label" : "조직봉합용키트트레이팩또는세트"
  },
  {
    "value" : "42312105",
    "label" : "Arm Sleeve, Cool,피부보호대"
  },
  {
    "value" : "42312108",
    "label" : "환부배액파우치"
  },
  {
    "value" : "42312201",
    "label" : "봉합사"
  },
  {
    "value" : "42312202",
    "label" : "총상 응급키트,봉합키트트레이팩또는세트,파라필름"
  },
  {
    "value" : "42312206",
    "label" : "봉합바늘"
  },
  {
    "value" : "42312309",
    "label" : "환부세정용품"
  },
  {
    "value" : "42312402",
    "label" : "비강부목또는비강스텐트"
  },
  {
    "value" : "42312502",
    "label" : "복대"
  },
  {
    "value" : "42312602",
    "label" : "음압상처치료시스템또는키트"
  },
  {
    "value" : "42321501",
    "label" : "정형외과수술용와이어"
  },
  {
    "value" : "42321503",
    "label" : "뼈이식확장기"
  },
  {
    "value" : "42321504",
    "label" : "골수내금속못"
  },
  {
    "value" : "42321505",
    "label" : "골절합용판"
  },
  {
    "value" : "42321707",
    "label" : "고관절치환키트또는시스템"
  },
  {
    "value" : "42321808",
    "label" : "무릎치환키트또는시스템"
  },
  {
    "value" : "42321903",
    "label" : "상완골스템"
  },
  {
    "value" : "42322001",
    "label" : "발목관절임플란트"
  },
  {
    "value" : "42121501",
    "label" : "동물용혈압계"
  },
  {
    "value" : "42121506",
    "label" : "동물용수술기구세트"
  },
  {
    "value" : "42121589",
    "label" : "동물용전기자극기"
  },
  {
    "value" : "42121590",
    "label" : "동물용식별칩주입기"
  },
  {
    "value" : "42121592",
    "label" : "정액보존용기"
  },
  {
    "value" : "42121605",
    "label" : "동물용심장혈관계제품"
  },
  {
    "value" : "42121607",
    "label" : "동물용비뇨생식기또는호르몬제품"
  },
  {
    "value" : "42121701",
    "label" : "동물용수술대"
  },
  {
    "value" : "42121801",
    "label" : "인공수정기"
  },
  {
    "value" : "42121802",
    "label" : "정자채취기"
  },
  {
    "value" : "42121803",
    "label" : "전기사정자극기"
  },
  {
    "value" : "42121804",
    "label" : "정액포장기"
  },
  {
    "value" : "42131501",
    "label" : "환자턱받이"
  },
  {
    "value" : "42131503",
    "label" : "환자검진복"
  },
  {
    "value" : "42131504",
    "label" : "환자복"
  },
//    -------------------- 의약품 시작
    {
    "value" : "51260101",
    "label" : "전신마취제"
  },
  {
    "value" : "51260102",
    "label" : "최면진정제"
  },
  {
    "value" : "51260103",
    "label" : "항전간제"
  },
  {
    "value" : "51260104",
    "label" : "해열,진통,소염제"
  },
  {
    "value" : "51260106",
    "label" : "진훈제"
  },
  {
    "value" : "51260107",
    "label" : "정신신경용제"
  },
  {
    "value" : "51260108",
    "label" : "기타중추신경용약"
  },
  {
    "value" : "51260201",
    "label" : "국소마취제"
  },
  {
    "value" : "51260202",
    "label" : "골격근이완제"
  },
  {
    "value" : "51260204",
    "label" : "진경제"
  },
  {
    "value" : "51260301",
    "label" : "안과용제"
  },
  {
    "value" : "51260302",
    "label" : "이비과용제"
  },
  {
    "value" : "51260303",
    "label" : "기타감각기관용약"
  },
  {
    "value" : "51260401",
    "label" : "항히스타민제"
  },
  {
    "value" : "51260403",
    "label" : "기타알레르기용약"
  },
  {
    "value" : "51260501",
    "label" : "안티푸라민한방파스"
  },
  {
    "value" : "5127",
    "label" : "개개의기관계용의약품"
  },
  {
    "value" : "51270101",
    "label" : "강심제"
  },
  {
    "value" : "51270102",
    "label" : "부정맥용제"
  },
  {
    "value" : "51270103",
    "label" : "이뇨제"
  },
  {
    "value" : "51270104",
    "label" : "혈압강하제"
  },
  {
    "value" : "51270105",
    "label" : "혈관보강제"
  },
  {
    "value" : "51270107",
    "label" : "혈관확장제"
  },
  {
    "value" : "51270108",
    "label" : "동맥경화용제"
  },
  {
    "value" : "51270109",
    "label" : "기타순환계용약"
  },
  {
    "value" : "51270202",
    "label" : "진해거담제"
  },
  {
    "value" : "51270203",
    "label" : "함소흡입제"
  },
  {
    "value" : "51270204",
    "label" : "기타호흡기관용약"
  },
  {
    "value" : "51270301",
    "label" : "치과구강용약"
  },
  {
    "value" : "51270302",
    "label" : "소화성궤양용제"
  },
  {
    "value" : "51270303",
    "label" : "건위소화제"
  },
  {
    "value" : "51270304",
    "label" : "제산제"
  },
  {
    "value" : "51270305",
    "label" : "최토제,진토제"
  },
  {
    "value" : "51270307",
    "label" : "정장제"
  },
  {
    "value" : "51270308",
    "label" : "하제,완장제"
  },
  {
    "value" : "51270309",
    "label" : "기타소화기관용약"
  },
  {
    "value" : "51270401",
    "label" : "뇌하수체호르몬제"
  },
  {
    "value" : "51270403",
    "label" : "갑상선,부갑상선호르몬제"
  },
  {
    "value" : "51270405",
    "label" : "부신호르몬제"
  },
  {
    "value" : "51270407",
    "label" : "난포호르몬제및황체호르몬제"
  },
  {
    "value" : "51270408",
    "label" : "혼합호르몬제"
  },
  {
    "value" : "51270409",
    "label" : "기타호르몬제,기타호르몬제(항호르몬제를포함),Feral Pig Oil"
  },
  {
    "value" : "51270501",
    "label" : "요로소독제"
  },
  {
    "value" : "51270503",
    "label" : "통경제"
  },
  {
    "value" : "51270505",
    "label" : "비뇨생식기관용제(성병예방제포함)"
  },
  {
    "value" : "51270506",
    "label" : "치질용제"
  },
  {
    "value" : "51270507",
    "label" : "기타비뇨생식기관및항문용약"
  },
  {
    "value" : "51270601",
    "label" : "외피용살균소독제"
  },
  {
    "value" : "51270602",
    "label" : "창상보호제"
  },
  {
    "value" : "51270603",
    "label" : "화농성질환용제"
  },
  {
    "value" : "51270604",
    "label" : "케토크린,진통,진양,수렴,소염제"
  },
  {
    "value" : "51270605",
    "label" : "기생성피부질환용제"
  },
  {
    "value" : "51270606",
    "label" : "피부연화제(부식제를포함)"
  },
  {
    "value" : "51270609",
    "label" : "기타외피용약"
  },
  {
    "value" : "51270610",
    "label" : "살균소독수"
  },
  {
    "value" : "512707",
    "label" : "기타의개개의기관용의약품"
  },
  {
    "value" : "51270701",
    "label" : "마이암부톨 400mg,기타개개기관용의약품,멀티락신바이오틱"
  },
  {
    "value" : "51280101",
    "label" : "비타민A및D제"
  },
  {
    "value" : "51280102",
    "label" : "비타민B1제,HMC3(ATCC CRL-3304) 등 15종"
  },
  {
    "value" : "51280103",
    "label" : "신일피리독신정,비타민B제(비타민B1을제외)"
  },
  {
    "value" : "51280104",
    "label" : "비타민C및P제"
  },
  {
    "value" : "51280106",
    "label" : "혼합비타민제(비타민A,D혼합제제를제외)"
  },
  {
    "value" : "51280107",
    "label" : "기타비타민제"
  },
  {
    "value" : "51280201",
    "label" : "칼슘제"
  },
  {
    "value" : "51280202",
    "label" : "무기질제제"
  },
  {
    "value" : "51280203",
    "label" : "당류제"
  },
  {
    "value" : "51280204",
    "label" : "유기산제제"
  },
  {
    "value" : "51280205",
    "label" : "단백아미노산제제"
  },
  {
    "value" : "51280207",
    "label" : "유유아용제"
  },
  {
    "value" : "51280208",
    "label" : "기타자양강장변질제"
  },
  {
    "value" : "51280301",
    "label" : "혈액대용제"
  },
  {
    "value" : "51280302",
    "label" : "지혈제"
  },
  {
    "value" : "51280303",
    "label" : "혈액응고저지제"
  },
  {
    "value" : "51280304",
    "label" : "기타혈액및체액용약"
  },
  {
    "value" : "51280401",
    "label" : "인공신장관류용제"
  },
  {
    "value" : "51280402",
    "label" : "기타인공관류용제"
  },
  {
    "value" : "51280501",
    "label" : "간장질환용제"
  },
  {
    "value" : "51280502",
    "label" : "해독제"
  },
  {
    "value" : "51280505",
    "label" : "효소제제"
  },
  {
    "value" : "51280506",
    "label" : "당뇨병용제"
  },
  {
    "value" : "51280507",
    "label" : "종합대사성제제"
  },
  {
    "value" : "51280508",
    "label" : "따로분류되지않은대사성의약품,한방파스"
  },
  {
    "value" : "51290102",
    "label" : "색소제제"
  },
  {
    "value" : "51290202",
    "label" : "기타종양치료제"
  },
  {
    "value" : "51290301",
    "label" : "방사성의약품"
  },
  {
    "value" : "51290401",
    "label" : "기타조직세포기관용의약품"
  },
  {
    "value" : "51310101",
    "label" : "주로그람양성균에작용하는것"
  },
  {
    "value" : "51310102",
    "label" : "주로그람음성균에작용하는것"
  },
  {
    "value" : "51310103",
    "label" : "항산성균용항생제"
  },
  {
    "value" : "51310104",
    "label" : "주로그람양성균,리케치아,비루스에작용하는것"
  },
  {
    "value" : "51310105",
    "label" : "주로그람양성,음성균,리케치아,비루스에작용하는것"
  },
  {
    "value" : "51310106",
    "label" : "주로곰팡이및원충에작용하는것"
  },
  {
    "value" : "51310108",
    "label" : "주로그람양성,음성균에작용하는것"
  },
  {
    "value" : "51310109",
    "label" : "기타항생물질제제(복합항생물질제제포함)"
  },
  {
    "value" : "51310201",
    "label" : "설파제"
  },
  {
    "value" : "51310202",
    "label" : "튜비스투정,항결핵제,리팜핀 600mg"
  },
  {
    "value" : "51310203",
    "label" : "치나제"
  },
  {
    "value" : "51310204",
    "label" : "구매제"
  },
  {
    "value" : "51310206",
    "label" : "기타화학요법제"
  },
  {
    "value" : "51310301",
    "label" : "백신"
  },
  {
    "value" : "51310302",
    "label" : "독소류및톡소이드류"
  },
  {
    "value" : "51310304",
    "label" : "혈액제제류"
  },
  {
    "value" : "51310305",
    "label" : "생물학적시험용제제류"
  },
  {
    "value" : "51310306",
    "label" : "생물학적제제"
  },
  {
    "value" : "51310307",
    "label" : "기타생물학적제제"
  },
  {
    "value" : "51310401",
    "label" : "항원충제"
  },
  {
    "value" : "51310402",
    "label" : "구충제"
  },
  {
    "value" : "51310403",
    "label" : "항기생동물성약품"
  },
  {
    "value" : "51310501",
    "label" : "기타항병원생물성약품"
  },
  {
    "value" : "51320101",
    "label" : "부형제"
  },
  {
    "value" : "51320102",
    "label" : "후시딘,연고기제"
  },
  {
    "value" : "51320103",
    "label" : "용해제,요석 용해제 등 3종"
  },
  {
    "value" : "51320104",
    "label" : "교미교취착색제"
  },
  {
    "value" : "51320105",
    "label" : "유화제"
  },
  {
    "value" : "51320106",
    "label" : "SI-110(경화촉매)"
  },
  {
    "value" : "513202",
    "label" : "진단용액"
  },
  {
    "value" : "51320201",
    "label" : "엑스선조영제"
  },
  {
    "value" : "51320202",
    "label" : "Thioglycolic acid"
  },
  {
    "value" : "51320203",
    "label" : "혈액검사용시약"
  },
  {
    "value" : "51320204",
    "label" : "생화학적검사용시약"
  },
  {
    "value" : "51320205",
    "label" : "면역혈청학적검사용시약"
  },
  {
    "value" : "51320206",
    "label" : "세균학적검사용제"
  },
  {
    "value" : "51320207",
    "label" : "병리조직검사용시약"
  },
  {
    "value" : "51320208",
    "label" : "기능검사용시약"
  },
  {
    "value" : "51320209",
    "label" : "Rotary Joint"
  },
  {
    "value" : "51320210",
    "label" : "체외진단검사지"
  },
  {
    "value" : "51320211",
    "label" : "분자유전검사용시약"
  },
  {
    "value" : "51320212",
    "label" : "Gel Red(Biotium, 41003)"
  },
  {
    "value" : "51320301",
    "label" : "의료용방부제"
  },
  {
    "value" : "51320302",
    "label" : "의료용살균소독제"
  },
  {
    "value" : "51320303",
    "label" : "의료용살충제"
  },
  {
    "value" : "51320304",
    "label" : "기타공중위생용약"
  },
  {
    "value" : "513204",
    "label" : "기타의치료를주목적으로하지않는의약품"
  },
  {
    "value" : "51320401",
    "label" : "Trimethylolpropane tris(3-mercaptopropionate)"
  },
  {
    "value" : "51330201",
    "label" : "합성마약"
  },
  {
    "value" : "51330301",
    "label" : "기타마약"
  }
];



export { itemYearOption, itemClsfcNo };
