import React from "react";
import rankHelpImg from "../../img/howtouse/rank.png";
import styles from "./help.module.css";

const RankHelpPopup = () => (
  <div className={styles.helpWindow}>
    <img className={styles.data_img} src={rankHelpImg} alt="" />
  </div>
);

export default RankHelpPopup;
