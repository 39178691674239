const apiResponse = async (openlabApi, params, token) => {
  let response = {};
  switch (params.name) {
    case "trendNormal":
      response = await openlabApi.patientsApi(params, token);
      break;
    case "trendYkiho":
      response = await openlabApi.patientsYkihoApi(params, token);
      break;
    case "rankNormal":
      response = await openlabApi.patientsRankApi(params, token);
      break;
    case "rankYkiho":
      response = await openlabApi.patientsRankYkihoApi(params, token);
      break;
    case "trendDetail":
      response = await openlabApi.patientsTrendDetailApi(params, token);
      break;
    case "searchRawData":
      response = await openlabApi.patientsDataSearchApi(params, token);
      break;
    case "disease":
      response = await openlabApi.diseaseTreatApi(params, token);
      break;
    case "treat":
      response = await openlabApi.treatDiseaseApi(params, token);
      break;
    default:
      console.error("error occur");
  }

  return response;
};

export { apiResponse };
