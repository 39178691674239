import React, { useRef } from "react";
import { AgGridColumn, AgGridReact } from "ag-grid-react";

import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";

const ClsfcTable = ({ rowData, genDetailTable }) => {
  const gridRef = useRef();

  const onSelectionChanged = (event) => {
    const selectedDataNode = event.api.getSelectedNodes();
    const selectedRowData = selectedDataNode[0].data;
    genDetailTable(selectedRowData);
  };

  const sizeToFit = (event) => {
    const gridApi = event.api;
    gridApi.sizeColumnsToFit();
  };

  return (
    <>
      <div
        className="ag-theme-alpine"
        style={{ height: "100%", width: "100%" }}
      >
        <AgGridReact
          rowData={rowData}
          ref={gridRef}
          rowSelection={"single"}
          onSelectionChanged={onSelectionChanged}
          onGridReady={sizeToFit}
        >
          <AgGridColumn
            field="물품분류명"
            sortable={true}
            minWidth={250}
            maxWidth={400}
          ></AgGridColumn>
          <AgGridColumn
            field="계약금액"
            sortable={true}
            suppressSizeToFit={true}
            minWidth={150}
            maxWidth={200}
          ></AgGridColumn>
          <AgGridColumn
            field="수량"
            sortable={true}
            suppressSizeToFit={true}
            minWidth={80}
            maxWidth={100}
          ></AgGridColumn>
        </AgGridReact>
      </div>
    </>
  );
};

export default ClsfcTable;
