import React from "react";
import DataTable from "./tables/dataTable";
import PatientsDetailChart from "./charts/patientsDetailChart";
import PatientsRankChart from "./charts/patientsRankChart";
import TrendTable from "./tables/trendTable";
import PatientsChart from "./charts/patientsChart";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styles from "./patientsStat.module.css";
import DownloadForOfflineIcon from "@mui/icons-material/DownloadForOffline";

const RankChartsTables = ({
  rankPatientsData,
  trendChartData,
  trendTableData,
  trendSearchRawData,
  tableInfo,
  onUpdateDetailParams,
  selectedRadio,
  updateClickedRankChartInfo,
  onClickRankChart,
  onClickTrendChart,
  onClickTrendTable,
  onClickDetailTable,
  rankChartDownload,
  trendChartDownload,
  trendTableDownload,
  detailTableDownload,
}) => {
  return (
    <>
      <div className={styles.contentBoxes}>
        <div className={styles.contentBox}>
          <div className={styles.contentBox__head}>
            <h4>기간내 다빈도질병 순위(click)</h4>
            <DownloadForOfflineIcon
              style={{ marginRight: "10px", cursor: "pointer" }}
              onClick={onClickRankChart}
            />
          </div>
          <div className={styles.content}>
            {rankPatientsData.length ? (
              <PatientsRankChart
                rankPatientsData={rankPatientsData}
                updateClickedRankChartInfo={updateClickedRankChartInfo}
                downloadTF={rankChartDownload}
              />
            ) : (
              <h5>데이터를 선택해주세요</h5>
            )}
          </div>
        </div>

        <div className={styles.contentBox}>
          <div className={styles.contentBox__head}>
            <h4>선택질병 경향분석(차트) </h4>
            <DownloadForOfflineIcon
              style={{ marginRight: "10px", cursor: "pointer" }}
              onClick={onClickTrendChart}
            />
          </div>
          <div className={styles.content}>
            {trendChartData.length ? (
              <PatientsChart
                trendChartData={trendChartData}
                selectedRadio={selectedRadio}
                downloadTF={trendChartDownload}
              />
            ) : (
              <h5>데이터를 선택해주세요</h5>
            )}
          </div>
        </div>

        <div className={styles.contentBox}>
          <div className={styles.contentBox__head}>
            <h4>선택질병 경향분석(click)</h4>
            <DownloadForOfflineIcon
              style={{ marginRight: "10px", cursor: "pointer" }}
              onClick={onClickTrendTable}
            />
          </div>
          <TrendTable
            rowData={trendTableData}
            onSelectRow={onUpdateDetailParams}
            tableInfo={tableInfo}
            downloadTF={trendTableDownload}
          />
        </div>

        <div className={styles.contentBox}>
          <div className={styles.contentBox__head}>
            <h4>세부데이터</h4>
            {/* <button className="download" onClick={onClickDetailTable}>
              <FontAwesomeIcon
                icon={"download"}
                style={{
                  width: "20px",
                  height: "20px",
                  color: "yellowgreen",
                }}
              />
            </button> */}
          </div>
          <DataTable
            rowData={trendSearchRawData}
            tableInfo={tableInfo}
            downloadTF={detailTableDownload}
          />
        </div>
      </div>
    </>
  );
};

export default RankChartsTables;
