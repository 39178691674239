import React from "react";
import itemHelpImg from "../../img/howtouse/item.png";
import styles from "./help.module.css";

const ItemHelpPopup = () => (
  <div className={styles.helpWindow}>
    <img className={styles.data_img} src={itemHelpImg} alt="" />
  </div>
);

export default ItemHelpPopup;
