import { library } from "@fortawesome/fontawesome-svg-core";

import {
  faSearch,
  faDownload,
  faFileDownload,
} from "@fortawesome/free-solid-svg-icons";

import { dom } from "@fortawesome/fontawesome-svg-core";

library.add(faSearch, faDownload, faFileDownload);

dom.watch();
