import * as React from "react";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import BaseLayout from "../layout/BaseLayout";
import {
  Button,
  Divider,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
} from "@mui/material";
import { blueGrey } from "@mui/material/colors";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../../context/UserContext";

const MyInfo = () => {
  const navigate = useNavigate();

  const [token, , staff, , , username, companyName, email] =
    React.useContext(UserContext);

  return (
    <BaseLayout>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          minHeight: "100vh",
        }}
      >
        <CssBaseline />
        <Container component="main" sx={{ mt: 8, mb: 2 }} maxWidth="sm">
          <Typography variant="h2" component="h1" gutterBottom>
            내 정보
          </Typography>
          <List
            sx={{ width: "100%", maxWidth: 360, bgcolor: "background.paper" }}
          >
            <ListItem>
              <ListItemText primary="이메일" secondary={email} />
            </ListItem>
            <ListItem>
              <ListItemText primary="이름" secondary={username} />
            </ListItem>
            <ListItem>
              <ListItemText primary="회사명" secondary={companyName} />
            </ListItem>
            <Divider />
            <ListItem disablePadding sx={{ bgcolor: blueGrey[100] }}>
              <ListItemButton
                component="a"
                onClick={() => navigate("/my_info/check_pw")}
              >
                <ListItemText primary="비밀번호 변경" />
              </ListItemButton>
            </ListItem>
          </List>
        </Container>
      </Box>
    </BaseLayout>
  );
};

export default MyInfo;
