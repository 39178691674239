import React, { useState, useRef } from "react";
import Select from "react-select";
import styles from "./diseaseTreatForm.module.css";
import { treatDiseaseOptions } from "../../../common/variables";

const TreatDiseaseForm = ({ upDateApiParams }) => {
  const [treat, setTreat] = useState("");
  const formRef = useRef();
  const treatRef = useRef();
  const onTreatChange = (option) => setTreat(option);

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      fontWeight: state.isSelected ? "bold" : "normal",
      color: "black",
      padding: 20,
      fontSize: state.selectProps.myFontSize,
    }),
    singleValue: (provided, state) => ({
      ...provided,
      fontSize: state.selectProps.myFontSize,
    }),
    // control: () => ({ width: "200px" }),
  };

  const onSubmit = (event) => {
    event.preventDefault();
    const inputOptions = {
      treat_cd: treat.value || "",
    };
    upDateApiParams(inputOptions);
  };

  return (
    <>
      <form ref={formRef} className={styles.form}>
        <div className={styles.selectionWrappers}>
          <div className={styles.selectionRow}>
            <div className={styles.selection_item_container}>
              <div className={styles.option_name}>*진료처치</div>
              <div className={styles.select_box}>
                <Select //
                  options={treatDiseaseOptions}
                  placeholder={"진료처치"}
                  ref={treatRef}
                  onChange={onTreatChange}
                  value={treat}
                  isSearchable={true}
                  styles={customStyles}
                />
              </div>
            </div>
          </div>
        </div>

        <button className={styles.submit_btn} onClick={onSubmit}>
          조회
        </button>
      </form>
    </>
  );
};

export default TreatDiseaseForm;
