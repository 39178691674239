// import * as Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import exporting from "highcharts/modules/exporting";
import sunburst from "highcharts/modules/sunburst.js";
import Highcharts from "highcharts/highstock"; // 차후에 추가
// exporting(Highcharts); // 제거
sunburst(Highcharts);

const SunburstChart = ({ options }) => {
  console.log(options);
  return (
    <div>
      <HighchartsReact highcharts={Highcharts} options={options} />
    </div>
  );
};

export default SunburstChart;
