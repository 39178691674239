import axios from "axios";

class OpenlabApi {
  constructor(baseURL) {
    this.api = axios.create({
      baseURL: baseURL,
    });
  }

  async jsonApi(appendUrl, data, token) {
    const response = await this.api.post(appendUrl, data, {
      headers: {
        "Content-Type": "application/json",
        // "Access-Control-Allow-Origin": "*",
        Authorization: "Bearer " + token,
      },
    });

    return response;
  }

  async patientsApi(data, token) {
    const url = "aps_detail_info/trend/disease";
    return this.jsonApi(url, data, token);
  }
  async patientsYkihoApi(data, token) {
    const url = "aps_detail_info/trend/ykiho";
    return this.jsonApi(url, data, token);
  }

  async patientsTrendDetailApi(data, token) {
    const url = "aps_detail_info/detail/disease";
    return this.jsonApi(url, data, token);
  }

  async patientsRankApi(data, token) {
    const url = "aps_detail_info/rank/disease";
    return this.jsonApi(url, data, token);
  }

  async patientsRankYkihoApi(data, token) {
    const url = "aps_detail_info/rank/ykiho";
    return this.jsonApi(url, data, token);
  }

  async patientsDetailForCodeApi(data, token) {
    const url = "aps_detail_info/detail/disease";
    return this.jsonApi(url, data, token);
  }

  async patientsDataSearchApi(data, token) {
    const url = "aps_detail_info/data";
    return this.jsonApi(url, data, token);
  }

  //   물품 분류 기준 통계
  async itemClsfcStat(data, token) {
    const url = "nara_info/item/prdctCls_stat";
    return this.jsonApi(url, data, token);
  }

  async itemClsfcTrend(data, token) {
    const url = "nara_info/item/prdctCls_trend";
    return this.jsonApi(url, data, token);
  }

  async itemClsfcBuyer(data, token) {
    const url = "nara_info/item/prdctCls_buyer";
    return this.jsonApi(url, data, token);
  }

  async itemClsfcDetail(data, token) {
    const url = "nara_info/item/prdctCls_detail";
    return this.jsonApi(url, data, token);
  }

  async itemDetailStat(data, token) {
    const url = "/nara_info/detail/prdct_detail_stat";
    return this.jsonApi(url, data, token);
  }

  async itemDetailBuyer(data, token) {
    const url = "/nara_info/detail/prdct_detail_buyer";
    return this.jsonApi(url, data, token);
  }

  async itemDetailTrend(data, token) {
    const url = "/nara_info/detail/prdct_detail_trend";
    return this.jsonApi(url, data, token);
  }

  // 질병-치료 차트 데이터

  async diseaseTreatApi(data, token) {
    const url = "/sunburst/disease";
    return this.jsonApi(url, data, token);
  }

  async treatDiseaseApi(data, token) {
    const url = "/sunburst/treat";
    return this.jsonApi(url, data, token);
  }
}

export default OpenlabApi;
