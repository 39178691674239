import React from "react";
import trendHelpImg from "../../img/howtouse/trend.png";
import styles from "./help.module.css";

const TrendHelpPopup = () => (
  <div className={styles.helpWindow}>
    <img className={styles.data_img} src={trendHelpImg} alt="" />
  </div>
);

export default TrendHelpPopup;
