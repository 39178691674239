import React from "react";
import PatientsChart from "./charts/patientsChart";
import TrendTable from "./tables/trendTable";
import DataTable from "./tables/dataTable";
import PatientsDetailChart from "./charts/patientsDetailChart";
import styles from "./patientsStat.module.css";
import DownloadForOfflineIcon from "@mui/icons-material/DownloadForOffline";

const TrendChartsTables = ({
  trendChartData,
  trendTableData,
  trendDetailData,
  trendSearchRawData,
  selectedRadio,
  onUpdateDetailParams,
  tableInfo,
  onTrendDownload1,
  onTrendDownload2,
  onTrendDownload3,
  onTrendDownload4,
  trendDownload1,
  trendDownload2,
  trendDownload3,
  trendDownload4,
}) => {
  return (
    <>
      <div className={styles.contentBoxes}>
        <div className={styles.contentBox}>
          <div className={styles.contentBox__head}>
            <h4>선택질병 경향분석(차트)</h4>
            <DownloadForOfflineIcon
              style={{ marginRight: "10px", cursor: "pointer" }}
              onClick={onTrendDownload1}
            />
          </div>

          <div className={styles.content}>
            {trendChartData.length ? (
              <PatientsChart
                trendChartData={trendChartData}
                selectedRadio={selectedRadio}
                downloadTF={trendDownload1}
              />
            ) : (
              <h5>데이터를 선택해주세요</h5>
            )}
          </div>
        </div>

        <div className={styles.contentBox}>
          <div className={styles.contentBox__head}>
            <h4>선택질병 경향분석(click) </h4>
            <DownloadForOfflineIcon
              style={{ marginRight: "10px", cursor: "pointer" }}
              onClick={onTrendDownload2}
            />
          </div>

          <div className={styles.content}>
            <TrendTable
              rowData={trendTableData}
              onSelectRow={onUpdateDetailParams}
              tableInfo={tableInfo}
              downloadTF={trendDownload2}
            />
          </div>
        </div>

        <div className={styles.contentBox}>
          <div className={styles.contentBox__head}>
            <h4>선택분석 세부차트</h4>
            <DownloadForOfflineIcon
              style={{ marginRight: "10px", cursor: "pointer" }}
              onClick={onTrendDownload3}
            />
          </div>

          <div className={styles.content}>
            {trendDetailData.length ? (
              <PatientsDetailChart
                trendDetailData={trendDetailData}
                selectedRadio={selectedRadio}
                downloadTF={trendDownload3}
              />
            ) : (
              <h5>테이블에서 행을 선택해주세요</h5>
            )}
          </div>
        </div>

        <div className={styles.contentBox}>
          <div className={styles.contentBox__head}>
            <h4>세부데이터</h4>
            {/* <button className="download" onClick={onTrendDownload4}>
              <FontAwesomeIcon
                icon={"download"}
                style={{
                  width: "20px",
                  height: "20px",
                  color: "yellowgreen",
                }}
              />
            </button> */}
          </div>

          <DataTable
            rowData={trendSearchRawData}
            tableInfo={tableInfo}
            downloadTF={trendDownload4}
          />
        </div>
      </div>
    </>
  );
};

export default TrendChartsTables;
