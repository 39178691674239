import { ResponsiveBar } from "@nivo/bar";
import styles from "./itemBarChart.module.css";
import { createRef, useEffect, useState } from "react";
import { useScreenshot, createFileName } from "use-react-screenshot";

const BarChart = ({ data /* see data tab */ }) => (
  <ResponsiveBar
    data={data}
    keys={["계약금액", "수량", "계약건수"]}
    indexBy="yyyymm"
    margin={{ top: 50, right: 130, bottom: 50, left: 60 }}
    padding={0.3}
    // width={450}
    // height={200}
    // groupMode="grouped"
    valueScale={{ type: "linear" }}
    indexScale={{ type: "band", round: true }}
    colors={{ scheme: "nivo" }}
    animate={false}
    defs={[
      {
        id: "dots",
        type: "patternDots",
        background: "inherit",
        color: "#38bcb2",
        size: 4,
        padding: 1,
        stagger: true,
      },
      {
        id: "lines",
        type: "patternLines",
        background: "inherit",
        color: "#eed312",
        rotation: -45,
        lineWidth: 6,
        spacing: 10,
      },
    ]}
    fill={[
      {
        match: {
          id: "fries",
        },
        id: "dots",
      },
      {
        match: {
          id: "sandwich",
        },
        id: "lines",
      },
    ]}
    borderColor={{ from: "color", modifiers: [["darker", 1.6]] }}
    axisTop={null}
    axisRight={null}
    axisBottom={{
      tickSize: 5,
      tickPadding: 5,
      tickRotation: 0,
      legend: "년월",
      legendPosition: "middle",
      legendOffset: 32,
    }}
    axisLeft={{
      tickSize: 5,
      tickPadding: 5,
      tickRotation: 0,
      legend: "",
      legendPosition: "middle",
      legendOffset: -40,
      format: (x) => {
        return x > 1000000
          ? `${x / 1000000}백만`
          : x > 10000
          ? `${x / 10000}만`
          : `${x}`;
      },
    }}
    labelSkipWidth={12}
    labelSkipHeight={12}
    labelTextColor={{ from: "color", modifiers: [["darker", 1.6]] }}
    legends={[
      {
        dataFrom: "keys",
        anchor: "bottom-right",
        direction: "column",
        justify: false,
        translateX: 120,
        translateY: 0,
        itemsSpacing: 2,
        itemWidth: 100,
        itemHeight: 20,
        itemDirection: "left-to-right",
        itemOpacity: 0.85,
        symbolSize: 20,
        effects: [
          {
            on: "hover",
            style: {
              itemOpacity: 1,
            },
          },
        ],
      },
    ]}
    role="application"
    ariaLabel="Nivo bar chart demo"
    barAriaLabel={function (e) {
      return e.id + ": " + e.formattedValue + " in country: " + e.indexValue;
    }}
  />
);

const ItemBarChart = ({ barChartData, downloadTF }) => {
  console.log(Object.keys(barChartData).length);

  const chartRef = createRef();
  const [image, takeScreenShot] = useScreenshot({
    type: "image/jpeg",
    quality: 1.0,
  });
  const [downloadCheck, setDownloadCheck] = useState(false);

  const download = (image, { name = "img", extension = "png" } = {}) => {
    const a = document.createElement("a");
    a.href = image;
    a.download = createFileName(extension, name);
    a.click();
  };

  useEffect(() => {
    downloadCheck && takeScreenShot(chartRef.current).then(download);
  }, [downloadCheck]);

  if (downloadCheck !== downloadTF) {
    setDownloadCheck(downloadTF);
  }

  return (
    <section>
      <div className={styles.Chart} ref={chartRef}>
        {Object.keys(barChartData).length === 0 ? (
          <div className={styles.emptyChart}>
            <h3
              style={{
                width: "75vh",
                justifyContent: "center",
                display: "flex",
                alignItems: "center",
                height: "35vh",
              }}
            >
              데이터를 선택해주세요
            </h3>
          </div>
        ) : (
          <BarChart data={barChartData} />
        )}
      </div>
    </section>
  );
};

export default ItemBarChart;
