import React, { useState } from "react";

const DiseaseTreatRadio = ({ updateRadioValue }) => {
  const [dataValue, setDataValue] = useState("disease");
  const chartValueRadio = (event) => {
    const targetName = event.target.value;
    setDataValue(targetName);
    updateRadioValue(targetName);
  };

  return (
    <>
      <ul style={{ margin: "0px", padding: "7px" }}>
        <li>
          <label>
            <input
              type="radio"
              value="disease"
              name="질병기준"
              onChange={chartValueRadio}
              checked={dataValue === "disease"}
            />
            <span>질병기준</span>
          </label>
        </li>
        <li>
          <label>
            <input
              type="radio"
              value="treat"
              name="yvalue"
              onChange={chartValueRadio}
              checked={dataValue === "treat"}
            />
            <span>처치기준</span>
          </label>
        </li>
      </ul>
    </>
  );
};

export default DiseaseTreatRadio;
