import React, { useState, useEffect, useContext } from "react";
import styles from "./diseaseTreat.module.css";

import { apiResponse } from "../../service/data_propagation";

import DiseaseTreatForm from "./forms/diseaseTreatForm";
import DiseaseTreatRadio from "./forms/disaseTreatRadio";
import TreatDiseaseForm from "./forms/treatDiseaseForm";
import SunburstChart from "../highcharts/sunburst";

import {
  getSunburst2LayerOptions,
  getSunburst3LayerOptions,
} from "./data/highChartOptions.jsx";
import { UserContext } from "../../context/UserContext";
import BaseLayout from "../layout/BaseLayout";

const DiseaseTreat = ({ openlabApi }) => {
  const [selectedRadio, setSelectedRadio] = useState("disease");
  const [highChartOptions, setHighChartOptions] = useState({});
  const [token] = useContext(UserContext);

  const loadDiseaseTreatData = async (inputOptions) => {
    setHighChartOptions({});
    const params = { ...inputOptions, name: selectedRadio };
    const response = await apiResponse(openlabApi, params, token);
    const data = response.data;

    if (selectedRadio === "disease") {
      const sunburstOptions = getSunburst3LayerOptions(data);

      setHighChartOptions(sunburstOptions);
    } else {
      const sunburstOptions = getSunburst2LayerOptions(data);
      setHighChartOptions(sunburstOptions);
    }
  };

  const updateRadioValue = (option) => {
    setSelectedRadio(option);
  };

  useEffect(() => {
    setHighChartOptions({});
  }, [selectedRadio]);

  return (
    <BaseLayout>
      <div className={styles.banner__title}>
        <h1>질병 - 진료행위 분석 </h1>
      </div>

      <div className={styles.container}>
        <div className={styles.inputOptions}>
          <div className={styles.value_radio}>
            <h5>기준값 선택</h5>
            <DiseaseTreatRadio updateRadioValue={updateRadioValue} />
          </div>
          {selectedRadio === "disease" && (
            <DiseaseTreatForm upDateApiParams={loadDiseaseTreatData} />
          )}
          {selectedRadio === "treat" && (
            <TreatDiseaseForm upDateApiParams={loadDiseaseTreatData} />
          )}
        </div>
        {/* {console.log("data", highChartOptions)} */}
        <section className={styles.chartTables}>
          {Object.keys(highChartOptions).length === 0 ? (
            <div className={styles.emptyChart}>
              <h3 style={{ textAlign: "center" }}>데이터를 선택해주세요</h3>
            </div>
          ) : (
            <SunburstChart options={highChartOptions} />
          )}
        </section>
      </div>
    </BaseLayout>
  );
};

export default DiseaseTreat;
