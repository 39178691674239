import React from "react";
import styles from "./footer.module.css";
import footerbg from "../../img/footerlogo.png";

const Footer = (props) => {
  return (
    <footer>
      <div className={styles.footer1}>
        <div
          className={styles.footerInner}
          style={{ backgroundImage: "url(" + footerbg + ")" }}
        >
          <p className={styles.copyright}>
            (24047) 강원도 춘천시 신북읍 신북로 61-10 강원테크노파크
            기술혁신지원센터
            <br />
            전화번호 : 033-248-5600<span>&nbsp;&nbsp;</span>
            <br />
            Copyright © 강원테크노파크. All Rights Reserved.
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
