import React from "react";
import styles from "./bizSup.module.css";
import intro_img from "../../img/support/sup_intro.jpeg";
import BizSupProcess from "./bizSupProcess";
import BaseLayout from "../layout/BaseLayout";

const BizSup = (props) => (
  <BaseLayout>
    <div className={styles.banner}>
      <h1 className={styles.banner__title}>기업분석지원</h1>
    </div>

    <section className={styles.section}>
      <div className={styles.container}>
        <div clasName={styles.content}>
          <div className={styles.content_title}>
            <h2>심평원 의료빅데이터 센터 활용 지원</h2>
          </div>

          <div className={styles.content_box}>
            <div className={styles.content_img_box}>
              <img className={styles.content_img} src={intro_img} alt="" />
            </div>

            <div className={styles.content_elements}>
              <div className={styles.content_element}>
                <div className={styles.content_number}>01</div>
                <div className={styles.content_sep}></div>
                <div className={styles.content_text}>
                  <p>
                    기업의 관심 상병/의약품/의료기기 분석을 위해
                    보건의료빅데이터센터 활용을 분석을 수행하고 분석결과 및
                    데이터 시각화 지원
                  </p>
                </div>
              </div>

              <div className={styles.content_element}>
                <div className={styles.content_number}>02</div>
                <div className={styles.content_sep}></div>
                <div className={styles.content_text}>
                  <p>
                    보건의료빅데이터센터 데이터 분석수행을 통계프로그램
                    활용방법, 제공변수들의 종류와 관계에 대한 데이터에 관한 정보
                    지원
                  </p>
                </div>
              </div>

              <div className={styles.content_element}>
                <div className={styles.content_number}>03</div>
                <div className={styles.content_sep}></div>
                <div className={styles.content_text}>
                  <p>
                    보건의료빅데이터 시스템 활용에 필요한 행정적 절차 불편 해소
                    지원
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <BizSupProcess />
      </div>
    </section>
  </BaseLayout>
);

export default BizSup;
