import React, { useState, useEffect, useContext } from "react";
import styles from "./patientsStat.module.css";

import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";

import patientsTrendYkihoInput from "./patientsTrendYkihoInput";
import patientsRankYkihoInput from "./patientsRankYkihoInput";
import patientsTrendInput from "./patientsTrendInput";
import patientsRankInput from "./patientsRankInput";

import { inputOptionToApiParams } from "../../service/data_manipulation";
import { apiResponse } from "../../service/data_propagation";
import TrendChartsTables from "./trendChartsTables";
import RankChartsTables from "./rankChartsTables";

import onHelpPopup from "./openPopup";
import { UserContext } from "../../context/UserContext";
import BaseLayout from "../layout/BaseLayout";

const NewPatientsState = ({ openlabApi, dataTrsf, tableInfo }) => {
  const [selectedRadio, setSelectedRadio] = useState("");
  // TrendData Ykiho 함께 사용
  const [trendChartData, setTrendChartData] = useState([]);
  const [trendTableData, setTrendTableData] = useState([]);
  const [trendDetailData, setTrendDetailData] = useState([]);
  const [trendSearchRawData, setTrendSearchRawData] = useState([]);
  const [rankPatientsData, setRankPatientsData] = useState([]);

  const [subPage, setSubPage] = useState("trend");
  const [ykihoPage, setYkihoPage] = useState("normal");
  const [apiParams, setApiParams] = useState();
  const [detailParams, setDetailParams] = useState({});

  // rankChart tables 에서 사용될 다운로드 버튼용 -- 주석 지우지 말 것
  const [rankChartDownload, setRankChartDownload] = useState(false);
  const [trendChartDownload, setTrendChartDownload] = useState(false);
  const [trendTableDownload, setTrendTableDownload] = useState(false);
  const [detailTableDownload, setDetailTableDownload] = useState(false);

  // trendChart tables 에서 사용될 다운로드 버튼용
  const [trendDownload1, setTrendDownload1] = useState(false);
  const [trendDownload2, setTrendDownload2] = useState(false);
  const [trendDownload3, setTrendDownload3] = useState(false);
  const [trendDownload4, setTrendDownload4] = useState(false);

  //
  const [token] = useContext(UserContext);

  useEffect(() => {
    cleanUpDataVariables();
  }, [subPage, ykihoPage]);

  const cleanUpDataVariables = () => {
    setTrendTableData([]);
    setTrendSearchRawData([]);
    setTrendChartData([]);

    if (subPage === "trend") {
      setTrendDetailData([]);
    }
    if (subPage === "rank") {
      setRankPatientsData([]);
    }
  };

  useEffect(() => {
    const loadTrendDetailData = async (inputOptions) => {
      const params = { ...inputOptions, name: "trendDetail" };
      console.log(params);
      const response = await apiResponse(openlabApi, params, token);
      const modiDetailData = dataTrsf.detailBarchartData(response.data, params);
      setTrendDetailData([...modiDetailData]);
    };

    const loadSearchRawData = async (inputOptions) => {
      const params = {
        ...inputOptions,
        name: "searchRawData",
        current_page: 1,
      };
      console.log("searchRawData:", params);
      const response = await apiResponse(openlabApi, params, token);
      setTrendSearchRawData(response.data.items);
    };

    detailParams.target && loadTrendDetailData(detailParams);
    detailParams.target && loadSearchRawData(detailParams);
  }, [detailParams]);

  const onUpdateDetailParams = (options) => {
    const { yyyymm } = options;
    apiParams.name === "trendNormal" &&
      setDetailParams({
        ...apiParams,
        bgn_ym: yyyymm,
        yyyymm,
        target: "region_cd",
      });

    apiParams.name === "trendYkiho" &&
      setDetailParams({
        ...apiParams,
        bgn_ym: yyyymm,
        yyyymm,
        target: "recu_cl_cd",
      });
  };

  const loadDefaultData = async (params) => {
    const pNames = params.name;
    const response = await apiResponse(openlabApi, params, token);
    if (pNames.slice(0, 5) === "trend") {
      const modiChartData = dataTrsf.trendToChartData(response.data);
      const modiTableData = dataTrsf.trendToTableData(response.data);
      setTrendChartData(modiChartData);
      setTrendTableData(modiTableData);
    }

    if (pNames.slice(0, 4) === "rank") {
      setRankPatientsData(response.data);
    }
  };

  const upDateApiParams = async (updateParams) => {
    console.log("updateParams", updateParams);
    const apiOptions = inputOptionToApiParams(updateParams);
    setApiParams(apiOptions);
    console.log("setAPiParams:", apiOptions);
    await loadDefaultData(apiOptions);
  };

  const updateRadioValue = (option) => {
    setSelectedRadio(option);
  };

  const updateClickedRankChartInfo = async (sick_cd) => {
    const new_params = {
      ...apiParams,
      msick_cd3: sick_cd,
      name: "trendNormal",
    };

    setApiParams(new_params);
    await loadDefaultData(new_params);
  };

  const onSubPageSelect = (event, subPageValue) => {
    if (subPageValue !== null) {
      setSubPage(subPageValue);
    } else {
      setSubPage(subPage);
    }
  };

  const onYkihoPageSelect = (event, onYkihoValue) => {
    if (onYkihoValue !== null) {
      setYkihoPage(onYkihoValue);
    } else {
      setYkihoPage(ykihoPage);
    }
  };

  const onClickRankChart = (event) => {
    setRankChartDownload(true);
    setTimeout(() => {
      setRankChartDownload(false);
    }, 0.2);
  };

  const onClickTrendChart = (event) => {
    setTrendChartDownload(true);
    setTimeout(() => {
      setTrendChartDownload(false);
    }, 0.2);
  };

  const onClickTrendTable = (event) => {
    setTrendTableDownload(true);
    setTimeout(() => {
      setTrendTableDownload(false);
    }, 0.2);
  };

  const onClickDetailTable = (event) => {
    setDetailTableDownload(true);
    setTimeout(() => {
      setDetailTableDownload(false);
    }, 0.3);
  };

  const onTrendDownload1 = (event) => {
    setTrendDownload1(true);
    setTimeout(() => {
      setTrendDownload1(false);
    }, 0.3);
  };

  const onTrendDownload2 = (event) => {
    setTrendDownload2(true);

    setTimeout(() => {
      setTrendDownload2(false);
    }, 0.3);
  };
  const onTrendDownload3 = (event) => {
    setTrendDownload3(true);
    setTimeout(() => {
      setTrendDownload3(false);
    }, 0.3);
  };
  const onTrendDownload4 = (event) => {
    setTrendDownload4(true);
    setTimeout(() => {
      setTrendDownload4(false);
    }, 0.3);
  };

  const onOpenTrendPopup = () => onHelpPopup("/help_trend");
  const onOpenRankPopup = () => onHelpPopup("/help_rank");

  return (
    <BaseLayout>
      <div className={styles.banner__title}>
        <h1>심평원 고령환자데이터셋 분석 </h1>
      </div>
      <div className={styles.container}>
        {subPage === "trend" && (
          <div className={styles.helpBox}>
            <button onClick={onOpenTrendPopup} className={styles.helpBtn}>
              도움말
            </button>
          </div>
        )}
        {subPage === "rank" && (
          <div className={styles.helpBox}>
            <button onClick={onOpenRankPopup} className={styles.helpBtn}>
              도움말
            </button>
          </div>
        )}
        <section className={styles.toggleSection}>
          <div className={styles.toggleGroup}>
            <div className={styles.toggle__name}>분석 방법</div>
            <ToggleButtonGroup
              value={subPage}
              exclusive
              onChange={onSubPageSelect}
              aria-label="analysisMethod"
            >
              <ToggleButton value="trend" aria-label="trend">
                <div className="toggle__btn">데이터경향분석</div>
              </ToggleButton>
              <ToggleButton value="rank" aria-label="rank">
                <div className="toggle__btn">다빈도상병순위</div>
              </ToggleButton>
            </ToggleButtonGroup>
          </div>

          <div className={styles.toggleGroup}>
            <div className={styles.toggle__name}>분석 방향</div>
            <ToggleButtonGroup
              value={ykihoPage}
              exclusive
              onChange={onYkihoPageSelect}
              aria-label="submethod"
            >
              <ToggleButton value="normal" aria-label="normal">
                <div className="toggle__btn">환자특성중심</div>
              </ToggleButton>

              <ToggleButton value="ykiho" aria-label="ykiho">
                <div className="toggle__btn">병원특성중심</div>
              </ToggleButton>
            </ToggleButtonGroup>
          </div>
        </section>

        <div className={styles.inputOptions}>
          {subPage === "trend" &&
            ykihoPage === "normal" &&
            patientsTrendInput({ upDateApiParams, updateRadioValue })}
          {subPage === "trend" &&
            ykihoPage === "ykiho" &&
            patientsTrendYkihoInput({
              upDateApiParams,
              updateRadioValue,
            })}
          {subPage === "rank" &&
            ykihoPage === "normal" &&
            patientsRankInput({ upDateApiParams, updateRadioValue })}
          {subPage === "rank" &&
            ykihoPage === "ykiho" &&
            patientsRankYkihoInput({ upDateApiParams, updateRadioValue })}
        </div>

        <section className={styles.chartTables}>
          {subPage === "trend" &&
            TrendChartsTables({
              trendChartData,
              trendTableData,
              trendDetailData,
              selectedRadio,
              tableInfo,
              onUpdateDetailParams,
              trendSearchRawData,
              onTrendDownload1,
              onTrendDownload2,
              onTrendDownload3,
              onTrendDownload4,
              trendDownload1,
              trendDownload2,
              trendDownload3,
              trendDownload4,
            })}

          {subPage === "rank" &&
            RankChartsTables({
              rankPatientsData,
              trendChartData,
              trendTableData,
              trendSearchRawData,
              tableInfo,
              onUpdateDetailParams,
              selectedRadio,
              updateClickedRankChartInfo,
              onClickRankChart,
              onClickTrendChart,
              onClickTrendTable,
              onClickDetailTable,
              rankChartDownload,
              trendChartDownload,
              trendTableDownload,
              detailTableDownload,
            })}
        </section>
      </div>
    </BaseLayout>
  );
};

export default NewPatientsState;
