import React from "react";
import PatientsRankForm from "./forms/patientsRankForm";

const patientsRankInput = ({ upDateApiParams, updateRadioValue }) => {
  const formName = "rankNormal";
  return (
    <PatientsRankForm
      upDateApiParams={upDateApiParams}
      formName={formName}
      updateRadioValue={updateRadioValue}
    />
  );
};

export default patientsRankInput;
