import * as React from "react";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../../context/UserContext";

const PasswordCheck = () => {
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { isSubmitting, errors },
    watch,
  } = useForm();

  const [errorMessage, setErrorMessage] = React.useState();
  const [token, setToken, , passwordCheck, setPasswordCheck] =
    React.useContext(UserContext);

  const onPasswordCheckSubmit = async (data) => {
    const response = await fetch(
      "http://222.113.80.171:8000/api/users/passwd_check",
      {
        method: "POST",
        headers: {
          accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        body: JSON.stringify({
          plain_password: data.plain_password,
        }),
      }
    );

    const result = await response.json();

    if (!response.ok) {
      if (typeof result.detail === "object") {
        setErrorMessage(result.detail[0].msg);
      } else if (typeof result.detail === "string") {
        setErrorMessage(result.detail);
      }
    } else {
      setPasswordCheck(true);
      navigate("/my_info/modify_pw");
    }
  };

  return (
    <Grid container component="main" sx={{ height: "100vh" }}>
      <CssBaseline />
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
      >
        <Box
          sx={{
            my: 8,
            mx: 4,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Typography component="h1" variant="h5">
            비밀번호 확인
          </Typography>
          <Box
            component="form"
            noValidate
            onSubmit={handleSubmit(onPasswordCheckSubmit)}
            sx={{ mt: 1, width: "30rem" }}
          >
            <TextField
              margin="normal"
              fullWidth
              id="plain_password"
              label="Password"
              type="password"
              autoFocus
              {...register("plain_password", {
                required: "비밀번호는 필수 입력입니다.",
                minLength: {
                  value: 6,
                  message: "최소 6자리 이상입니다.",
                },
              })}
            />

            {errors.plain_password && (
              <small style={{ color: "red" }}>
                {errors.plain_password.message}
              </small>
            )}

            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
              disabled={isSubmitting}
            >
              비밀번호 확인
            </Button>

            {errorMessage && <p style={{ color: "red" }}>{errorMessage}</p>}
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
};

export default PasswordCheck;
