import React, { useRef, useState, useEffect } from "react";
import { AgGridColumn, AgGridReact } from "ag-grid-react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";

const BuyerTable = ({ rowData, downloadTF }) => {
  const gridRef = useRef();
  const [gridApi, setGridApi] = useState(null);
  const [downloadCheck, setDownloadCheck] = useState(false);

  useEffect(() => {
    downloadCheck && gridApi.exportDataAsCsv();
  }, [downloadCheck]);

  if (downloadCheck !== downloadTF) {
    setDownloadCheck(downloadTF);
  }

  const onGridReady = (params) => {
    setGridApi(params.api);
    params.api.sizeColumnsToFit();
  };

  return (
    <>
      <div
        className="ag-theme-alpine"
        style={{ height: "100%", width: "100%" }}
      >
        <AgGridReact
          onGridReady={onGridReady}
          rowData={rowData}
          ref={gridRef}
          rowSelection={"single"}
        >
          <AgGridColumn
            field="기관명"
            sortable={true}
            minWidth={250}
            maxWidth={450}
          ></AgGridColumn>
          <AgGridColumn
            field="계약금액"
            sortable={true}
            suppressSizeToFit={true}
            minWidth={150}
            maxWidth={200}
          ></AgGridColumn>
          <AgGridColumn
            field="수량"
            sortable={true}
            suppressSizeToFit={true}
            minWidth={80}
            maxWidth={100}
          ></AgGridColumn>
        </AgGridReact>
      </div>
    </>
  );
};

export default BuyerTable;
