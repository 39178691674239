import React from "react";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../../context/UserContext";

const EmailCheck = () => {
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { isSubmitting, errors },
    watch,
  } = useForm();

  const [errorMessage, setErrorMessage] = React.useState("");
  const [errorOk, setErrorOk] = React.useState(false);

  const onPasswordCheckSubmit = async (data) => {
    setErrorMessage("");
    const response = await fetch(
      "http://222.113.80.171:8000/api/users/reset_pw",
      {
        method: "POST",
        headers: {
          accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          email: data.email,
        }),
      }
    );

    const result = await response.json();

    console.log(result);

    if (!response.ok) {
      if (typeof result.detail === "object") {
        setErrorMessage(result.detail[0].msg);
      } else if (typeof result.detail === "string") {
        setErrorMessage(result.detail);
      }
      setErrorOk(false);
    } else {
      setErrorMessage("이메일로 임의의 비밀번호가 발송되었습니다.");
      setErrorOk(true);
    }
  };

  return (
    <Grid container component="main" sx={{ height: "100vh" }}>
      <CssBaseline />
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
      >
        <Box
          sx={{
            my: 8,
            mx: 4,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Typography component="h1" variant="h5">
            이메일 입력
          </Typography>
          <Box
            component="form"
            noValidate
            onSubmit={handleSubmit(onPasswordCheckSubmit)}
            sx={{ mt: 1, width: "30rem" }}
          >
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              autoComplete="email"
              autoFocus
              {...register("email", {
                required: "이메일은 필수 입력입니다.",
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                  message: "이메일 형식에 맞지 않습니다.",
                },
                validate: (val) => {
                  const frontAlias = val.split("@")[0];
                  if (frontAlias.length < 6) {
                    return "이메일이 너무 짧습니다.(최소 6자리 이상)";
                  }
                },
              })}
            />
            {errors.email && (
              <small style={{ color: "red" }}>{errors.email.message}</small>
            )}

            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
              disabled={isSubmitting}
            >
              확인
            </Button>

            {errorMessage && (
              <p style={{ color: errorOk ? "green" : "red" }}>{errorMessage}</p>
            )}
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
};

export default EmailCheck;
