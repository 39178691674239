import React, { useRef } from "react";
import styles from "./home_info.module.css";
import patientsImg from "../../img/home_patients.jpeg";
import itemImg from "../../img/home_device.png";
import supImg from "../../img/home_sup.jpeg";
import { useNavigate } from "react-router-dom";

const Data_info = (props) => {
  const navigate = useNavigate();

  return (
    <div className={styles.dataContainer}>
      <div className={styles.content_title}>
        <h2>데이터 소개</h2>
      </div>

      <div className={styles.dataContents}>
        <div className={styles.dataContent}>
          <div
            className={styles.data_img_content}
            onClick={() => navigate("/patients")}
          >
            <img className={styles.data_img} src={patientsImg} alt="" />
          </div>
          <p className={styles.data_name}>
            건강보험심사평가원 <br /> 고령환자표본데이터셋
          </p>

          <ul className={styles.ul_description}>
            <li>
              <p className={styles.data_description}>
                2009~2016년 고령환자(만65세이상) 추출비율 20%(약 100만명)
              </p>
            </li>
            <li>
              <p className={styles.data_description}>
                2017~2018년 추출비율 10% (약 70만명)
              </p>
            </li>
            <li>
              <p className={styles.data_description}>
                1년 단위로 성별과 연령구간에 따라 환자단위 층화계층 추출한 자료
              </p>
            </li>
            <li>
              <p className={styles.data_description}>
                명세서 일반내역, 진료내역, 상병내역, 원외처방내역, 요양기관정보
                구성
              </p>
            </li>
          </ul>
        </div>

        <div className={styles.dataContent}>
          <div
            className={styles.data_img_content}
            onClick={() => navigate("/items")}
          >
            <img className={styles.data_img} src={itemImg} alt="" />
          </div>
          <p className={styles.data_name}>
            의료기기/의약품 <br />
            물품 구매 내역
          </p>
          <ul className={styles.ul_description}>
            <li>
              <p className={styles.data_description}>
                보훈복지의료공단 물품구매 데이터
              </p>
            </li>
            <li>
              <p className={styles.data_description}>
                나라장터 의약품, 의료기기 물품구매내역
              </p>
            </li>
            <li>
              <p className={styles.data_description}>
                시장 파악 및 인사이트 제공을 위한 분석 수행
              </p>
            </li>
          </ul>
        </div>
        <div className={styles.dataContent}>
          <div
            className={styles.data_img_content}
            onClick={() => navigate("/support")}
          >
            <img className={styles.data_img} src={supImg} alt="" />
          </div>
          <p className={styles.data_name}>
            심평원 의료빅데이터센터 <br /> 정보 및 활용 지원
          </p>
          <ul className={styles.ul_description}>
            <li>
              <p className={styles.data_description}>
                건강보험심사평가원에서 제공하는 보건의료빅데이터센터의 분석 방법
              </p>
            </li>
            <li>
              <p className={styles.data_description}>
                관심 상병/의약품/의료기기 항목 분석 및 시각화 지원
              </p>
            </li>
            <li>
              <p className={styles.data_description}>
                분석관련 기반지식과 통계프로그램 활용, 제공변수들의 종류와
                관계에 대한 데이터 기반지식 활용 지원
              </p>
            </li>
            <li>
              <p className={styles.data_description}>
                보건의료빅데이터 센터의 활용 신청 방법
              </p>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};
export default Data_info;
