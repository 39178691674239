import React, { useRef, useState, useEffect } from "react";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";

const DataTable = ({ rowData, tableInfo, downloadTF }) => {
  const gridRef = useRef();
  const [gridApi, setGridApi] = useState(null);
  const [gridColumnApi, setGridColumnApi] = useState(null);
  const [downloadCheck, setDownloadCheck] = useState(false);

  useEffect(() => {
    downloadCheck && gridApi.exportDataAsCsv();
  }, [downloadCheck]);

  if (downloadCheck !== downloadTF) {
    setDownloadCheck(downloadTF);
  }

  const onGridReady = (params) => {
    setGridApi(params.api);
    // params.api.sizeColumnsToFit();
    setGridColumnApi(params.columnApi);
  };

  const autoSizeAll = (skipheaderTF) => {
    const allColumnIds = [];
    gridColumnApi.getAllColumns().forEach((column) => {
      allColumnIds.push(column.colId);
    });
    gridColumnApi.autoSizeColumn(allColumnIds, skipheaderTF);
  };

  return (
    <>
      <div
        className="ag-theme-alpine"
        style={{ height: "100%", width: "100%" }}
      >
        <AgGridReact
          defaultColDef={tableInfo.defaultColDef}
          rowData={rowData}
          columnDefs={tableInfo.patientsDataColumnDefs}
          ref={gridRef}
          onGridReady={onGridReady}
          suppressDragLeaveHidesColumns={true}
          pagenation={true}
        ></AgGridReact>
      </div>
    </>
  );
};

export default DataTable;
