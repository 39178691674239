const getSunburst2LayerOptions = (data) => {
  const defaultOptions = {
    chart: {
      height: "100%",
      reflow: false,
    },

    title: {
      text: "진료행위-질병 분석",
    },
    plotOptions: {
      series: {
        animation: {
          duration: 100,
        },
      },
    },
  };

  const series = [
    {
      type: "sunburst",
      reflow: false,
      data: data,
      allowDrillToNode: true,
      cursor: "pointer",
      dataLabels: {
        format: "{point.name}",
        // filter: {
        //   property: "innerArcLength",
        //   operator: ">",
        //   value: 16,
        // },
      },
      levels: [
        {
          level: 1,
          levelIsConstant: false,
          dataLabels: {
            // filter: {
            //   property: "outerArcLength",
            //   operator: ">",
            //   value: 64,
            // },
          },
        },
        {
          level: 2,
          colorByPoint: true,
        },
      ],
      tooltip: {
        //   headerFormat: "<b>", 좀 더 복잡한 구조를 만들 때 사용함
        pointFormat: "<b>{point.h_name} : {point.value}</b>",
        //   footerFormat: "</b>",
      },
    },
  ];

  const options = {
    ...defaultOptions,
    series,
    navigation: {
      buttonOptions: {
        enabled: true,
        verticalAlign: "bottom",
      },
    },
    exporting: {
      buttons: {
        contextButton: {
          menuItems: ["downloadPNG", "downloadSVG", "separator"],
        },
      },
    },
  };

  return options;
};

const getSunburst3LayerOptions = (data) => {
  const series = [
    {
      type: "sunburst",
      data: data,
      allowDrillToNode: true,
      cursor: "pointer",
      dataLabels: {
        format: "{point.name}",
        filter: {
          property: "innerArcLength",
          operator: ">",
          value: 1,
        },
      },
      levels: [
        {
          level: 1,
          levelIsConstant: false,
        },
        {
          level: 2,
          colorByPoint: true,
        },
        {
          level: 3,
          colorVariation: {
            key: "brightness",
            to: -0.5,
          },
        },
      ],
      tooltip: {
        pointFormat: "<b>{point.h_name} : {point.value}</b>",
      },
    },
  ];
  const options = {
    chart: {
      height: "100%",
    },
    title: {
      text: "질병-진료행위 분석",
    },
    plotOptions: {
      series: {
        animation: {
          duration: 1000,
        },
        turboThreshold: 0,
      },
    },
    series,
    navigation: {
      buttonOptions: {
        enabled: true,
        verticalAlign: "bottom",
      },
    },
    exporting: {
      buttons: {
        contextButton: {
          menuItems: ["downloadPNG", "downloadSVG", "separator"],
        },
      },
    },
  };

  return options;
};

const testoptions = (data) => {
  return {
    chart: {
      height: "100%",
    },

    title: {
      text: "World population 2017",
    },
    subtitle: {
      text: 'Source <href="https://en.wikipedia.org/wiki/List_of_countries_by_population_(United_Nations)">Wikipedia</a>',
    },
    series: [
      {
        type: "sunburst",
        data: data,
        allowDrillToNode: true,
        cursor: "pointer",
        dataLabels: {
          format: "{point.name}",
          filter: {
            property: "innerArcLength",
            operator: ">",
            value: 16,
          },
        },
        levels: [
          {
            level: 1,
            levelIsConstant: false,
            dataLabels: {
              filter: {
                property: "outerArcLength",
                operator: ">",
                value: 64,
              },
            },
          },
          {
            level: 2,
            colorByPoint: true,
          },
          {
            level: 3,
            colorVariation: {
              key: "brightness",
              to: -0.5,
            },
          },
          {
            level: 4,
            colorVariation: {
              key: "brightness",
              to: 0.5,
            },
          },
        ],
      },
    ],
    navigation: {
      buttonOptions: {
        enabled: true,
        verticalAlign: "bottom",
      },
    },
    exporting: {
      buttons: {
        contextButton: {
          menuItems: ["downloadPNG", "downloadSVG", "separator"],
        },
      },
    },
  };
};

export { getSunburst2LayerOptions, getSunburst3LayerOptions, testoptions };
