import React, { createRef, useEffect, useState } from "react";
import { useScreenshot, createFileName } from "use-react-screenshot";
import styles from "./patientsChart.module.css";
import LineChart from "../../nivo/lineChart";

const PatientsChart = ({ trendChartData, selectedRadio, downloadTF }) => {
  const chartRef = createRef();
  const [image, takeScreenShot] = useScreenshot({
    type: "image/jpeg",
    quality: 1.0,
  });
  const [downloadCheck, setDownloadCheck] = useState(false);

  const download = (image, { name = "img", extension = "png" } = {}) => {
    const a = document.createElement("a");
    a.href = image;
    a.download = createFileName(extension, name);
    a.click();
  };

  useEffect(() => {
    downloadCheck && takeScreenShot(chartRef.current).then(download);
  }, [downloadCheck]);

  if (downloadCheck !== downloadTF) {
    setDownloadCheck(downloadTF);
  }

  const chartData = selectedRadio
    ? trendChartData.filter(
        (data) =>
          data.data_id ===
          {
            1: "cost_sum",
            2: "case_count",
            3: "patients_number",
            cost_sum: "cost_sum",
            case_count: "case_count",
            patients_number: "patients_number",
          }[selectedRadio]
      )
    : trendChartData;

  return (
    <div className={styles.Chart} ref={chartRef}>
      <LineChart data={chartData} onClick={console.log} />
    </div>
  );
};

export default PatientsChart;
