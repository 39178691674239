import React from "react";
import PatientsRankYkihoForm from "./forms/patientsRankYkihoForm";

const patientsRankYkihoInput = ({ upDateApiParams, updateRadioValue }) => {
  const formName = "rankYkiho";
  return (
    <>
      <PatientsRankYkihoForm
        upDateApiParams={upDateApiParams}
        formName={formName}
        updateRadioValue={updateRadioValue}
      />
    </>
  );
};

export default patientsRankYkihoInput;
