function clsfcDetailJsonMerge(x) {
  x.sort(clsDetailSort);

  const result = [];
  for (let dataJson of x) {
    const idx = result.findIndex((s) => s.prdctIdntNo === dataJson.prdctIdntNo);
    if (idx + 1) {
      for (let valueKey of ["amt", "item_cnt", "cntrct_cnt"]) {
        result[idx][valueKey] += dataJson[valueKey];
      }
    } else {
      result.push(dataJson);
    }
  }
  return result;
}

const clsDetailSort = (a, b) => {
  if (parseInt(a.prdctIdntNo) < parseInt(b.prdctIdntNo)) {
    return -1;
  } else {
    return 1;
  }
};

function transToBarChartJson(resJson) {
  // {
  //     country: "AD",
  //     "hot dog": 98,
  //     "hot dogColor": "hsl(323, 70%, 50%)",
  //     burger: 185,
  //     burgerColor: "hsl(308, 70%, 50%)",
  //     sandwich: 155,
  //     sandwichColor: "hsl(42, 70%, 50%)",
  //     kebab: 189,
  //     kebabColor: "hsl(140, 70%, 50%)",
  //     fries: 147,
  //     friesColor: "hsl(41, 70%, 50%)",
  //     donut: 145,
  //     donutColor: "hsl(283, 70%, 50%)",
  //   }

  const res = resJson.map((s) => {
    return { yyyymm: s.yyyymm, 계약금액: s.amt, 수량: s.item_cnt };
  });
  console.log(res);

  return res;
}

export { clsfcDetailJsonMerge, transToBarChartJson };
